import List from '@/views/Administrator/List.vue'
import Save from '@/views/Administrator/Save.vue'

export const administratorRoutes = [
  {
    path: '/administrators',
    name: 'administrator-index',
    component: List,
  },
  {
    path: '/administrator/:id?',
    name: 'administrator-save',
    component: Save,
    meta: { menuIndex: '/administrators' },
  },
]
