<template>
  <div class="layout-aside height-100">
    <router-link :to="{ name: 'home' }" tag="span" class="pointer">
      <el-image
        :src="require('@/assets/images/logo-white.svg')"
        class="logo primary-bg width-100"
      />
    </router-link>

    <el-menu
      ref="leftMenu"
      class="mt-30"
      :router="true"
      :default-active="activeNavTab"
      @select="onMenuItemSelect"
    >
      <template v-for="(menu, key) in menus">
        <template v-if="menu.hasOwnProperty('subs')">
          <el-submenu :key="key.toString()" :index="menu.index">
            <template slot="title" ref="subMenu">
              <i :class="menu.icon" class="pr-15"></i>
              <span>{{ menu.title }}</span>
            </template>
            <template v-for="(sub, subKey) in menu.subs">
              <el-menu-item
                :key="key.toString() + '-' + subKey.toString()"
                :index="sub.route"
              >
                <span class="subtext">{{ sub.title }}</span>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :key="key.toString()" :index="menu.route">
            <i :class="menu.icon" class="pr-15"></i>
            <span>{{ menu.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <div class="logout pointer" :loading="logoutLoading" @click="logout">
      <i class="icon ion-md-log-out mr-15"></i><span>Logout</span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
export default {
  data: () => {
    return {
      menus: [
        {
          title: 'Dashboard',
          icon: 'icon ion-ios-stats',
          route: '/dashboard',
        },
        {
          title: 'Reading sheets',
          icon: 'icon ion-ios-book',
          route: '/reading-sheets',
        },
        {
          title: 'Reporters',
          icon: 'icon ion-ios-copy',
          route: '/reporters',
        },
        {
          title: 'News',
          icon: 'icon ion-ios-albums',
          route: '/news',
        },
        {
          title: 'Requests',
          icon: 'icon ion-ios-help-circle',
          route: '/requests',
        },
        {
          title: 'Contacts',
          icon: 'icon ion-ios-people',
          route: '/contacts',
        },
        {
          title: 'Authors',
          icon: 'icon ion-ios-person',
          route: '/authors',
        },
        {
          title: 'Companies',
          icon: 'icon ion-ios-briefcase',
          route: '/companies',
        },
        {
          title: 'Users',
          icon: 'icon ion-ios-person',
          route: '/users',
        },
        {
          title: 'Administration',
          icon: 'icon ion-ios-settings',
          index: 'administration',
          subs: [
            {
              title: 'Administrators',
              route: '/administrators',
            },
            {
              title: 'Genres',
              route: '/genres',
            },
            {
              title: 'Thematics',
              route: '/thematics',
            },
            {
              title: 'Periods',
              route: '/periods',
            },
            {
              title: 'Formats',
              route: '/formats',
            },
            {
              title: 'Categories',
              route: '/categories',
            },
            {
              title: 'Audiences',
              route: '/audiences',
            },
            {
              title: 'Times',
              route: '/times',
            },
            {
              title: 'Countries',
              route: '/countries',
            },
            {
              title: 'Literary Prizes',
              route: '/literary-prize',
            },
            {
              title: 'AI Prompts',
              route: '/ai-prompt',
            },
          ],
        },
      ],
    }
  },
  mounted() {
    this.$refs.leftMenu.open('administration')
    this.$nextTick(() => {
      this.detectCurrentMenuItem()
    })
  },
  computed: {
    ...mapState('auth', ['logoutLoading']),
    ...mapState('common', ['activeNavTab']),
  },
  methods: {
    ...mapMutations('common', ['setActiveNavTab']),
    ...mapActions('auth', ['logout']),
    detectCurrentMenuItem() {
      let menuIndex = this.$route.meta['menuIndex'] || this.$route.path
      this.setActiveNavTab(menuIndex)
    },
    onMenuItemSelect(index) {
      if (index) {
        this.setActiveNavTab(index)
      }
    },
  },
}
</script>
