<template>
  <div class="list reporter-list">
    <reporters-table
      :data="reporters.data"
      :filters="extraFilters"
      :is-loading="getReportersLoading"
      :hidden-columns="{ reporterUsers: true, 'delete-action': true }"
      @filter-change="value => (extraFilters = value)"
    />

    <paginator
      :meta="reporters.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
import ReportersTable from '@/views/Reporter/ReportersTable.vue'

export default {
  name: 'UserReportersTab',
  components: {
    Paginator,
    ReportersTable,
  },
  mixins: [pagination('getReporters', 'title')],
  computed: {
    ...mapState('reporter', ['reporters', 'statuses', 'getReportersLoading']),
  },
  mounted() {
    let userId = this.$route.params.id
    // Changing extraFilters triggers getReporters()
    this.extraFilters = Object.assign({}, this.extraFilters, { userId })
  },
  methods: {
    ...mapActions('reporter', ['getReporters', 'getReporterStatuses']),
  },
}
</script>
