<template>
  <dashboard-right-item accordeon-tab-name="recent-users">
    <template slot="title">
      <div class="icon-wrapper">
        <i class="icon ion-ios-person" />
      </div>
      <div class="content-wrapper">
        <div class="top-line">Les utilisateurs</div>
        <div class="bottom-line">RÉCEMMENT CONNECTÉS</div>
      </div>
    </template>

    <template slot="content">
      <div
        v-for="user in items"
        :key="user.id"
        class="recent-user-item-wrapper"
      >
        <div class="user-name-and-counter">
          <router-link
            :to="{ name: 'user-save', params: { id: user.id } }"
            class="user-fullname"
          >
            {{ user.fullname }}
          </router-link>
          <span v-if="user.numberConnections" class="user-connection-date">
            ({{ user.numberConnections }})
          </span>
        </div>
        <div class="last-connection-date">
          {{ user.lastConnectionDate }}
        </div>
      </div>
    </template>
  </dashboard-right-item>
</template>

<script>
import DashboardRightItem from './DashboardRightItem.vue'

export default {
  name: 'RecentUser',
  components: {
    DashboardRightItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
