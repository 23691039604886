<template>
  <div class="save">
    <save-page-header title="Contact"></save-page-header>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native="submit"
      v-loading.fullscreen.lock="isPageLoading"
    >
      <!-- Full name field -->
      <el-form-item label="Full name" prop="name" :error="errors['name']">
        <el-input v-model="form.name" placeholder="Full Name" />
      </el-form-item>
      <!-- Email field -->
      <el-form-item label="Email" prop="email" :error="errors['email']">
        <el-input v-model="form.email" placeholder="Email" />
      </el-form-item>
      <!-- Phone field -->
      <el-form-item label="Phone" prop="phone" :error="errors['phone']">
        <el-input v-model="form.phone" placeholder="Phone" />
      </el-form-item>
      <!-- Job field -->
      <el-form-item label="Job" prop="job" :error="errors['professional_role']">
        <el-input v-model="form.professional_role" placeholder="Job" />
      </el-form-item>
      <!-- Comment field -->
      <el-form-item label="Comment" prop="comment" :error="errors['comment']">
        <el-input
          type="textarea"
          row="3"
          v-model="form.comment"
          placeholder="Comment"
        />
      </el-form-item>
      <!-- Company field -->
      <el-form-item label="Company" :error="errors['company_id']">
        <el-select
          filterable
          v-model="form.company_id"
          placeholder="Company"
          class="el-col-24"
          autocomplete="on"
          :filter-method="filterCompanies"
        >
          <el-option
            v-for="company in companies.data"
            :key="company.id"
            :label="company.name"
            :value="company.id"
            :disabled="getCompaniesLoading"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- Contact type field -->
      <el-form-item label="Contact type" :error="errors['contact_type_id']">
        <el-select
          v-model="form.contact_type_id"
          placeholder="Contact type"
          class="el-col-24"
          :disabled="getContactTypesLoading"
        >
          <el-option
            v-for="type in contactTypes.data"
            :key="type.id"
            :label="type.label"
            :value="type.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div :class="[{ 'align-right': !inModal, 'align-modal': inModal }]">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveContactLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            {{ isUpdate ? 'Save Contact' : 'Create Contact' }}
          </el-button>
        </el-col>
      </div>
    </el-form>

    <div
      v-if="!getContactLoading && readingSheetsList.length > 0"
      class="contact-reading-sheets-wrapper"
    >
      <save-page-header title="Associated reading sheets"></save-page-header>
      <books-table
        class="reading-sheet-table"
        :data="readingSheetsList"
        @row-click="goToBook"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import SavePageHeader from '@/components/ui/SavePageHeader'
import BooksTable from '@/components/ui/BooksTable'

export default {
  components: {
    SavePageHeader,
    BooksTable,
  },
  props: {
    inModal: {
      required: false,
      default: () => false,
    },
  },
  mixins: [apiError('saveContactError'), labelWidth],
  data: () => ({
    form: {},
  }),
  computed: {
    ...mapState('contact', [
      'contact',
      'contactTypes',
      'saveContactError',
      'saveContactLoading',
      'getContactTypesLoading',
      'getContactLoading',
    ]),
    ...mapState('company', ['companies', 'getCompaniesLoading']),
    isUpdate() {
      return this.$route.params.id !== undefined && !this.inModal
    },
    isPageLoading() {
      return this.getContactLoading
    },
    readingSheetsList() {
      let list = []
      if (this.contact.data) {
        list = this.contact.data.readingSheets
      }
      return list
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getContact({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.contact.data,
      }
      delete this.form.reedingSheets

      this.getCompanies({
        name: this.form.company ? this.form.company.name : '',
      })
    } else {
      this.getCompanies({ perPage: 10 })
    }
    this.getContactTypes()
  },
  methods: {
    ...mapActions('contact', ['saveContact', 'getContact', 'getContactTypes']),
    ...mapActions('company', ['getCompanies']),
    submit() {
      this.saveContact({
        routeParameters: {
          id: this.isUpdate ? this.$route.params.id : null,
        },
        ...this.form,
        inModal: this.inModal,
      }).then(() => {
        if (this.saveContactError.status == null) {
          this.$emit('created', true)
        }
      })
    },
    filterCompanies(search) {
      this.getCompanies({ name: search })
    },
    goToBook(book) {
      this.$router.push({ name: 'readingSheets-save', params: { id: book.id } })
    },
  },
}
</script>
