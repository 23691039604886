<template>
  <el-row type="flex" justify="center" align="middle" class="empty-layout p-20">
    <el-col :xs="24" :sm="12" :lg="8">
      <div class="mb-20 text-center">
        <el-image :src="require('@/assets/images/logo.png')" />
        <h1>Administration Panel</h1>
      </div>
      <el-card>
        <slot />
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {}
</script>
