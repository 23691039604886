import { generateAction } from '../builder'

import router from '@/router'

const getCompanies = generateAction('getCompanies', 'admin/companies')
const getCompany = generateAction('getCompany', 'admin/companies/{id}')
const saveCompany = generateAction(
  'saveCompany',
  'admin/companies/{id}',
  'POST',
  data => {
    if (!data.inModal) {
      return router.push({
        name: 'company-index',
      })
    }
  }
)
const deleteCompany = generateAction(
  'deleteCompany',
  'admin/companies/{id}',
  'DELETE'
)

export default {
  getCompanies,
  saveCompany,
  getCompany,
  deleteCompany,
}
