import { generateMutations } from '../builder'

export default {
  ...generateMutations('getDashboardData', 'dashboardData'),
  updateRecentSearchItem(state, { index, propertyName, value }) {
    let item = Object.assign({}, state.dashboardData.recentSearches[index])
    item[propertyName] = value
    state.dashboardData.recentSearches.splice(index, 1, item)
  },
}
