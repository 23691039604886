<template>
  <div class="popular-books-block">
    <div class="popular-books-header">
      <div class="popular-books-title">
        <div class="title-icon">
          <i class="icon ion-ios-book"></i>
        </div>
        <div class="title-text">
          Fiches les plus regardées
        </div>
      </div>
      <div class="slider-navigation">
        <i class="navigation-arrow el-icon-arrow-left" @click="scrollLeft"></i>
        <i
          class="navigation-arrow el-icon-arrow-right"
          @click="scrollRight"
        ></i>
      </div>
    </div>

    <div ref="booksList" class="popular-books-list">
      <div class="book-details-wrapepr" v-for="book in items" :key="book.id">
        <div class="number-views-block">
          <i class="el-icon-view"></i>
          <div class="number-views">
            {{ book.numberViews }}
          </div>
        </div>

        <div class="book-image-wrapper" @click="goToBook(book)">
          <div
            v-if="!book.coverUrl"
            class="book-image without-image"
            :style="{ 'background-color': book.primaryGenreColor }"
          ></div>
          <div
            v-if="book.coverUrl"
            class="book-image"
            :style="{
              'background-color': book.primaryGenreColor,
              'background-image': `url(${book.coverUrl})`,
            }"
          ></div>
        </div>

        <div class="book-title-block" @click="goToBook(book)">
          {{ book.originalTitle }}
        </div>

        <div class="authors-block">
          {{ book.authorNames.join(', ') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopularBooks',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToBook(book) {
      this.$router.push({ name: 'readingSheets-save', params: { id: book.id } })
    },
    scrollRight() {
      const booksListEl = this.$refs.booksList
      let newScrollX = booksListEl.scrollLeft + 340
      booksListEl.scrollTo({
        left: newScrollX,
        behavior: 'smooth',
      })
    },
    scrollLeft() {
      const booksListEl = this.$refs.booksList
      let newScrollX = booksListEl.scrollLeft - 340
      booksListEl.scrollTo({
        left: newScrollX,
        behavior: 'smooth',
      })
    },
  },
}
</script>
