<template>
  <div class="save">
    <save-page-header title="Thematic"></save-page-header>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native="submit"
    >
      <translatable-input
        :errors="errors"
        :source.sync="form.names"
        sourceKey="names"
        placeholder="New Thematic"
        title="Name"
        :label-width="labelWidth"
      />
      <translatable-input
        source-key="ai_descriptions"
        :errors="errors"
        :source.sync="form.ai_descriptions"
        placeholder="AI description"
        title="AI description"
        :label-width="labelWidth"
        inputType="textarea"
        :rows="9"
        autosize
      />
      <div class="align-right">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveThematicLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            {{ isUpdate ? 'Save Thematic' : 'Create Thematic' }}
          </el-button>
        </el-col>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import TranslatableInput from '@/components/Form/TranslatableInput'
import { initMultilingual } from '@/services/helpers'
import SavePageHeader from '@/components/ui/SavePageHeader'

export default {
  components: {
    TranslatableInput,
    SavePageHeader,
  },
  mixins: [apiError('saveThematicError'), labelWidth],
  data: () => ({
    form: {
      names: initMultilingual(),
      ai_descriptions: initMultilingual(),
    },
  }),
  computed: {
    ...mapState('thematic', [
      'thematic',
      'saveThematicError',
      'saveThematicLoading',
    ]),
    isUpdate() {
      return this.$route.params.id !== undefined
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getThematic({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.thematic.data,
        names: initMultilingual(this.thematic.data.names),
        ai_descriptions: initMultilingual(this.thematic.data.ai_descriptions),
      }
    }
  },
  methods: {
    ...mapActions('thematic', ['saveThematic', 'getThematic']),
    async submit() {
      await this.saveThematic({
        routeParameters: {
          id: this.$route.params.id,
        },
        ...this.form,
      })
    },
  },
}
</script>
