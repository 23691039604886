<template>
  <div>
    <div class="space-between">
      <h2 class="title primary-color">{{ title }}</h2>
      <form-lang-switcher v-if="showLanguageSelect" />
    </div>
    <div class="ruler"></div>
  </div>
</template>
<script>
import FormLangSwitcher from '@/components/Form/FormLangSwitcher'
export default {
  components: {
    FormLangSwitcher,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    showLanguageSelect: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
}
</script>
