import { generateAction } from '../builder'

import router from '@/router'

const getContacts = generateAction('getContacts', 'admin/contacts')
const getContact = generateAction('getContact', 'admin/contacts/{id}')
const saveContact = generateAction(
  'saveContact',
  'admin/contacts/{id}',
  'POST',
  data => {
    if (!data.inModal) {
      return router.push({
        name: 'contact-index',
      })
    }
    return
  }
)
const deleteContact = generateAction(
  'deleteContact',
  'admin/contacts/{id}',
  'DELETE'
)
const getContactTypes = generateAction(
  'getContactTypes',
  'admin/utilities/contact-types'
)
export default {
  getContacts,
  saveContact,
  getContact,
  deleteContact,
  getContactTypes,
}
