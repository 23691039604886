<template>
  <div>
    <el-form @submit.prevent.native="submit">
      <el-form-item label="Email" prop="email" :error="errors.email">
        <el-input
          v-model="form.email"
          prefix-icon="el-icon-user"
          placeholder="user@mail.com"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Mot de passe"
        prop="password"
        :error="errors.password"
      >
        <el-input
          v-model="form.password"
          show-password
          prefix-icon="el-icon-lock"
          placeholder="mot de passe"
        ></el-input>
      </el-form-item>
      <el-form-item class="mt-50">
        <el-button native-type="submit" :loading="loginLoading" type="primary">
          Me connecter
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { apiError } from '@/mixins'

export default {
  mixins: [apiError('loginError')],
  data: () => ({
    form: {
      email: null,
      password: null,
    },
  }),
  computed: {
    ...mapState('auth', ['loginLoading', 'loginError']),
  },
  methods: {
    ...mapActions('auth', ['login']),
    submit() {
      this.login(this.form)
    },
  },
}
</script>
