import { generateMutations } from '../builder'

export default {
  ...generateMutations('getAllNews', 'allNews'),
  ...generateMutations('saveNews', 'news'),
  ...generateMutations('getNews', 'news'),
  ...generateMutations('deleteNews'),
  ...generateMutations('deleteImage'),
  updateNewsData(state, { property, value }) {
    state[property] = value
  },
}
