import { generateState } from '../builder'

export default {
  thematics: [],
  thematic: {},
  ...generateState('getThematics'),
  ...generateState('saveThematic'),
  ...generateState('getThematic'),
  ...generateState('deleteThematic'),
}
