<template>
  <el-form>
    <el-input
      @input.native="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      class="search"
      v-model="value"
    >
      <i class="icon ion-ios-search el-input__icon" slot="prefix"> </i>
    </el-input>
  </el-form>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: () => 'Search...',
    },
  },
  data() {
    return {
      value: '',
    }
  },
  watch: {
    '$route.path'() {
      this.value = ''
    },
  },
}
</script>
