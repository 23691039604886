import { generateAction } from '../builder'
import router from '@/router'

const getAiPrompts = generateAction('getAiPrompts', 'admin/ai-prompts')
const getAiPrompt = generateAction('getAiPrompt', 'admin/ai-prompts/{id}')
const saveAiPrompt = generateAction(
  'saveAiPrompt',
  'admin/ai-prompts/{id}',
  'PUT',
  () => {
    return router.push({
      name: 'ai-prompt-index',
    })
  }
)

export default {
  getAiPrompts,
  getAiPrompt,
  saveAiPrompt,
}
