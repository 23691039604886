import { generateAction } from '../builder'

import router from '@/router'

const getLiteraryPrizes = generateAction(
  'getLiteraryPrizes',
  'admin/literary-prizes'
)
const getLiteraryPrize = generateAction(
  'getLiteraryPrize',
  'admin/literary-prizes/{id}'
)
const saveLiteraryPrize = generateAction(
  'saveLiteraryPrize',
  'admin/literary-prizes/{id}',
  'POST',
  () => {
    return router.push({
      name: 'literary-prize-index',
    })
  }
)
const deleteLiteraryPrize = generateAction(
  'deleteLiteraryPrize',
  'admin/literary-prizes/{id}',
  'DELETE'
)

export default {
  getLiteraryPrizes,
  saveLiteraryPrize,
  getLiteraryPrize,
  deleteLiteraryPrize,
}
