import { generateAction } from '../builder'

const getCharacterGenders = generateAction(
  'getCharacterGenders',
  'admin/character-genders'
)

export default {
  getCharacterGenders,
}
