<template>
  <div>
    <filters v-model="extraFilters" />
    <portal to="search">
      <search
        placeholder="Find a reading sheet..."
        v-model="globalFilter"
      ></search>
    </portal>
    <div class="list">
      <div class="space-between mb-50">
        <h2 class="title primary-color">READING SHEETS LIST</h2>
        <router-link :to="{ name: 'readingSheets-save' }" tag="span">
          <el-button type="primary" class="plain-primary" plain>
            <i class="el-icon-plus"></i>
            CREATE A READING SHEET
          </el-button>
        </router-link>
      </div>

      <books-table
        class="reading-sheet-table"
        :data="readingSheetsList"
        :is-loading="getReadingSheetsLoading"
        @row-click="selectItem"
      />

      <paginator
        :meta="readingSheets.meta"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
import Filters from '@/components/Form/Filters'
import BooksTable from '@/components/ui/BooksTable'
import Search from '@/components/Form/Search.vue'

export default {
  components: {
    Paginator,
    Filters,
    BooksTable,
    Search,
  },
  mixins: [pagination('getReadingSheets')],
  computed: {
    ...mapState('readingSheet', [
      'readingSheets',
      'statuses',
      'getReadingSheetsLoading',
      'getBookStatusesLoading',
    ]),
    readingSheetsList() {
      let list = []
      if (this.readingSheets.data) {
        list = this.readingSheets.data
      }
      return list
    },
  },
  mounted() {
    this.getReadingSheets()
  },
  methods: {
    ...mapActions('readingSheet', ['getReadingSheets']),
    selectItem(row) {
      this.$router.push({ name: 'readingSheets-save', params: { id: row.id } })
    },
  },
}
</script>
