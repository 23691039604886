import List from '@/views/AiPrompt/List.vue'
import Save from '@/views/AiPrompt/Save.vue'

export const aiPromptRoutes = [
  {
    path: '/ai-prompt',
    name: 'ai-prompt-index',
    component: List,
  },
  {
    path: '/ai-prompt/:id?',
    name: 'ai-prompt-save',
    component: Save,
    meta: { menuIndex: '/ai-prompt' },
  },
]
