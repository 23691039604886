import Home from '@/views/Home.vue'

export const homeRoutes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: { name: 'readingSheets' },
  },
]
