import { generateState } from '../builder'

export default {
  times: [],
  time: {},
  ...generateState('getTimes'),
  ...generateState('saveTime'),
  ...generateState('getTime'),
  ...generateState('deleteTime'),
}
