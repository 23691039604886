import { generateAction } from '../builder'
import router from '@/router'

const login = generateAction('login', 'admin/auth/login', 'POST', () => {
  return router.push({
    name: 'home',
  })
})
const logout = generateAction('logout', 'admin/auth/logout', 'POST', () => {
  return router.push({
    name: 'auth-login',
  })
})
const me = generateAction('me', 'admin/me')

export default {
  login,
  logout,
  me,
}
