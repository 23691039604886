<template>
  <div class="save" v-loading.lock="isPageLoading">
    <save-page-header title="User"></save-page-header>
    <el-tabs
      type="card"
      class="translatable-tabs mt-30"
      @tab-click="changePlane"
      v-model="activePlane"
    >
      <!-- default user component-->
      <el-tab-pane label="User's information" name="users">
        <div class="mt-30">
          <el-form
            :label-width="labelWidth + 'px'"
            @submit.prevent.native="submit"
          >
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="User validation" prop="role.name">
                  <el-switch
                    class="mr-20"
                    v-model="form.is_accepted"
                    @change="userValidation"
                  ></el-switch>
                  <span
                    v-if="form.is_accepted"
                    :class="{ 'primary-color': validated }"
                    >(Validated)</span
                  >
                  <span v-else :class="{ 'primary-color': validated }">
                    (Unvalidated)
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-divider content-position="left">
                Current plan
              </el-divider>
            </el-row>
            <template v-if="hasUserSubscription">
              <el-alert v-if="subscriptionEnded" type="error" :closable="false">
                User subscription is no longer valid
              </el-alert>
              <el-alert
                v-else-if="userSubscription.canceled_at"
                type="warning"
                :closable="false"
              >
                User subscription is canceled
              </el-alert>
              <el-row :gutter="10" class="mt-30">
                <el-col :span="12">
                  <!-- Subscription field -->
                  <el-form-item
                    label="Subscription plan"
                    prop="subscription_plan"
                  >
                    <el-input
                      v-model="userSubscription.role"
                      :disabled="true"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- Credit Left field -->
                  <el-form-item label="Credit left">
                    <el-input v-model="user.data.credits" :disabled="true" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="10">
                <el-col :span="12">
                  <!-- Renewal Type field -->
                  <el-form-item label="Renewal type" prop="renewal_type">
                    <el-input
                      v-model="userSubscription.paid"
                      :disabled="true"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Commitment" prop="commitment">
                    <el-input
                      v-model="userSubscription.commitment"
                      :disabled="true"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <!-- Subscription Start field -->
                  <el-form-item
                    label="Subscription start"
                    prop="subscription_start"
                  >
                    <el-input
                      v-model="userSubscription.start_date"
                      :disabled="true"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Subscription end"
                    prop="subscription_end"
                  >
                    <el-input
                      v-model="userSubscription.subscription_end_at"
                      :disabled="true"
                      placeholder="-"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="Stripe ID" prop="subscription_stripe_id">
                    <el-input
                      v-model="userSubscription.stripe_id"
                      :disabled="true"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Canceled at"
                    prop="subscription_canceled_at"
                  >
                    <el-input
                      v-model="userSubscription.canceled_at"
                      :disabled="true"
                      placeholder="-"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
            <div class="mb-20" v-else>
              <el-alert
                type="info"
                title="No subscription yet"
                :closable="false"
              />
            </div>
            <el-row :gutter="10" type="flex" justify="end">
              <el-col
                :span="4"
                v-if="!hasUserSubscription || userSubscription.canceled_at"
              >
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="onAddSubscription"
                >
                  add subscription
                </el-button>
              </el-col>
              <el-col :span="4" v-if="hasUserSubscription">
                <el-button
                  type="success"
                  icon="el-icon-document-copy"
                  @click="onManageCredits"
                >
                  manage credits
                </el-button>
              </el-col>
              <el-col
                :span="4"
                v-if="
                  hasUserSubscription &&
                    !userSubscription.canceled_at &&
                    userSubscription.stripe_id
                "
              >
                <el-button
                  type="warning"
                  icon="el-icon-refresh"
                  @click="onChangeSubscription"
                >
                  change subscription plan
                </el-button>
              </el-col>
              <el-col
                :span="4"
                v-if="hasUserSubscription && !userSubscription.canceled_at"
              >
                <el-button
                  type="danger"
                  icon="el-icon-close"
                  @click="onCancelSubscription"
                  :loading="cancelPlanLoading"
                >
                  cancel subscription
                </el-button>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-divider content-position="left">
                General information
              </el-divider>
            </el-row>
            <!-- name fields -->
            <el-row :gutter="10" class="mt-30">
              <el-col :span="12">
                <!-- First name field -->
                <el-form-item
                  label="Firstname"
                  prop="firstname"
                  :error="errors.firstname"
                >
                  <el-input v-model="form.firstname" placeholder="Firstname" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- Last name field -->
                <el-form-item
                  label="Lastname"
                  prop="lastname"
                  :error="errors.lastname"
                >
                  <el-input v-model="form.lastname" placeholder="Lastname" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="10">
              <el-col :span="12">
                <!-- Email field -->
                <el-form-item
                  label="Email"
                  prop="email"
                  :error="errors['email']"
                >
                  <el-input v-model="form.email" placeholder="Email" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- Phone field -->
                <el-form-item
                  label="Phone"
                  prop="phone"
                  :error="errors['phone']"
                >
                  <el-input v-model="form.phone" placeholder="Phone" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="10">
              <el-col :span="12">
                <!-- Job field -->
                <el-form-item label="Job" prop="job" :error="errors['job']">
                  <el-input v-model="form.job" placeholder="Job" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- Company field -->
                <el-form-item label="Company" :error="errors['company']">
                  <el-input v-model="form.company" placeholder="company" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="10">
              <el-col :span="12">
                <!-- VAT field -->
                <el-form-item
                  label="VAT Number"
                  prop="vat_number"
                  :error="errors['vat_number']"
                >
                  <el-input
                    disabled
                    v-model="form.vat_number"
                    placeholder="VAT Number"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <!-- Language field -->
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="Language" :error="errors['locale_id']">
                  <el-select
                    v-model="form.locale_id"
                    placeholder="Language"
                    class="el-col-md-24"
                    :disabled="getUserLoading"
                  >
                    <el-option
                      v-for="language in userLocales.data"
                      :key="language.id"
                      :label="language.name"
                      :value="language.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- Address field -->
                <el-form-item
                  label="Address"
                  prop="address"
                  :error="errors['address']"
                >
                  <el-input v-model="form.address" placeholder="Address" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="10">
              <el-col :span="12">
                <!-- Additional Address field -->
                <el-form-item
                  label="Additional address"
                  prop="additional_address"
                  :error="errors['additional_address']"
                >
                  <el-input
                    v-model="form.additional_address"
                    placeholder="Additional address"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- City field -->
                <el-form-item label="City" :error="errors['city']">
                  <el-input v-model="form.city" placeholder="city" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="10">
              <el-col :span="12">
                <!-- Postal code field -->
                <el-form-item
                  label="Postal code"
                  prop="job"
                  :error="errors['postal_code']"
                >
                  <el-input v-model="form.postal_code" placeholder="Job" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- Country field -->
                <el-form-item label="Country" :error="errors['country_id']">
                  <el-select
                    v-model="form.country_id"
                    placeholder="Country"
                    class="el-col-md-24"
                    filterable
                    autocomplete="on"
                    :filter-method="filterCountries"
                  >
                    <el-option
                      v-for="country in countries.data"
                      :key="country.id"
                      :label="country.name"
                      :value="country.id"
                      :disabled="getCountriesLoading"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="align-right">
              <el-col :span="4">
                <el-button
                  native-type="submit"
                  :loading="saveUserLoading"
                  type="primary"
                  plain
                  class="plain-primary"
                  @click="submit"
                >
                  {{ isUpdate ? 'SAVE THE USER' : 'CREATE THE USER' }}
                </el-button>
              </el-col>
            </div>
          </el-form>
        </div>
      </el-tab-pane>

      <!-- import reporter component-->
      <el-tab-pane label="Reporters" name="reporters">
        <user-reporters-tab v-if="activePlane === 'reporters'" />
      </el-tab-pane>

      <!-- import Requests component-->
      <el-tab-pane label="Requests" name="requests">
        <requests-table
          v-if="activePlane === 'requests' && form.id"
          class="mt-20"
          :user-id="form.id"
          :show-filter="true"
        ></requests-table>
      </el-tab-pane>

      <!-- import User's search component-->
      <el-tab-pane label="User's search" name="searches">
        <user-searches-tab v-if="activePlane === 'searches'" />
      </el-tab-pane>

      <!-- import library component-->
      <el-tab-pane label="User's library" name="libraries"
        ><user-libraries-tab v-if="activePlane === 'libraries'"
      /></el-tab-pane>
    </el-tabs>

    <el-dialog
      title="Add subscription"
      :visible.sync="addSubscriptionVisible"
      width="40%"
    >
      <el-form label-position="top">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="User plan" :error="addPlanErrors.plan_id">
              <el-select
                class="el-col-24"
                v-model="addPlanForm.plan_id"
                placeholder="Plan"
                :disabled="getPlansLoading"
                @change="onAddPlanSelectChange"
              >
                <el-option
                  v-for="plan in plans.data"
                  :key="plan.id"
                  :value="plan.id"
                  :label="plan.role + ' ' + plan.amount / 100 + ' EUR'"
                >
                  <b class="uppercase">{{ plan.role }}</b>
                  <span> {{ plan.amount / 100 }} EUR</span>
                  <small>
                    (comm. {{ plan.commitment }} / pay. {{ plan.paid }})
                  </small>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Credits" :error="addPlanErrors.credits">
              <el-input v-model="addPlanForm.credits" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item
              label="Subscription start date"
              prop="subscription_start_date"
              :error="addPlanErrors.start_date"
            >
              <el-date-picker
                class="custom-date-picker"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                v-model="addPlanForm.start_date"
                placeholder="Subscription start date"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- Subscription end date field -->
            <el-form-item
              label="Subscription end date"
              prop="subscription_end_date"
              :error="addPlanErrors.end_date"
            >
              <el-date-picker
                class="custom-date-picker"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                v-model="addPlanForm.end_date"
                placeholder="Subscription end date"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button @click="addSubscriptionVisible = false">
              Cancel
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button
              :loading="addPlanLoading"
              type="success"
              @click="saveAddSubscription"
            >
              Save
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      title="Manage credits"
      :visible.sync="manageCreditsVisible"
      width="40%"
    >
      <el-form label-position="left">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="Credits" :error="manageCreditsErrors.credits">
              <el-input-number
                v-model="manageCreditsForm.credits"
                :step-strictly="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button @click="manageCreditsVisible = false">
              Cancel
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button
              :loading="manageCreditsLoading"
              type="success"
              @click="saveManageCredits"
            >
              Save
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import RequestsTable from '@/views/Request/RequestsTable.vue'
import SavePageHeader from '@/components/ui/SavePageHeader'
import UserReportersTab from './UserReportersTab'
import UserLibrariesTab from './UserLibrariesTab'
import UserSearchesTab from './UserSearchesTab'

export default {
  components: {
    SavePageHeader,
    UserReportersTab,
    RequestsTable,
    UserLibrariesTab,
    UserSearchesTab,
  },
  mixins: [
    apiError('saveUserError'),
    labelWidth,
    apiError('addPlanError', 'addPlanErrors'),
    apiError('manageCreditsError', 'manageCreditsErrors'),
  ],
  data: () => ({
    form: {
      new_credits: 0,
    },
    activePlane: 'users',
    addSubscriptionVisible: false,
    manageCreditsVisible: false,
    addPlanForm: {
      plan_id: null,
      start_date: null,
      end_date: null,
      credits: 0,
    },
    manageCreditsForm: {
      credits: 0,
    },
  }),
  created() {
    const navManuItem = this.$route.meta['menuIndex'] || this.$route.path
    this.setActiveNavTab(navManuItem)
  },
  computed: {
    ...mapState('user', [
      'user',
      'plans',
      'saveUserError',
      'saveUserLoading',
      'getUserLoading',
      'validateUserError',
      'validateUserLoading',
      'getPlansLoading',
      'cancelPlanLoading',
      'addPlanLoading',
      'addPlanError',
      'manageCreditsLoading',
      'manageCreditsError',
    ]),
    ...mapState('locale', ['userLocales', 'getLocalesLoading']),
    ...mapState('country', ['countries', 'getCountriesLoading']),
    isUpdate() {
      return this.$route.params.id !== undefined
    },
    isPageLoading() {
      return (
        this.validateUserLoading ||
        this.getUserLoading ||
        this.getPlansLoading ||
        this.getLocalesLoading ||
        this.getCountriesLoading
      )
    },
    validated() {
      return this.form.is_accepted ? this.form.is_accepted : false
    },
    userSubscription() {
      return this.user &&
        this.user.data &&
        this.user.data.subscription &&
        this.user.data.subscription.data
        ? this.user.data.subscription.data
        : {}
    },
    hasUserSubscription() {
      return (
        this.userSubscription && Object.keys(this.userSubscription).length > 0
      )
    },
    subscriptionEnded() {
      const { subscription_end_at } = this.userSubscription
      if (!subscription_end_at) {
        return false
      }

      return Date.parse(subscription_end_at) - Date.parse(new Date()) < 0
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getUser({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.user.data,
        ...this.user.data.address,
        country_id: this.user.data.address
          ? this.user.data.address.country.id
          : null,
        locale_id: this.user.data.locale ? this.user.data.locale.id : null,
        new_credits: 0,
      }
      this.getCountries({
        name: this.form.country ? this.form.country.name : '',
      })
    } else {
      this.getCountries({ perPage: 10 })
    }
    this.getLocales()
    this.getPlans()
  },
  methods: {
    ...mapMutations('common', ['setActiveNavTab']),
    ...mapActions('user', [
      'saveUser',
      'getUser',
      'validateUser',
      'getPlans',
      'cancelPlan',
      'addPlan',
      'manageCredits',
    ]),
    ...mapActions('locale', ['getLocales']),
    ...mapActions('country', ['getCountries']),
    async submit() {
      await this.saveUser({
        routeParameters: {
          id: this.$route.params.id,
        },
        ...this.form,
      })
    },
    async changePlane(tab) {
      this.activePlane = tab.name
    },
    filterCountries(search) {
      this.getCountries({ name: search })
    },
    async userValidation() {
      await this.validateUser({
        routeParameters: {
          id: this.$route.params.id,
        },
        is_accepted: this.form.is_accepted,
      }).then(() => {
        if (this.validateUserError.status === null && this.form.is_accepted) {
          this.$notify({
            type: 'success',
            message: 'User validation succeed',
            title: 'Successful',
          })
        } else if (
          this.validateUserError.status === null &&
          !this.form.is_accepted
        ) {
          this.$notify({
            type: 'success',
            message: 'User invalidation succeed',
            title: 'Successful',
          })
        }
      })
    },
    onChangeSubscription() {
      if (this.userSubscription.stripe_id) {
        this.$alert(
          'User account is connected to Stripe. You must upgrade the plan on Stripe Dashboard. ' +
            'It will automatically upgrade the user account on the BS2BO platform.',
          'Change plan',
          {
            confirmButtonText: 'OK',
          }
        )
      } else {
        // todo
      }
    },
    onCancelSubscription() {
      this.$confirm(
        'Do you want to cancel the current user subscription ?' +
          (this.userSubscription.stripe_id
            ? 'The Stripe subscription will be canceled too'
            : ''),
        'Cancel subscription',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(() => {
        this.cancelPlan({
          routeParameters: {
            userid: this.user.data.id,
          },
        })
      })
    },
    onAddSubscription() {
      this.addSubscriptionVisible = true
    },
    async saveAddSubscription() {
      await this.addPlan({
        routeParameters: {
          userid: this.user.data.id,
        },
        ...this.addPlanForm,
      })

      if (!this.addPlanError.data) {
        this.addSubscriptionVisible = false
      }
    },
    onAddPlanSelectChange() {
      this.addPlanForm.credits = this.plans.data.find(
        i => i.id === this.addPlanForm.plan_id
      ).credits
    },
    onManageCredits() {
      this.manageCreditsVisible = true
    },
    async saveManageCredits() {
      await this.manageCredits({
        routeParameters: {
          userid: this.user.data.id,
        },
        ...this.manageCreditsForm,
      })

      if (!this.manageCreditsError.data) {
        this.manageCreditsForm.credits = 0
        this.manageCreditsVisible = false
      }
    },
  },
}
</script>
