import { generateAction } from '../builder'

const getAdaptationBudgets = generateAction(
  'getAdaptationBudgets',
  'admin/adaptation-budgets'
)

export default {
  getAdaptationBudgets,
}
