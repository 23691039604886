import List from '@/views/Thematic/List.vue'
import Save from '@/views/Thematic/Save.vue'

export const thematicRoutes = [
  {
    path: '/thematics',
    name: 'thematic-index',
    component: List,
  },
  {
    path: '/thematic/:id?',
    name: 'thematic-save',
    component: Save,
    meta: { menuIndex: '/thematics' },
  },
]
