import { generateAction } from '../builder'

import router from '@/router'

const getUsers = generateAction('getUsers', 'admin/users')
const getUser = generateAction('getUser', 'admin/users/{id}')
const saveUser = generateAction('saveUser', 'admin/users/{id}', 'POST', () => {
  return router.push({
    name: 'user-index',
  })
})
const deleteUser = generateAction('deleteUser', 'admin/users/{id}', 'DELETE')
const getLibraries = generateAction(
  'getLibraries',
  'admin/users/libraries/{id}'
)
const validateUser = generateAction(
  'validateUser',
  'admin/users/validate/{id}',
  'POST'
)
const getPlans = generateAction('getPlans', 'admin/plans')
const cancelPlan = generateAction(
  'cancelPlan',
  'admin/plans/{userid}/cancel',
  'DELETE'
)
const addPlan = generateAction('addPlan', 'admin/plans/{userid}', 'POST')
const manageCredits = generateAction(
  'manageCredits',
  'admin/users/{userid}/credits',
  'POST'
)
const getUserSearches = generateAction(
  'getUserSearches',
  'admin/users/searches/{id}'
)

export default {
  getUsers,
  saveUser,
  getUser,
  deleteUser,
  getLibraries,
  validateUser,
  getPlans,
  cancelPlan,
  addPlan,
  manageCredits,
  getUserSearches,
}
