<template>
  <div class="save">
    <save-page-header title="AI prompt"></save-page-header>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native=""
    >
      <el-form-item label="Label">
        <el-input v-model="form.label" type="text" title="AI label" disabled />
      </el-form-item>
      <el-form-item label="Content" prop="contents" :error="errors['contents']">
        <translatable-input
          :errors="errors"
          :source.sync="form.contents"
          sourceKey="contents"
          :label-width="labelWidth"
          inputType="textarea"
          placeholder="Enter the AI prompt"
          autosize
        />
      </el-form-item>
      <div class="align-right">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveAiPromptLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            Save AI prompt
          </el-button>
        </el-col>
      </div>
      <h3 class="title primary-color pt-30">Variables</h3>
      <el-table :data="variables">
        <el-table-column prop="name" label="Name" width="200">
          <template v-slot="scope">
            <strong>{{ '\{\{' + scope.row.name + '\}\}' }}</strong>
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="Description"
        ></el-table-column>
      </el-table>
      <el-card class="mt-30">
        <div slot="header">
          <h3 class="title primary-color">JSON structure output</h3>
        </div>
        <pre>{{ transformJson(form.json_structure) }}</pre>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import SavePageHeader from '@/components/ui/SavePageHeader'
import { AI_PROMPT_VARIABLES } from '@/utils/constant'
import TranslatableInput from '@/components/Form/TranslatableInput.vue'
import { initMultilingual } from '@/services/helpers'
import { error } from '@/services/api'

export default {
  components: {
    TranslatableInput,
    SavePageHeader,
  },
  mixins: [apiError('saveAiPromptError'), labelWidth],
  data: () => ({
    form: {
      contents: initMultilingual(),
      json_structure: '',
    },
    selectedPane: 'en',
    variables: AI_PROMPT_VARIABLES,
  }),
  computed: {
    ...mapState('aiPrompt', [
      'aiPrompt',
      'saveAiPromptError',
      'saveAiPromptLoading',
    ]),
  },
  async mounted() {
    await this.getAiPrompt({
      routeParameters: {
        id: this.$route.params.id,
      },
    })
    this.form = {
      ...this.aiPrompt.data,
      contents: initMultilingual(this.aiPrompt.data.contents),
    }
  },
  methods: {
    error,
    ...mapActions('aiPrompt', ['saveAiPrompt', 'getAiPrompt']),
    async submit() {
      await this.saveAiPrompt({
        routeParameters: {
          id: this.$route.params.id,
        },
        ...this.form,
      })
    },
    transformJson(json) {
      return json ? JSON.stringify(JSON.parse(json), null, 2) : ''
    },
  },
}
</script>
