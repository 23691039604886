import { generateMutations } from '../builder'
import Vue from 'vue'

export default {
  ...generateMutations('getContacts', 'contacts'),
  ...generateMutations('saveContact', 'contact'),
  ...generateMutations('getContact', 'contact'),
  ...generateMutations('deleteContact'),
  ...generateMutations('getContactTypes', 'contactTypes'),
  setStateContactData(state, { property, item, name, value }) {
    let index = state[property].data.findIndex(obj => obj.id === item.id)
    if (state[property].data[index][name] !== undefined) {
      state[property].data[index][name] = value
    } else {
      Vue.set(state[property].data[index], name, value)
    }
  },
}
