import { generateState } from '../builder'

export default {
  companies: [],
  company: {},
  ...generateState('getCompanies'),
  ...generateState('saveCompany'),
  ...generateState('getCompany'),
  ...generateState('deleteCompany'),
}
