import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Layouts
import Default from './layouts/Default'
import Empty from './layouts/Empty'
Vue.component('default-layout', Default)
Vue.component('empty-layout', Empty)

// Element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/element-variables.scss'
// todo dynamic local
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale })

// Ionicons
import 'ionicons/dist/css/ionicons.min.css'

// Global styles
import '@/assets/styles/app.scss'

// Portal vue
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
