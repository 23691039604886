<template>
  <div class="list">
    <portal to="search">
      <search placeholder="Find a request..." v-model="globalFilter"></search>
    </portal>
    <div class="space-between mb-50">
      <h2 class="title primary-color">REQUEST LIST</h2>
      <router-link :to="{ name: 'request-save' }" tag="span">
        <el-button type="primary" class="plain-primary" plain>
          <i class="el-icon-plus"></i>
          CREATE A NEW REQUEST
        </el-button>
      </router-link>
    </div>
    <requests-table :show-filter="true"></requests-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Search from '@/components/Form/Search.vue'
import { pagination } from '@/mixins'
import RequestsTable from '@/views/Request/RequestsTable.vue'
export default {
  components: {
    Search,
    RequestsTable,
  },
  mixins: [pagination('getRequests')],
  methods: {
    ...mapActions('request', ['getRequests']),
  },
}
</script>
