import { error } from '@/services/api'

export function apiError(attribute, errorsName = 'errors') {
  return {
    computed: {
      [errorsName]() {
        return error(this[attribute])
      },
    },
  }
}

export function pagination(getList, filterName = 'name', search = 'search') {
  return {
    data: () => {
      return {
        perPage: 10,
        currentPage: 1,
        globalFilter: '',
        extraFilters: {},
      }
    },
    watch: {
      currentPage() {
        this[search](false)
      },
      perPage() {
        this[search]()
      },
      globalFilter() {
        this[search]()
      },
      extraFilters: {
        handler: function() {
          this[search]()
        },
        deep: true,
      },
    },
    methods: {
      search(resetCurrentPage = true) {
        if (resetCurrentPage) {
          this.currentPage = 1
        }
        this[getList]({
          ...{
            [filterName]: this.globalFilter,
            ...this.extraFilters,
          },
          page: this.currentPage,
          perPage: this.perPage,
        })
      },
    },
  }
}

export const labelWidth = {
  data: () => {
    return {
      labelWidth: 180,
    }
  },
}
