import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

import { authRoutes } from './auth'
import { homeRoutes } from './home'
import { genreRoutes } from './genre'
import { timeRoutes } from './time'
import { thematicRoutes } from './thematic'
import { audienceRoutes } from './audience'
import { categoryRoutes } from './category'
import { formatRoutes } from './format'
import { periodRoutes } from './period'
import { administratorRoutes } from './administrator'
import { countryRoutes } from './country'
import { contactRoutes } from './contact'
import { readingSheetsRoutes } from './readingSheets'
import { companyRoutes } from './company'
import { userRoutes } from './user'
import { reporterRoutes } from './reporter'
import { requestRoutes } from './request'
import { authorRoutes } from './author'
import { newsRoutes } from './news'
import { dashboardRoutes } from './dashboard'
import { literaryPrizeRoutes } from '@/router/literaryPrize'
import { aiPromptRoutes } from '@/router/aiPrompt'

const routes = [
  ...homeRoutes,
  ...authRoutes,
  ...administratorRoutes,
  ...audienceRoutes,
  ...genreRoutes,
  ...categoryRoutes,
  ...formatRoutes,
  ...periodRoutes,
  ...thematicRoutes,
  ...timeRoutes,
  ...countryRoutes,
  ...contactRoutes,
  ...readingSheetsRoutes,
  ...companyRoutes,
  ...userRoutes,
  ...reporterRoutes,
  ...requestRoutes,
  ...authorRoutes,
  ...newsRoutes,
  ...dashboardRoutes,
  ...literaryPrizeRoutes,
  ...aiPromptRoutes,
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/me')

  if (to.meta.guest !== true) {
    if (!store.getters['auth/isLoggedIn']) {
      return next({ name: 'auth-login' })
    }
  } else {
    if (store.getters['auth/isLoggedIn']) {
      return next({ name: 'home' })
    }
  }

  await store.commit('common/setReady', true)
  return next()
})

export default router
