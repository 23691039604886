<template>
  <div class="lightbox">
    <h2 class="lightbox-title padding-box f-28 primary-color text-center">
      {{ title }}
    </h2>
    <div class="lightbox-main padding-box">
      <slot></slot>
    </div>
    <div class="action-buttons vertical-align">
      <div v-if="btn1" class="left f-14 pointer" @click="$emit('button1')">
        {{ btn1 }}
      </div>
      <el-button
        v-if="btn2"
        type="primary"
        class="f-14 large"
        @click="$emit('button2')"
        native-type="submit"
        :loading="btn2Loading"
      >
        {{ btn2 }}
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    btn1: {
      required: false,
      type: String,
    },
    btn2: {
      required: false,
      type: String,
    },
    btn2Loading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.lightbox {
  padding-top: 3.7rem;
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px #ebebeb;
  margin: 4rem 2rem 0 2rem;
  width: 100%;
  max-width: 65rem;
}
.padding-box {
  padding-left: 3.7rem;
  padding-right: 3.7rem;
}
</style>
