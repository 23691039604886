<template>
  <div class="list">
    <portal to="search">
      <search placeholder="Find a contact..." v-model="globalFilter"></search>
    </portal>
    <div class="space-between mb-50">
      <h2 class="title primary-color">CONTACT LIST</h2>
      <router-link :to="{ name: 'contact-save' }" tag="span">
        <el-button type="primary" class="plain-primary" plain>
          <i class="el-icon-plus"></i>
          CREATE A NEW CONTACT
        </el-button>
      </router-link>
    </div>
    <el-table :data="contacts.data" v-loading="getContactsLoading">
      <el-table-column prop="id" label="#" width="75"> </el-table-column>
      <el-table-column
        prop="name"
        label="Contact name"
        min-width="150"
      ></el-table-column>
      <el-table-column label="Company" min-width="150">
        <template v-slot="scope">
          {{ scope.row.company ? scope.row.company.name : '-' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="contact_type.label"
        label="Contact Type"
        min-width="150"
      >
      </el-table-column>
      <el-table-column label="Actions" width="220">
        <template v-slot="scope">
          <router-link
            :to="{ name: 'contact-save', params: { id: scope.row.id } }"
            tag="span"
            class="ml-10"
          >
            <el-button type="primary" size="small" class="plain-primary" plain>
              <i class="el-icon-edit" />
            </el-button>
          </router-link>
          <el-popconfirm
            confirmButtonText="OK"
            cancelButtonText="No, Thanks"
            icon="el-icon-info"
            iconColor="red"
            title="Are you sure to delete this?"
            @confirm="confirmDelete(scope.row.id)"
            @cancel="
              $notify({
                title: 'Cancel',
                message: 'Deletion cancelled',
                type: 'info',
              })
            "
          >
            <el-button
              size="small"
              type="danger"
              slot="reference"
              class="ml-10"
            >
              <i class="el-icon-delete" />
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :meta="contacts.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
import Search from '@/components/Form/Search.vue'

export default {
  components: {
    Paginator,
    Search,
  },
  mixins: [pagination('getContacts')],
  computed: {
    ...mapState('contact', ['contacts', 'getContactsLoading']),
  },
  mounted() {
    this.getContacts()
  },
  methods: {
    ...mapActions('contact', ['getContacts', 'deleteContact']),
    async confirmDelete(id) {
      try {
        await this.deleteContact({
          routeParameters: {
            id,
          },
        })
        this.search(true)
        this.$notify({
          title: 'Success',
          message: 'Deletion succeed',
          type: 'success',
        })
      } catch (e) {
        this.$notify({
          title: 'Failed',
          message: 'Deletion Failed',
          type: 'danger',
        })
      }
    },
  },
}
</script>
