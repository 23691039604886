<template>
  <div>
    <p><b>Language :</b> {{ locale }}</p>
    <p style="white-space: pre-wrap">{{ content }}</p>
  </div>
</template>

<script>
export default {
  props: {
    locale: {
      type: () => 'fr' | 'en',
      default: 'en',
    },
    content: {
      type: String,
      default: '',
    },
  },
}
</script>
