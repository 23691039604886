<template>
  <div class="translatable">
    <p
      v-if="title"
      class="form-title bold"
      :style="[
        { 'min-width': labelWidth + 'px' },
        { 'max-width': labelWidth + 'px' },
      ]"
    >
      {{ title }}
    </p>
    <el-tabs type="card" class="translatable-tabs" v-model="selectedPane">
      <el-tab-pane
        v-for="locale in locales"
        :label="locale.code"
        :key="locale.code"
        :name="locale.code"
      >
        <el-form-item
          :prop="sourceKey"
          :error="errors[sourceKey + '.' + locale.code]"
        >
          <el-input
            :type="inputType"
            :rows="rows"
            @keyup.native="$emit('update:source', source)"
            v-model="source[locale.code]"
            :disabled="isDisabled"
            :placeholder="placeholder"
            :autosize="autosize"
          />
        </el-form-item>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    source: {
      type: Object,
      required: true,
    },
    sourceKey: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: () => {},
    },
    title: {
      required: false,
      type: String,
    },
    labelWidth: {
      required: false,
      type: Number,
      default: () => {},
    },
    inputType: {
      type: String,
      required: false,
      default: () => 'text',
    },
    rows: {
      type: Number,
      required: false,
      default: () => 1,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => '',
    },
    defaultSelectedPane: {
      type: String,
      default: () => 'en',
    },
    autosize: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      selectedPane: this.defaultSelectedPane,
    }
  },
  computed: {
    ...mapState('locale', ['locales', 'paneLang']),
  },
  watch: {
    // the previous logic doesnt work fine in more than one errors
    errors(errors) {
      if (errors) {
        for (let i = 0, len = this.locales.length; i < len; i++) {
          if (errors[this.sourceKey + '.' + this.locales[i].code]) {
            this.selectedPane = this.locales[i].code
            break
          }
        }
      }
    },
    paneLang(val) {
      if (val !== this.selectedPane) {
        this.selectedPane = val
      }
    },
    defaultSelectedPane(value) {
      if (value) {
        this.selectedPane = value
      }
    },
  },
}
</script>
