<template>
  <div class="vertical-align">
    <div class="addlist authorlist-container">
      <el-row class="addlist-title">
        <el-col :span="16">Selected author(s)</el-col>
        <el-col :span="8">Author type</el-col>
      </el-row>
      <div class="tag-list">
        <template v-if="value.length">
          <el-row
            :gutter="20"
            v-for="(item, index) in value"
            :key="index"
            class="tag-item vertical-align"
          >
            <el-col :span="15">
              <router-link
                :to="{ name: 'author-save', params: { id: item.id } }"
                class="info f-15 primary-color underline pointer"
                target="_blank"
              >
                {{ item.name }}
              </router-link>
            </el-col>
            <el-col :span="7">
              <el-form-item :errors="error">
                <el-select
                  :value="item.type"
                  @input="changeAuthorType(index, $event)"
                >
                  <el-option
                    v-for="type in types.data"
                    :key="type.id"
                    :label="type.name"
                    :value="type.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-button
                type="danger"
                class="plain-danger all-align"
                icon="el-icon-delete"
                size="small"
                @click="remove(index)"
                plain
              ></el-button>
            </el-col>
          </el-row>
        </template>
        <el-row v-else>
          <el-col :span="24" class="p-10 text-center">
            No data
          </el-col>
        </el-row>
      </div>
      <el-form-item class="input-nomargin" :error="error">
        <el-select
          ref="author"
          v-model="authorSelect"
          @change="addAuthor(authorSelect)"
          autocomplete="on"
          filterable
          :filter-method="filterAuthors"
          reserve-keyword
          placeholder="Add an existing author..."
        >
          <i class="el-icon-plus el-input__icon primary-color" slot="prefix">
          </i>
          <el-option
            v-for="(author, index) in authors.data"
            :key="index"
            :label="author.name"
            :value="author.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
  </div>
</template>
<script>
import { labelWidth } from '@/mixins'
import { mapActions, mapState } from 'vuex'

export default {
  mixins: [labelWidth],
  emits: ['remove', 'item-changed'],
  data() {
    return {
      authorSelect: '',
    }
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    error: {
      type: () => Object | undefined,
      required: true,
    },
  },
  mounted() {
    this.getAuthorTypes()
  },
  methods: {
    ...mapActions('author', ['getAuthors', 'getAuthorTypes']),
    changeAuthorType(index, value) {
      this.$emit('changed-author-type', {
        index: index,
        value: value,
      })

      let copy = structuredClone(this.value)
      copy[index].type = value
      this.$emit('input', copy)
    },
    remove(index) {
      let copy = this.value
      copy.splice(index, 1)
      this.$emit('input', copy)
    },
    filterAuthors(search) {
      this.getAuthors({ name: search })
    },
    addAuthor(item) {
      if (item) {
        let getItem = this.authors.data?.find(obj => obj.id === item)
        let listItem = this.value?.find(obj => obj.id === item)

        let copy = structuredClone(this.value)

        if (!listItem && getItem) {
          let newItem = Object.assign({}, getItem, {
            type: 1,
          })

          // Emit we added an item
          this.$emit('added-item', newItem)

          copy.push(newItem)
          this.$emit('input', copy)

          this.authorSelect.value = null
        }
      }
    },
    setAuthorSearch(value) {
      this.filterAuthors(value)
      this.$refs.author.focus()
      this.$refs.author.$emit('input', value)
      this.$refs.author.$emit('change', value)
    }
  },
  computed: {
    ...mapState('author', ['authors', 'getAuthorsLoading', 'types']),
  },
}
</script>
