<template>
  <div class="list reporter-list">
    <portal to="search">
      <search placeholder="Find a reporter..." v-model="globalFilter"></search>
    </portal>

    <div class="space-between mb-50">
      <h2 class="title primary-color">REPORTER LIST</h2>
      <router-link :to="{ name: 'reporter-save' }" tag="span">
        <el-button type="primary" class="plain-primary" plain>
          <i class="el-icon-plus"></i>
          CREATE A NEW REPORTER
        </el-button>
      </router-link>
    </div>

    <reporters-table
      :data="reporters.data"
      :filters="extraFilters"
      :is-loading="getReportersLoading || deleteReporterLoading"
      @filter-change="value => (extraFilters = value)"
      @confirm-delete="value => confirmDelete(value)"
    />

    <paginator
      :meta="reporters.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
import Search from '@/components/Form/Search.vue'
import ReportersTable from './ReportersTable.vue'

export default {
  name: 'ReporterList',
  components: {
    Paginator,
    Search,
    ReportersTable,
  },
  mixins: [pagination('getReporters', 'title')],
  computed: {
    ...mapState('reporter', [
      'reporters',
      'getReportersLoading',
      'deleteReporterLoading',
    ]),
  },
  mounted() {
    this.getReporters()
  },
  methods: {
    ...mapActions('reporter', ['getReporters', 'deleteReporter']),
    async confirmDelete(id) {
      try {
        await this.deleteReporter({
          routeParameters: {
            id,
          },
        })
        this.search(true)
        this.$notify({
          title: 'Success',
          message: 'Deletion succeed',
          type: 'success',
        })
      } catch (e) {
        this.$notify({
          title: 'Failed',
          message: 'Deletion Failed',
          type: 'danger',
        })
      }
    },
  },
}
</script>
