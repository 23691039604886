import List from '@/views/Category/List.vue'
import View from '@/views/Category/View.vue'

export const categoryRoutes = [
  {
    path: '/categories',
    name: 'category-index',
    component: List,
  },
  {
    path: '/category/:id?',
    name: 'category-view',
    component: View,
    meta: { menuIndex: '/categories' },
  },
]
