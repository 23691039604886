import { generateAction } from '../builder'
import router from '@/router'

const getAdministrators = generateAction(
  'getAdministrators',
  'admin/administrators'
)
const getAdministrator = generateAction(
  'getAdministrator',
  'admin/administrators/{id}'
)
const saveAdministrator = generateAction(
  'saveAdministrator',
  'admin/administrators/{id}',
  'POST',
  () => {
    return router.push({
      name: 'administrator-index',
    })
  }
)
const deleteAdministrator = generateAction(
  'deleteAdministrator',
  'admin/administrators/{id}',
  'DELETE'
)

export default {
  getAdministrators,
  getAdministrator,
  saveAdministrator,
  deleteAdministrator,
}
