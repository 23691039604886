<template>
  <div id="app">
    <component v-if="ready" :is="layout">
      <router-view />
    </component>
    <div v-else v-loading.fullscreen.lock="true"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('common', ['ready']),
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout'
    },
  },
}
</script>
