<template>
  <div class="save">
    <save-page-header title="Time"></save-page-header>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native="submit"
    >
      <translatable-input
        :errors="errors"
        :source.sync="form.names"
        sourceKey="names"
        placeholder="New time"
        title="Name"
        :label-width="labelWidth"
      />
      <div class="align-right">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveTimeLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            {{ isUpdate ? 'Save Time' : 'Create Time' }}
          </el-button>
        </el-col>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import TranslatableInput from '@/components/Form/TranslatableInput'
import { initMultilingual } from '@/services/helpers'
import SavePageHeader from '@/components/ui/SavePageHeader'

export default {
  components: {
    TranslatableInput,
    SavePageHeader,
  },
  mixins: [apiError('saveTimeError'), labelWidth],
  data: () => ({
    form: {
      names: initMultilingual(),
    },
  }),
  computed: {
    ...mapState('time', ['time', 'saveTimeError', 'saveTimeLoading']),
    isUpdate() {
      return this.$route.params.id !== undefined
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getTime({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.time.data,
        names: initMultilingual(this.time.data.names),
      }
    }
  },
  methods: {
    ...mapActions('time', ['saveTime', 'getTime']),
    async submit() {
      await this.saveTime({
        routeParameters: {
          id: this.$route.params.id,
        },
        ...this.form,
      })
    },
  },
}
</script>
