import List from '@/views/Audience/List.vue'
import View from '@/views/Audience/View.vue'

export const audienceRoutes = [
  {
    path: '/audiences',
    name: 'audience-index',
    component: List,
  },
  {
    path: '/audience/:id?',
    name: 'audience-view',
    component: View,
    meta: { menuIndex: '/audiences' },
  },
]
