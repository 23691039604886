export default {
  paneLang: 'en',
  locales: [
    {
      label: 'english',
      code: 'en',
    },
    {
      label: 'french',
      code: 'fr',
    },
    {
      label: 'spanish',
      code: 'es',
    },
    {
      label: 'deutsch',
      code: 'de',
    },
    {
      label: 'Italian',
      code: 'it',
    },
  ],
  languages: [],
  userLocales: [],
}
