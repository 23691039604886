import { generateState } from '../builder'

export default {
  allNews: [],
  news: {},
  ...generateState('getAllNews'),
  ...generateState('saveNews'),
  ...generateState('getNews'),
  ...generateState('deleteNews'),
  ...generateState('deleteImage'),
}
