<template>
  <div
    class="save reporter-item-page"
    v-loading.fullscreen.lock="isPageLoading"
  >
    <el-row type="flex" justify="space-between" align="middle" class="mb-20">
      <h2 class="title primary-color">{{ formTitle }}</h2>
      <el-select :value="lang" class="el-col-md-2" @change="onLanguageChange">
        <el-option
          v-for="lang in langs"
          :key="lang.code"
          :label="lang.name"
          :value="lang.code"
        >
        </el-option>
      </el-select>
    </el-row>

    <el-form @submit.prevent.native="">
      <el-row :gutter="10">
        <el-col :md="12" :sm="24">
          <el-form-item label="Title" :error="formErrors['title']">
            <el-input
              type="text"
              v-model="form.title"
              placeholder="Title"
              @input="clearError('title')"
            />
          </el-form-item>
        </el-col>
        <el-col :md="12" :sm="24">
          <el-form-item
            label="Expected publication date"
            :error="formErrors['date']"
          >
            <el-date-picker
              class="custom-date-picker"
              v-model="form.date"
              type="date"
              placeholder="Pick a date"
              @change="clearError('date')"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12" :sm="24">
          <el-form-item label="User(s)">
            <el-select
              multiple
              filterable
              remote
              v-model="form.reporterUserIds"
              value-key="id"
              placeholder="Select a user"
              class="el-col-24"
              autocomplete="on"
              :loading="getReporterUsersLoading"
              :remote-method="getReporterUsersList"
              @visible-change="onUsersAutocopleteVisibleChange"
            >
              <el-option
                v-for="user in reporterUsers.data"
                :key="user.id"
                :label="user.fullname"
                :value="user.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="12" :sm="24">
          <el-form-item label="User group(s)">
            <el-select
              multiple
              v-model="form.reporterUserRoleIds"
              value-key="id"
              placeholder="Select a user group"
              class="el-col-24"
              :loading="getReporterUserRolesLoading"
              @visible-change="onUserRolesAutocopleteVisibleChange"
            >
              <el-option
                v-for="userRole in reporterUserRoles.data"
                :key="userRole.id"
                :label="formattedUserRole(userRole.name)"
                :value="userRole.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :md="12" :sm="24">
          <el-form-item label="Status">
            <el-select
              v-model="form.reporterStatusId"
              placeholder="Select a status"
              class="el-col-24"
              :disabled="getReporterStatusesLoading"
            >
              <el-option
                v-for="status in statuses.data"
                :key="status.id"
                :label="status.name"
                :value="status.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" v-for="category in categories" :key="category.name">
          <el-form-item
            :label="category.title"
            class="mb-0 mt-20"
          ></el-form-item>
          <el-row
            v-show="!item.deleted"
            v-for="item in reporterTextsData[category.name]"
            :key="item.id"
            class="mb-10"
            type="flex"
            justify="space-between"
            align="middle"
          >
            <el-col :span="22">
              <textarea
                autocomplete="off"
                placeholder=""
                class="el-textarea__inner reporter-text-input"
                v-model="item.text"
                @change="onTextChange(item)"
              ></textarea>
            </el-col>
            <el-col :span="2" class="delete-text-icon-wrapper">
              <el-popconfirm
                confirmButtonText="OK"
                cancelButtonText="No, Thanks"
                icon="el-icon-info"
                iconColor="red"
                title="Are you sure to delete this?"
                @confirm="onDeleteReporterText(item)"
              >
                <el-button
                  size="small"
                  type="danger"
                  slot="reference"
                  class="ml-10"
                >
                  <i class="el-icon-delete" />
                </el-button>
              </el-popconfirm>
            </el-col>
          </el-row>
          <el-col>
            <el-button
              type="primary"
              class="plain-primary add-block-button"
              plain
              @click="onAddReporterText(category.name)"
            >
              <i class="el-icon-plus"></i>
              Ajouter un block
            </el-button>
          </el-col>
        </el-col>
      </el-row>

      <el-form-item class="mt-50">
        <el-button native-type="submit" type="primary" @click="submit">
          Enregistrer
        </el-button>
      </el-form-item>
    </el-form>

    <div v-if="isUpdate">
      <reporter-booklist
        :reporter-id="reporterId"
        :reporter-books-data="reporterBooksData"
        @on-book-delete="getReporterBooksData"
      />
    </div>

    <el-dialog
      :visible.sync="changeLocaleDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <h3 class="text-center">
        All form changes will be saved before changing locale. Continue ?
      </h3>
      <span slot="footer" class="dialog-footer">
        <el-row type="flex" justify="right">
          <el-button @click="changeLocaleDialogVisible = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="onChageLocaleConfirm"
            >Confirm</el-button
          >
        </el-row>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import ReporterBooklist from './ReporterBooklist'

const STATUS_DRAFT_ID = 1

export default {
  name: 'ReporterSave',
  components: {
    ReporterBooklist,
  },
  data() {
    return {
      form: {
        title: '',
        date: null,
        reporterUserIds: [],
        reporterUserRoleIds: [],
        reporterStatusId: null,
      },
      lang: 'en',
      newLang: null,
      reporterTextsData: {
        rights: [],
        alerts: [],
        buzz: [],
        news: [],
      },
      categories: [
        { name: 'rights', title: 'Film rights' },
        { name: 'alerts', title: 'Alerts' },
        { name: 'buzz', title: 'Buzz' },
        { name: 'news', title: 'news' },
      ],
      langs: [
        { code: 'en', name: 'en' },
        { code: 'fr', name: 'fr' },
        { code: 'es', name: 'es' },
        { code: 'de', name: 'de' },
        { code: 'it', name: 'it' },
      ],
      formErrors: {
        title: null,
        date: null,
      },
      changeLocaleDialogVisible: false,
      isCahngeLocaleLoading: false,
    }
  },
  computed: {
    ...mapState('reporter', [
      'reporter',
      'statuses',
      'getReporterLoading',
      'saveReporterError',
      'saveReporterLoading',
      'getReporterStatusesLoading',
      'reporterUsers',
      'getReporterUsersLoading',
      'reporterBooksData',
      'deleteReporterBookLoading',
      'getReporterBooksLoading',
      'reporterUserRoles',
      'getReporterUserRolesLoading',
    ]),
    isUpdate() {
      return this.$route.params.id !== undefined
    },
    reporterId() {
      return this.$route.params.id ? parseInt(this.$route.params.id) : null
    },
    isPageLoading() {
      return (
        this.getReporterLoading ||
        this.saveReporterLoading ||
        this.isCahngeLocaleLoading ||
        this.deleteReporterBookLoading ||
        this.getReporterBooksLoading
      )
    },
    formTitle() {
      return this.isUpdate ? 'EDIT REPORTER' : 'CREATE REPORTER'
    },
  },
  async mounted() {
    if (this.isUpdate) {
      this.getReporterData()
      this.getReporterBooksData()
    }

    await this.getReporterStatuses()

    if (!this.isUpdate) {
      this.form.date = new Date()
      this.form.reporterStatusId = STATUS_DRAFT_ID
    }
  },
  methods: {
    ...mapActions('reporter', [
      'saveReporter',
      'getReporter',
      'getReporterStatuses',
      'getReporterUsers',
      'getReporterUserRoles',
      'getReporterBooks',
    ]),
    ...mapMutations('reporter', ['setStateValue']),
    async getReporterData() {
      await this.getReporter({
        routeParameters: {
          id: this.reporterId,
        },
        lang: this.lang,
      })
      let data = this.reporter.data
      this.fillFormData(data)
      this.setStateValue({
        property: 'reporterUsers',
        value: { data: data.reporterUsers },
      })
      this.setStateValue({
        property: 'reporterUserRoles',
        value: { data: data.reporterUserRoles },
      })
    },
    fillFormData(data) {
      let reporterUserIds = data.reporterUsers.map(item => item.id)
      let reporterUserRoleIds = data.reporterUserRoles.map(item => item.id)
      let date = data.date ? new Date(data.date) : null
      this.form = {
        title: data.title,
        reporterStatusId: data.reporterStatusId,
        date,
        reporterUserIds,
        reporterUserRoleIds,
      }

      let reporterTextsData = {}
      let key = new Date().valueOf()
      Object.keys(data.reporterTextsData).forEach(category => {
        reporterTextsData[category] = []
        data.reporterTextsData[category].forEach(item => {
          let newItem = {
            ...item,
            key: key - item.id,
            modified: false,
            deleted: false,
          }
          reporterTextsData[category].push(newItem)
        })
      })
      this.reporterTextsData = reporterTextsData
    },
    async getReporterBooksData() {
      await this.getReporterBooks({
        routeParameters: {
          id: this.reporterId,
        },
        lang: this.lang,
      })
    },
    submit() {
      this.saveReporterData()
    },
    async saveReporterData() {
      let updatedTexts = []
      let deletedTextIds = []
      Object.keys(this.reporterTextsData).forEach(category => {
        this.reporterTextsData[category].forEach(item => {
          if (item.deleted === true) {
            deletedTextIds.push(item.id)
          }
          if (item.modified === true || item.id === null) {
            updatedTexts.push({
              id: item.id,
              text: item.text,
              textType: item.textType,
            })
          }
        })
      })

      let result = await this.saveReporter({
        id: this.reporterId,
        title: this.form.title,
        date: this.form.date,
        reporterStatusId: this.form.reporterStatusId,
        reporterUserIds: this.form.reporterUserIds,
        reporterUserRoleIds: this.form.reporterUserRoleIds,
        lang: this.lang,
        updatedTexts: updatedTexts,
        deletedTextIds: deletedTextIds,
      })

      if (result.data) {
        this.fillFormData(result.data)
        // Add new reporterId to the url
        if (!this.$route.params.id) {
          this.$router
            .replace({
              name: 'reporter-save',
              params: { id: result.data.id },
            })
            .catch(() => {})
        }
        this.$notify({
          title: 'Success',
          message: 'Successfully saved',
          type: 'success',
          duration: 3000,
        })
      }

      if (result.validationErrors) {
        Object.keys(this.formErrors).forEach(property => {
          if (result.validationErrors[property]) {
            this.formErrors[property] = result.validationErrors[property].join(
              ' '
            )
          } else {
            this.formErrors[property] = null
          }
        })
      }

      return result
    },
    getReporterUsersList(search) {
      let existingUserIds = this.form.reporterUserIds
      this.getReporterUsers({ search, existingUserIds })
    },
    clearError(property) {
      this.formErrors[property] = null
    },
    onUsersAutocopleteVisibleChange(isVisible) {
      if (isVisible) {
        this.getReporterUsersList('')
      }
    },
    onUserRolesAutocopleteVisibleChange(isVisible) {
      if (isVisible) {
        this.getReporterUserRoles()
      }
    },
    onLanguageChange(newLang) {
      this.newLang = newLang
      this.$forceUpdate()
      this.changeLocaleDialogVisible = true
    },
    async onChageLocaleConfirm() {
      this.isCahngeLocaleLoading = true
      this.changeLocaleDialogVisible = false
      let result = await this.saveReporterData()

      if (!result.validationErrors) {
        this.lang = this.newLang
        this.getReporterData()
        this.getReporterBooksData()
      }
      this.isCahngeLocaleLoading = false
    },
    onTextChange(item) {
      item.modified = true
    },
    onAddReporterText(category) {
      let newTextItem = {
        id: null,
        key: new Date().valueOf,
        modified: false,
        deleted: false,
        text: '',
        textType: category,
      }
      this.reporterTextsData[category].push(newTextItem)
    },
    onDeleteReporterText(item) {
      item.deleted = true
    },
    formattedUserRole(name) {
      let mapper = {
        essential: 'Essential',
        premium: 'Premium',
        vip: 'VIP',
      }

      return mapper[name] ? mapper[name] : name
    },
  },
}
</script>
