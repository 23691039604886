import api from '@/services/api'
import { Notification } from 'element-ui'

export function generateAction(
  action,
  route,
  method = 'GET',
  successCallback = () => {},
  errorCallback = () => {}
) {
  return async ({ commit }, parameters = {}) => {
    const { routeParameters, formData, inModal, ...dataParameters } = parameters
    const isFormData = formData && formData instanceof FormData

    const url = route
      .replace(/{([a-z]+)}/g, function(m, v) {
        return routeParameters[v] || ''
      })
      .replace(/\/+$/, '')

    commit('common/setLoadingProgressPercentage', 0, { root: true })
    commit(action + 'Loading')
    try {
      const response = await api.request({
        url,
        method,
        data: isFormData ? formData : { ...dataParameters },
        params: method === 'GET' ? dataParameters : null,
        onUploadProgress: progressEvent => {
          let loadingProgressPercentage = 0
          if (progressEvent.total) {
            loadingProgressPercentage = Math.round(
              (100 * progressEvent.loaded) / progressEvent.total
            )
          }
          commit(
            'common/setLoadingProgressPercentage',
            loadingProgressPercentage,
            { root: true }
          )
        },
      })
      if ((method === 'POST' || method === 'PUT') && action !== 'login') {
        Notification({
          title: 'Success',
          message:
            response.data && response.data.message
              ? response.data.message
              : 'Added successfully to the database',
          type: 'success',
          offset: 100,
          duration: 8000,
        })
      }
      commit(action + 'Success', response.data)

      successCallback({ inModal: inModal })
    } catch (e) {
      const { data, status, statusText } = e.response

      commit(action + 'Error', {
        data,
        status,
        statusText,
      })

      errorCallback()
    }
  }
}

const errorState = {
  data: null,
  status: null,
  statusText: null,
}

export function generateState(action) {
  return {
    [action + 'Loading']: false,
    [action + 'Error']: errorState,
  }
}

export function generateMutations(action, item) {
  return {
    [action + 'Loading']: state => {
      state[action + 'Loading'] = true
      state[action + 'Error'] = errorState
    },
    [action + 'Success']: (state, data) => {
      if (item) {
        state[item] = data
      }
      state[action + 'Loading'] = false
      state[action + 'Error'] = errorState
    },
    [action + 'Error']: (state, error) => {
      state[action + 'Loading'] = false
      state[action + 'Error'] = error
    },
  }
}
