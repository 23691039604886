import api from '@/services/api'
import { generateAction } from '../builder'

const getDashboardData = generateAction(
  'getDashboardData',
  'admin/dashboard/data'
)

const getSavedSearchUsers = async (context, params) => {
  let url = 'admin/dashboard/get-saved-search-users'
  let response = await api.request({
    method: 'post',
    url: url,
    data: {
      savedFilters: params.savedFilters,
    },
  })
  const result = response.data

  return result
}

export default {
  getDashboardData,
  getSavedSearchUsers,
}
