import store from '@/store'

export function getDeep(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj)
}

export function initMultilingual(defaultValues = {}) {
  return store.getters['locale/localeCodes'].reduce(
    (o, key) =>
      Object.assign(o, {
        [key]: defaultValues[key] ? defaultValues[key] : '',
      }),
    {}
  )
}
