<template>
  <div class="layout-header">
    <el-row>
      <el-col :span="19">
        <portal-target name="search"> </portal-target>
      </el-col>
      <el-col :span="5" class="p-10 pr-40">
        <el-button
          type="primary"
          class="plain-primary go-to-front"
          plain
          @click="openFrontURL()"
        >
          Go to front site
          <i class="icon ion-ios-arrow-round-forward ml-10 f-20"></i>
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  methods: {
    openFrontURL() {
      window.open(process.env.VUE_APP_PLATFORM_URL, '_blank')
    },
  },
}
</script>
