import { generateAction } from '../builder'

const getReadingSheets = generateAction('getReadingSheets', 'admin/books')
const getReadingSheet = generateAction('getReadingSheet', 'admin/books/{id}')
const saveReadingSheet = generateAction(
  'saveReadingSheet',
  'admin/books/{id}',
  'POST'
)
const getBookStatuses = generateAction(
  'getBookStatuses',
  'admin/utilities/book-statuses'
)
const getRightsStatuses = generateAction(
  'getRightsStatuses',
  'admin/utilities/rights-statuses'
)
const deleteFile = generateAction(
  'deleteFile',
  'admin/utilities/book-files/{book}/{file}',
  'DELETE'
)

const deleteManuscript = generateAction(
  'deleteManuscript',
  'admin/utilities/book-manuscript/{book}',
  'DELETE'
)
export default {
  getReadingSheets,
  saveReadingSheet,
  getReadingSheet,
  getBookStatuses,
  getRightsStatuses,
  deleteFile,
  deleteManuscript,
}
