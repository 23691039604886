<template>
  <div>
    <el-table :data="libraries.data" v-loading="getLibrariesLoading">
      <el-table-column prop="id" label="#" width="65"> </el-table-column>
      <el-table-column label="Reading sheet" min-width="160">
        <template v-slot="scope">
          <router-link
            tag="span"
            v-for="(book, index) in scope.row.books"
            :key="book.id"
            :to="{
              name: 'readingSheets-save',
              params: { id: book.id },
            }"
          >
            <i class="primary-color bold pointer">
              {{ book ? book.originalTitle : ''
              }}{{ scope.row.books.length === index + 1 ? '' : ',' }} </i
            ><br />
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Library name" min-width="80">
      </el-table-column>
    </el-table>
    <paginator
      :meta="libraries.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
export default {
  name: 'UserLibraryTab',
  components: {
    Paginator,
  },
  mixins: [pagination('getAllLibraries')],
  computed: {
    ...mapState('user', ['libraries', 'getLibrariesLoading']),
  },
  mounted() {
    this.getAllLibraries()
  },
  methods: {
    ...mapActions('user', ['getLibraries']),
    async getAllLibraries() {
      let userId = this.$route.params.id
      await this.getLibraries({
        routeParameters: {
          id: userId,
        },
        page: this.currentPage,
        perPage: this.perPage,
      })
    },
  },
}
</script>

<style scoped></style>
