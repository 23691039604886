<template>
  <div class="save reading-sheet-save">
    <div
      v-if="saveReadingSheetLoading"
      class="el-loading-mask is-fullscreen page-loading-progress"
    >
      <el-progress
        type="circle"
        :percentage="loadingProgressPercentage"
      ></el-progress>
    </div>
    <save-page-header
      title="UPDATING A READING SHEET"
      :show-language-select="true"
    ></save-page-header>
    <el-dialog
      v-if="newContactDialog"
      title="Create New Contact"
      :visible.sync="newContactDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <new-contact
        :in-modal="true"
        @created="newContactDialog = !newContactDialog"
      />
    </el-dialog>
    <el-dialog
      v-if="newAuthorDialog"
      title="Create New Author"
      :visible.sync="newAuthorDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <new-author
        :in-modal="true"
        @created="newAuthorDialog = !newAuthorDialog"
      />
    </el-dialog>
    <el-dialog
      v-if="newCompanyDialog"
      title="Create New Company"
      :visible.sync="newCompanyDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <new-company
        :in-modal="true"
        @created="newCompanyDialog = !newCompanyDialog"
      />
    </el-dialog>
    <generate-reading-sheets-modal
      :visible="generateReadingSheets"
      @close="generateReadingSheets = false"
      @generation-launched="form.is_ai_generation_running = true"
      :book-id="parseInt(this.$route.params.id)"
    />
    <el-form
      ref="form"
      :label-width="labelWidth + 'px'"
      v-loading="isLoading"
      class="book-save-form"
    >
      <el-row :gutter="20">
        <el-col :span="16" class="left-column">
          <div class="form-items">
            <el-form-item
              label="Original title"
              :error="errors['original_title']"
            >
              <el-input v-model="form.original_title"></el-input>
            </el-form-item>
            <translatable-input
              :errors="errors"
              :source.sync="form.titles"
              sourceKey="titles"
              title="Title"
              :label-width="labelWidth"
              placeholder="Enter the title"
            />
            <translatable-input
              title="Detailed summaries"
              :errors="errors"
              :source.sync="form.detailed_summaries"
              sourceKey="detailed_summaries"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Detailed summaries"
            />
            <div class="vertical-align">
              <div
                :style="{ minWidth: labelWidth + 'px' }"
                class="grey-color bold f-16 text-right pr-10"
              >
                Author(s)
              </div>
              <div class="addlist authorlist-container">
                <authors-selection
                  :error="errors['authorList']"
                  v-model="form.authorList"
                  @added-item="
                    item => {
                      this.setStateData({
                        property: 'readingSheet',
                        item: 'authorList',
                        value: item,
                      })
                    }
                  "
                  @changed-author-type="
                    setItemChangeValue(
                      $event.value,
                      $event.index,
                      'authorList',
                      'type'
                    )
                  "
                >
                </authors-selection>
              </div>
            </div>

            <div
              class="primary-color italic underline text-right mt-10 pointer"
              @click="newAuthorDialog = true"
            >
              Can't find the author?
              <span class="bold">create a new one.</span>
            </div>
            <el-row>
              <el-form-item label="Serie" :error="errors['serie']">
                <el-switch
                  v-model="form.serie"
                  active-text="Yes"
                  inactive-text="No"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </el-form-item>
            </el-row>
            <div v-if="form.serie === 1" class="mb-20">
              <el-row
                v-for="(item, index) in form.seriesList"
                :key="index"
                :gutter="10"
              >
                <el-col :span="13">
                  <el-form-item
                    label="Episode title"
                    :error="errors[`seriesList.${index}.name`]"
                  >
                    <el-input
                      :value="form.seriesList[index].name"
                      @input="
                        setItemChangeValue($event, index, 'seriesList', 'name')
                      "
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item
                    label="Episode number"
                    :error="errors[`seriesList.${index}.number`]"
                  >
                    <el-input
                      :value="form.seriesList[index].number"
                      @input="
                        setItemChangeValue(
                          $event,
                          index,
                          'seriesList',
                          'number'
                        )
                      "
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col v-if="form.seriesList.length > 1" :span="2">
                  <el-button
                    type="danger"
                    class="plain-danger all-align"
                    icon="el-icon-delete"
                    @click="removeItem(index, 'seriesList', form.seriesList)"
                    plain
                  ></el-button>
                </el-col>
              </el-row>
              <el-row class="align-right">
                <el-col :span="4">
                  <el-button
                    type="primary"
                    class="plain-primary"
                    plain
                    @click="addItem(form.seriesList, 'seriesList')"
                    >+ Add</el-button
                  >
                </el-col>
              </el-row>
            </div>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item
                  label="Native country"
                  :error="errors['original_country_id']"
                >
                  <el-select
                    v-model="form.original_country_id"
                    filterable
                    autocomplete="on"
                    :filter-method="filterCountries"
                  >
                    <el-option
                      v-for="country in countries.data"
                      :key="country.id"
                      :label="country.name"
                      :value="country.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item
                  label="Original language"
                  :error="errors['language_id']"
                >
                  <el-select v-model="form.language_id" filterable>
                    <el-option
                      v-for="language in languages.data"
                      :key="language.id"
                      :label="language.name"
                      :value="language.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Status" :error="errors['status_id']">
                  <el-select
                    v-model="form.status_id"
                    placeholder="Select the status..."
                    default-first-option
                  >
                    <el-option
                      v-for="status in statuses.data"
                      :key="status.id"
                      :label="status.label"
                      :value="status.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <p class="bold subtitle">CRITERIAS</p>
          <div class="ruler"></div>
          <div class="form-items">
            <el-tabs
              type="border-card"
              class="translatable-tabs"
              v-model="criteriaActiveTab"
            >
              <el-tab-pane
                v-for="(criteria, index) in criterias"
                :label="criteria.tabName"
                :key="index"
                :name="criteria.name"
              >
                <el-form-item
                  :label="criteria.fieldName"
                  :error="errors[criteria.name]"
                >
                  <el-select
                    v-if="criteria.type === 'select'"
                    v-model="form[criteria.name]"
                    value-key="id"
                    :placeholder="criteria.placeholder"
                    :multiple="criteria.multiple"
                    :filterable="criteria.filterable"
                    autocomplete="on"
                    clearable
                  >
                    <el-option
                      v-for="item in criteria.dataName"
                      :key="item.id"
                      :label="item[criteria.optionFieldName]"
                      :value="criteria.multiple ? item : item.id"
                    ></el-option>
                  </el-select>
                  <criteria-tree
                    v-if="criteria.type === 'tree'"
                    :source-tree="criteria.dataName"
                    :selected-items="form[criteria.name]"
                    @change="value => handleTreeChange(criteria.name, value)"
                  />
                </el-form-item>
              </el-tab-pane>
            </el-tabs>
          </div>
          <el-form-item label="Primary genre" :error="errors['primary_genre']">
            <el-radio-group v-model="form.primary_genre">
              <el-radio
                class="primary-genre-radio"
                v-for="genre in genreList"
                :key="genre.id"
                :label="genre.id"
                >{{ genre.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <p class="bold subtitle">PRESENTATION</p>
          <div class="ruler"></div>
          <div class="form-items">
            <translatable-input
              :errors="errors"
              :source.sync="form.subtitles"
              sourceKey="subtitles"
              title="Subtitle"
              :label-width="labelWidth"
              placeholder="Subtitle"
            />
            <translatable-input
              :errors="errors"
              :source.sync="form.pitches"
              sourceKey="pitches"
              title="Pitch"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Pitch"
            />
            <el-form-item
              label="BS2BO commentary"
              :error="errors['private_comment']"
            >
              <el-input
                v-model="form.private_comment"
                placeholder="BS2BO Commentary"
                type="textarea"
                :rows="9"
              ></el-input>
            </el-form-item>
            <translatable-input
              :errors="errors"
              :source.sync="form.public_comments"
              sourceKey="public_comments"
              title="Public commentary"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Public commentary"
            />
            <translatable-input
              :errors="errors"
              :source.sync="form.newsletter_comments"
              sourceKey="newsletter_comments"
              title="Reporter summary"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Reporter summary"
            />
            <translatable-input
              :errors="errors"
              :source.sync="form.summaries"
              sourceKey="summaries"
              title="Report"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Report"
            />
            <translatable-input
              :errors="errors"
              :source.sync="form.scouts"
              sourceKey="scouts"
              title="Scout"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Scout"
            />
            <el-row>
              <el-col :span="12">
                <el-form-item label="Times" :error="errors['time_id']">
                  <el-select v-model="form.time_id">
                    <el-option
                      v-for="time in times.data"
                      :key="time.id"
                      :label="time.name"
                      :value="time.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Periods" :error="errors['periods']">
                  <el-select
                    v-model="form.periods"
                    value-key="id"
                    filterable
                    multiple
                    remote
                    :remote-method="filterPeriods"
                  >
                    <el-option
                      v-for="period in periods.data"
                      :key="period.id"
                      :label="period.name"
                      :value="period"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <translatable-input
              :errors="errors"
              :source.sync="form.additional_periods"
              sourceKey="additional_periods"
              title="Complement periods"
              :label-width="labelWidth"
              placeholder="Enter the complement periods"
            />
            <el-form-item label="Places" :error="errors['places']">
              <el-select
                v-model="form.places"
                value-key="id"
                filterable
                multiple
                remote
                :remote-method="filterCountries"
              >
                <el-option
                  v-for="country in countries.data"
                  :key="country.id"
                  :label="country.name"
                  :value="country"
                ></el-option>
              </el-select>
            </el-form-item>
            <translatable-input
              :errors="errors"
              :source.sync="form.additional_places"
              sourceKey="additional_places"
              title="Complement places"
              :label-width="labelWidth"
              placeholder="Enter the complement places"
            />
            <translatable-input
              :errors="errors"
              :source.sync="form.durations"
              sourceKey="durations"
              title="Duration"
              :label-width="labelWidth"
              placeholder="Enter the duration"
            />
            <el-form-item label="Universal">
              <el-switch
                v-model="form.universal"
                active-text="Yes"
                inactive-text="No"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </div>
          <p class="bold subtitle">SOURCES</p>
          <div class="ruler"></div>
          <div class="form-items">
            <el-form-item label="Add a Source" :error="errors['contactList']">
              <el-select
                v-model="form.sources"
                placeholder="Add a source..."
                @change="
                  addTag(
                    form.sources,
                    contacts.data,
                    'sourceList',
                    form.sourceList
                  )
                "
                filterable
                remote
                autocomplete="on"
                :remote-method="filterContacts"
              >
                <el-option
                  v-for="contact in contacts.data"
                  :key="contact.id"
                  :label="contact.name"
                  :value="contact.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-items">
            <div
              class="mb-20"
              v-for="(source, index) in form.sourceList"
              :key="index"
            >
              <el-form-item label="Name of source">
                <el-input :disabled="true" v-model="source.name"></el-input>
              </el-form-item>
              <div></div>
              <el-form-item
                label="Other"
                :error="errors[`sourceList.${index}.other`]"
              >
                <el-input
                  :value="source.other"
                  @input="
                    setItemChangeValue($event, index, 'sourceList', 'other')
                  "
                ></el-input>
              </el-form-item>
              <el-form-item
                label="Date"
                :error="errors[`sourceList.${index}.date`]"
              >
                <el-date-picker
                  class="custom-date-picker"
                  :value="source.date"
                  @input="
                    setItemChangeValue($event, index, 'sourceList', 'date')
                  "
                  type="date"
                  placeholder="Select a date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="Mail"
                :error="errors[`sourceList.${index}.mail`]"
              >
                <el-input
                  :value="source.mail"
                  @input="
                    setItemChangeValue($event, index, 'sourceList', 'mail')
                  "
                  type="textarea"
                  :rows="9"
                ></el-input>
              </el-form-item>
              <div class="left space-between">
                <el-button
                  type="danger"
                  class="plain-danger auto-width"
                  plain
                  @click="removeItem(index, 'sourceList', form.sourceList)"
                  >DELETE</el-button
                >
              </div>
            </div>
          </div>
          <p class="bold subtitle">RIGHTS</p>
          <div class="ruler"></div>
          <div class="form-items">
            <el-row :gutter="10">
              <el-col :span="16">
                <el-form-item
                  label="First publishing house"
                  :error="errors['company_id']"
                >
                  <el-select
                    v-model="form.company_id"
                    placeholder="Select a company..."
                    filterable
                    autocomplete="on"
                    :filter-method="filterCompanines"
                  >
                    <el-option
                      v-for="company in companies.data"
                      :key="company.id"
                      :label="company.name"
                      :value="company.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="align-right">
                <el-button
                  type="primary"
                  class="plain-primary auto-width"
                  plain
                  @click="newCompanyDialog = true"
                  >CREATE A COMPANY</el-button
                >
              </el-col>
              <el-col :span="16">
                <el-form-item label="Format" :error="errors['format_id']">
                  <el-select v-model="form.format_id" placeholder="Format...">
                    <el-option
                      v-for="format in formats.data"
                      :key="format.id"
                      :label="format.name"
                      :value="format.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item
                  label="Publication Date"
                  :error="errors['publication_date']"
                >
                  <el-date-picker
                    v-model="form.publication_date"
                    type="month"
                    value-format="yyyy-MM-dd"
                    placeholder="Pick a month"
                    class="full-width"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item
                  label="Number of page"
                  :error="errors['nb_pages']"
                >
                  <el-input placeholder="pages..." v-model="form.nb_pages">
                    <template slot="append">pages</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col class="mb-10" :span="24">
                <div class="vertical-align">
                  <div
                    :style="{ minWidth: labelWidth + 'px' }"
                    class="grey-color bold f-16 text-right pr-10"
                  >
                    Contact(s)
                  </div>
                  <div class="addlist contactlist-container">
                    <el-row class="addlist-title">
                      <el-col :span="12">Selected Contact(s)</el-col>
                      <el-col :span="12">Audiovisual law</el-col>
                    </el-row>

                    <div class="tag-list">
                      <el-row
                        :gutter="20"
                        v-for="(item, index) in form.contactList"
                        :key="index"
                        class="tag-item vertical-align m-5"
                      >
                        <el-col :span="12">
                          <router-link
                            :to="{
                              name: 'contact-save',
                              params: { id: item.id },
                            }"
                            class="info f-15 primary-color underline pointer"
                            target="_blank"
                          >
                            {{
                              item.company
                                ? item.name + ` (${item.company.name}) `
                                : item.name
                            }}
                          </router-link>
                        </el-col>
                        <el-col :span="10">
                          <el-checkbox
                            :value="item.audiovisual_law"
                            @change="
                              setItemChangeValue(
                                $event,
                                index,
                                'contactList',
                                'audiovisual_law'
                              )
                            "
                            >Audiovisual law contact</el-checkbox
                          >
                        </el-col>
                        <el-col :span="4">
                          <el-button
                            type="danger"
                            class="plain-danger all-align"
                            icon="el-icon-delete"
                            size="small"
                            @click="
                              removeItem(index, 'contactList', form.contactList)
                            "
                            plain
                          ></el-button>
                        </el-col>
                      </el-row>
                    </div>
                    <el-form-item :error="errors['contactList']">
                      <el-select
                        v-model="form.contacts"
                        placeholder="Add an existing contact..."
                        class="input-nomargin"
                        @change="
                          addTag(
                            form.contacts,
                            contacts.data,
                            'contactList',
                            form.contactList,
                            'contacts'
                          )
                        "
                        filterable
                        remote
                        autocomplete="on"
                        :remote-method="filterContacts"
                      >
                        <i
                          class="el-icon-plus el-input__icon primary-color"
                          slot="prefix"
                        >
                        </i>
                        <el-option
                          v-for="contact in contacts.data"
                          :key="contact.id"
                          :label="
                            contact.company
                              ? contact.name + ` (${contact.company.name}) `
                              : contact.name
                          "
                          :value="contact.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div
                  class="primary-color italic underline text-right mt-10 pointer"
                  @click="newContactDialog = true"
                >
                  Can't find the contact?
                  <span class="bold">create a new one.</span>
                </div>
              </el-col>
              <el-col :span="16">
                <el-form-item
                  label="State of rights"
                  :error="errors['rights_status_id']"
                >
                  <el-select
                    v-model="form.rights_status_id"
                    placeholder="state of rights..."
                  >
                    <el-option
                      v-for="item in rightsStatuses.data"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item
                  label="Rights update"
                  :error="errors['rights_status_updated_at']"
                >
                  <el-date-picker
                    v-model="form.rights_status_updated_at"
                    type="date"
                    placeholder="Select a date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    class="full-width"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <p class="bold subtitle">CHARACTERS</p>
          <div class="ruler"></div>
          <character-list v-model="form.characters" :errors="errors" />
          <p class="bold subtitle">COMPLEMENTS</p>
          <div class="ruler"></div>
          <div class="form-items">
            <el-form-item
              label="Confidential"
              :error="errors['manuscript_confidential']"
            >
              <el-switch
                v-model="form.manuscript_confidential"
                active-text="Yes"
                inactive-text="No"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
            <translatable-input
              :errors="errors"
              :source.sync="form.additional_manuscripts"
              sourceKey="additional_manuscripts"
              title="Additional Information"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter additional information"
            />
          </div>
          <p class="bold subtitle">ADAPTATION FILE</p>
          <div class="ruler"></div>
          <div class="form-items">
            <translatable-input
              title="Key moments"
              :errors="errors"
              :source.sync="form.key_moments"
              sourceKey="key_moments"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the key moments"
            />
            <translatable-input
              title="Arena"
              :errors="errors"
              :source.sync="form.arenas"
              sourceKey="arenas"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the arena"
            />
            <translatable-input
              title="Promise"
              :errors="errors"
              :source.sync="form.promises"
              sourceKey="promises"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the promise"
            />
            <translatable-input
              title="Message"
              :errors="errors"
              :source.sync="form.messages"
              sourceKey="messages"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the message"
            />
            <translatable-input
              title="Tone"
              :errors="errors"
              :source.sync="form.tones"
              sourceKey="tones"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the tones"
            />
            <translatable-input
              title="Point of view"
              :errors="errors"
              :source.sync="form.point_of_views"
              sourceKey="point_of_views"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the point of view"
            />
            <translatable-input
              title="Narrative structure"
              :errors="errors"
              :source.sync="form.narrative_structures"
              sourceKey="narrative_structures"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the narrative structure"
            />
            <translatable-input
              title="Strong points"
              :errors="errors"
              :source.sync="form.strong_points"
              sourceKey="strong_points"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the strong points"
            />
            <translatable-input
              title="Weak points"
              :errors="errors"
              :source.sync="form.weak_points"
              sourceKey="weak_points"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the weak points"
            />
            <translatable-input
              title="Book references"
              :errors="errors"
              :source.sync="form.book_references"
              sourceKey="book_references"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the book references"
            />
            <translatable-input
              title="Movie references"
              :errors="errors"
              :source.sync="form.movie_references"
              sourceKey="movie_references"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the movie references"
            />
            <el-form-item label="Adaptation">
              <el-tabs
                type="border-card"
                class="translatable-tabs"
                v-model="adaptationCriteriaActiveTab"
              >
                <el-tab-pane
                  v-for="(criteria, index) in adaptationCriterias"
                  :label="criteria.tabName"
                  :key="index"
                  :name="criteria.name"
                >
                  <el-form-item
                    :label="criteria.fieldName"
                    :error="errors[criteria.name]"
                  >
                    <el-select
                      v-model="form[criteria.name]"
                      value-key="id"
                      :placeholder="criteria.placeholder"
                      :multiple="criteria.multiple"
                      :filterable="criteria.filterable"
                      autocomplete="on"
                      clearable
                    >
                      <el-option
                        v-for="item in criteria.dataName"
                        :key="item.id"
                        :label="item[criteria.optionFieldName]"
                        :value="criteria.multiple ? item : item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-tab-pane>
              </el-tabs>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="8" class="right-column">
          <p class="bold subtitle">
            AI GENERATION
          </p>
          <div class="ruler"></div>
          <div v-if="form.ai_generation_error" class="pb-20">
            <el-alert
              title="AI GENERATION ERROR"
              type="error"
              :closable="false"
            >
              {{ form.ai_generation_error }}
            </el-alert>
          </div>
          <div class="pb-20">
            <el-button
              type="success"
              plain
              @click="generateReadingSheets = true"
              :disabled="form.is_ai_generation_running"
              :loading="form.is_ai_generation_running"
            >
              <i class="el-icon-magic-stick"></i>
              &nbsp; Generate reading sheet with AI
            </el-button>
          </div>
          <div v-if="form.has_ai_results" class="pb-20">
            <el-button type="info" plain @click="redirectToAiComparation">
              <i class="el-icon-success"></i>
              &nbsp; See AI result
            </el-button>
          </div>

          <p class="bold subtitle">
            FILES AND RATINGS
          </p>
          <div class="ruler"></div>
          <el-form-item label="Cover">
            <el-upload
              class="cover-upload"
              :action="actionURL"
              list-type="picture-card"
              :show-file-list="true"
              :auto-upload="false"
              :file-list="form.coverList"
              :limit="1"
              :on-preview="previewFile"
              :on-change="coverUploadChange"
              :on-remove="deleteCovers"
              :multiple="false"
              accept=".pdf, .PDF, .jpg, .JPG, .jpeg, .JPEG, .png,.PNG"
              :class="{ hide: hideUpload }"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item
            class="pt-20 pb-20"
            label="Video / Audio"
            :error="errors['video_file']"
          >
            <el-upload
              class="upload-demo"
              :class="{ 'without-drop-area': hideVideoUpload }"
              drag
              :action="actionURL"
              :limit="1"
              ref="video"
              :on-remove="deleteVideos"
              :on-change="videoUploadChange"
              :file-list="form.videoList"
              :on-preview="previewFile"
              :auto-upload="false"
              :multiple="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload--text">
                Drop file here or <em>click to upload</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                Video file with a size less than 500MB
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item
            class="pt-20 pb-20"
            label="File(s)"
            :error="errors['documentList']"
          >
            <el-upload
              :action="actionURL"
              :limit="10"
              ref="file"
              :on-remove="deleteFiles"
              :on-change="handleChange"
              :file-list="form.documentList"
              :on-preview="previewFile"
              list-type="picture"
              :auto-upload="false"
              accept=".pdf, .PDF, .jpg, .JPG, .jpeg, .JPEG, .png,
              .PNG, .xlsx .ppt, .pptx, pdf, .doc, .docx, .xls, .avi, .mp4, .ogg"
              multiple
            >
              <el-button type="primary" class="plain-primary" plain
                >Add a file...</el-button
              >
            </el-upload>
          </el-form-item>
          <el-form-item
            class="pt-20 pb-20"
            label="Manuscript"
            :error="errors['manuscript']"
          >
            <manuscript-upload
              @change="
                file => {
                  newDocumentManuscript = file
                  form.manuscript = file
                }
              "
              @remove="
                () => {
                  newDocumentManuscript = null
                  form.manuscript = null
                }
              "
              :file-list="form.manuscript ? [form.manuscript] : []"
            >
            </manuscript-upload>
          </el-form-item>
          <el-row class="rating pb-20" :gutter="30">
            <template v-for="(name, key) in ratingTypes">
              <el-col
                :span="12"
                class="name text-right"
                :key="'rating-type-title-' + key"
                >{{ name }}</el-col
              >
              <el-col :span="12" :key="'rating-type-rating-' + key">
                <el-rate v-model="form['rating_' + key]" :max="4"></el-rate>
              </el-col>
            </template>
          </el-row>
          <div class="vertical-align">
            <div class="addlist reporterlist-container">
              <el-row class="addlist-title">
                <el-col :span="24">Reporter(s) associated</el-col>
              </el-row>
              <div class="tag-list">
                <el-row
                  :gutter="10"
                  v-for="(item, index) in form.reporterList"
                  :key="index"
                  class="tag-item vertical-align align-right"
                >
                  <el-col :span="20">
                    <router-link
                      :to="{ name: 'reporter-save', params: { id: item.id } }"
                      class="info f-15 primary-color underline pointer"
                      target="_blank"
                    >
                      {{ item.title }}
                    </router-link>
                  </el-col>
                  <el-col :span="3">
                    <el-button
                      type="danger"
                      class="plain-danger all-align"
                      icon="el-icon-delete"
                      size="small"
                      @click="
                        removeItem(index, 'reporterList', form.reporterList)
                      "
                      plain
                    ></el-button>
                  </el-col>
                </el-row>
              </div>
              <el-form-item class="input-nomargin">
                <el-select
                  v-model="form.reporter"
                  @change="
                    addTag(
                      form.reporter,
                      reporters.data,
                      'reporterList',
                      form.reporterList,
                      'reporter'
                    )
                  "
                  filterable
                  remote
                  :remote-method="filterReporters"
                  placeholder="Add an existing reporter..."
                >
                  <i
                    class="el-icon-plus el-input__icon primary-color"
                    slot="prefix"
                  >
                  </i>
                  <el-option
                    v-for="reporter in reporters.data"
                    :key="reporter.id"
                    :label="reporter.title"
                    :value="reporter.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row class="request-table-wrapper">
        <p class="bold subtitle">REQUESTS</p>
        <div v-if="isUpdate">
          <div class="ruler"></div>
          <p v-if="form.requestList.length === 0" class="italic">
            No requests for this sheet
          </p>
          <div class="save" v-if="form.requestList.length > 0">
            <requests-table
              class="mt-20"
              :book-id="parseInt(this.$route.params.id)"
            ></requests-table>
          </div>
        </div>
      </el-row>

      <div class="align-right fixed-save">
        <el-button
          type="primary"
          plain
          class="primary"
          native-type="submit"
          :loading="saveReadingSheetLoading"
          @click="submit({ withNotification: false })"
          >SAVE THE SHEET</el-button
        >
        <el-button
          type="danger"
          plain
          class="danger"
          native-type="submit"
          :loading="saveReadingSheetLoading"
          @click="submit({ withNotification: true })"
          >SAVE WITH NOTIFICATION</el-button
        >
      </div>
    </el-form>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { apiError } from '@/mixins'
import TranslatableInput from '@/components/Form/TranslatableInput'
import { initMultilingual } from '@/services/helpers'
import SavePageHeader from '@/components/ui/SavePageHeader'
import NewContact from '@/views/Contact/Save'
import NewAuthor from '@/views/Author/Save'
import NewCompany from '@/views/Company/Save'
import RequestsTable from '@/views/Request/RequestsTable.vue'
import CriteriaTree from './CriteriaTree.vue'
import { labelWidth } from '@/mixins'
import { DOCUMENT_TYPES } from '@/utils/constant'
import ManuscriptUpload from '@/views/ReadingSheets/ManuscriptUpload.vue'
import CharacterList from '@/views/ReadingSheets/CharacterList.vue'
import GenerateReadingSheetsModal from '@/components/modals/GenerateReadingSheetsModal.vue'
import router from '@/router'
import AuthorsSelection from '@/views/ReadingSheets/AuthorsSelection.vue'

const MAX_DOCUMENT_FILE_SIZE = 1024 * 1024 * 60
const BOOK_STATUS_DRAFT_ID = 1
const RIGHT_STATUS_TO_BE_DEFINED_ID = 4

export default {
  name: 'reading-sheet-save',
  components: {
    AuthorsSelection,
    GenerateReadingSheetsModal,
    CharacterList,
    TranslatableInput,
    SavePageHeader,
    NewContact,
    NewAuthor,
    NewCompany,
    RequestsTable,
    CriteriaTree,
    ManuscriptUpload,
  },
  mixins: [apiError('saveReadingSheetError'), labelWidth],
  data() {
    return {
      actionURL: '',
      hideUpload: false,
      hideVideoUpload: false,
      criteriaActiveTab: 'audiences',
      adaptationCriteriaActiveTab: 'adaptation_formats',
      criteriaError: 'audiences',
      adaptationCriteriaError: 'adaptation_formats',
      newDocumentFiles: [],
      newDocumentManuscript: [],
      newDocumentCovers: [],
      newDocumentVideo: [],
      newContactDialog: false,
      newAuthorDialog: false,
      newCompanyDialog: false,
      thematicList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      form: {
        titles: initMultilingual(),
        subtitles: initMultilingual(),
        pitches: initMultilingual(),
        public_comments: initMultilingual(),
        summaries: initMultilingual(),
        report: initMultilingual(),
        scouts: initMultilingual(),
        additional_periods: initMultilingual(),
        durations: initMultilingual(),
        additional_places: initMultilingual(),
        newsletter_comments: initMultilingual(),
        additional_manuscripts: initMultilingual(),
        detailed_summaries: initMultilingual(),
        key_moments: initMultilingual(),
        arenas: initMultilingual(),
        promises: initMultilingual(),
        messages: initMultilingual(),
        tones: initMultilingual(),
        point_of_views: initMultilingual(),
        narrative_structures: initMultilingual(),
        strong_points: initMultilingual(),
        weak_points: initMultilingual(),
        book_references: initMultilingual(),
        movie_references: initMultilingual(),
        documentList: [],
        coverList: [],
        audiences: [],
        genres: [],
        categories: [],
        thematics: [],
        adaptation_formats: [],
        adaptation_kinds: [],
        literary_prizes: [],
        characters: [],
        primary_genre: null,
        sources: null,
        serie: 0,
        seriesList: [
          {
            name: '',
            number: '',
          },
        ],
        sourceList: [],
        contactList: [],
        reporterList: [],
        authorList: [],
        requestList: [],
        status_id: BOOK_STATUS_DRAFT_ID,
        rights_status_id: RIGHT_STATUS_TO_BE_DEFINED_ID,
        videoList: [],
      },
      ratingTypes: {
        quality: 'Literary quality',
        concept: 'Concept',
        plot: 'Plot',
        characters: 'Characters',
        coherence: 'Coherence',
        emotions: 'Emotions',
        dialogues: 'Dialogues',
        commercial: 'Commercial',
      },
      generateReadingSheets: false,
      has_ai_results: false,
      is_ai_generation_running: true,
      ai_generation_error: null,
    }
  },
  computed: {
    ...mapState('common', ['loadingProgressPercentage']),
    ...mapState('readingSheet', [
      'readingSheet',
      'statuses',
      'rightsStatuses',
      'getReadingSheetLoading',
      'getBookStatusesLoading',
      'saveReadingSheetLoading',
      'saveReadingSheetError',
    ]),
    ...mapState('period', ['periods', 'getPeriodsLoading']),
    ...mapState('thematic', ['thematics', 'getThematicsLoading']),
    ...mapState('author', ['authors', 'getAuthorsLoading', 'types']),
    ...mapState('category', ['categories', 'getCategoriesLoading']),
    ...mapState('time', ['times', 'getTimesLoading']),
    ...mapState('audience', ['audiences', 'getAudiencesLoading']),
    ...mapState('genre', ['genres', 'getGenresLoading']),
    ...mapState('country', ['countries', 'getCountriesLoading']),
    ...mapState('locale', ['languages', 'getLanguagesLoading']),
    ...mapState('contact', ['contacts', 'getContactsLoading']),
    ...mapState('format', ['formats', 'getFormatsLoading']),
    ...mapState('company', ['companies', 'getCompaniesLoading']),
    ...mapState('reporter', ['reporters', 'getReportersLoading']),
    ...mapState('adaptationFormat', [
      'adaptationFormats',
      'getAdaptationFormatsLoading',
    ]),
    ...mapState('adaptationKind', [
      'adaptationKinds',
      'getAdaptationKindsLoading',
    ]),
    ...mapState('adaptationBudget', [
      'adaptationBudgets',
      'getAdaptationBudgetsLoading',
    ]),
    ...mapState('literaryPrize', [
      'literaryPrizes',
      'getLiteraryPrizesLoading',
    ]),
    criterias() {
      return [
        {
          id: 1,
          tabName: 'Audience(s)',
          name: 'audiences',
          placeholder: 'Mature audience, Children',
          fieldName: 'Audiences',
          dataName: this.audiences.data,
          multiple: true,
          type: 'select',
          optionFieldName: 'name',
        },
        {
          id: 2,
          tabName: 'Genre(s)',
          name: 'genres',
          placeholder: 'Genres...',
          fieldName: 'Genres',
          dataName: this.getTree(this.genres.data),
          type: 'tree',
          index: 0,
        },
        {
          id: 3,
          tabName: 'Categories',
          name: 'categories',
          placeholder: 'Categories...',
          dataName: this.categories.data,
          fieldName: 'Categories',
          multiple: true,
          filterable: true,
          type: 'select',
          optionFieldName: 'name',
        },
        {
          id: 4,
          tabName: 'Thematics(s)',
          name: 'thematics',
          placeholder: 'Thematics...',
          fieldName: 'Thematics',
          dataName: this.getTree(this.thematics.data),
          type: 'tree',
          index: 1,
        },
        {
          id: 5,
          tabName: 'Literary Prizes',
          name: 'literary_prizes',
          placeholder: 'Literary Prizes...',
          fieldName: 'Literary Prizes',
          dataName: this.literaryPrizes.data,
          multiple: true,
          type: 'select',
          optionFieldName: 'label',
        },
      ]
    },
    adaptationCriterias() {
      return [
        {
          id: 5,
          tabName: 'Format(s)',
          name: 'adaptation_formats',
          placeholder: 'Adaptation Formats...',
          fieldName: 'Adaptation Formats',
          dataName: this.adaptationFormats.data,
          multiple: true,
          optionFieldName: 'label',
        },
        {
          id: 6,
          tabName: 'Kind(s)',
          name: 'adaptation_kinds',
          placeholder: 'Adaptation Kinds...',
          fieldName: 'Adaptation Kinds',
          dataName: this.adaptationKinds.data,
          multiple: true,
          optionFieldName: 'label',
        },
        {
          id: 7,
          tabName: 'Budget',
          name: 'adaptation_budget_id',
          placeholder: 'Adaptation Budget...',
          fieldName: 'Adaptation Budget',
          dataName: this.adaptationBudgets.data,
          multiple: false,
          optionFieldName: 'label',
        },
      ]
    },
    isUpdate() {
      return this.$route.params.id !== undefined
    },
    isLoading() {
      let loading = false
      if (
        this.getReadingSheetLoading ||
        this.getThematicsLoading ||
        this.getGenresLoading ||
        this.getAdaptationFormatsLoading ||
        this.getAdaptationKindsLoading ||
        this.getAdaptationBudgetsLoading ||
        this.getLiteraryPrizesLoading
      ) {
        loading = true
      }
      return loading
    },
    genreList() {
      let list = []
      if (this.genres && this.genres.data) {
        list = this.genres.data.filter(item => {
          return this.form.genres && this.form.genres.indexOf(item.id) !== -1
        })
      }
      return list
    },
  },
  watch: {
    errors(errors) {
      if (errors) {
        for (let i = 0, len = this.criterias.length; i < len; i++) {
          if (errors[this.criterias[i].name]) {
            this.criteriaActiveTab = this.criterias[i].name
            break
          }
        }

        for (let i = 0, len = this.adaptationCriterias.length; i < len; i++) {
          if (errors[this.adaptationCriterias[i].name]) {
            this.adaptationCriteriaActiveTab = this.adaptationCriterias[i].name
            break
          }
        }
      }
    },
  },
  created() {
    const navManuItem = this.$route.meta['menuIndex'] || this.$route.path
    this.setActiveNavTab(navManuItem)
  },
  async mounted() {
    this.setSelectedPane('en')
    if (this.isUpdate) {
      await this.getReadingSheet({
        routeParameters: {
          id: this.$route.params.id,
        },
      })

      this.form = {
        ...this.readingSheet.data,
        titles: initMultilingual(this.readingSheet.data.titles),
        subtitles: initMultilingual(this.readingSheet.data.subtitles),
        pitches: initMultilingual(this.readingSheet.data.pitches),
        public_comments: initMultilingual(
          this.readingSheet.data.public_comments
        ),
        summaries: initMultilingual(this.readingSheet.data.summaries),
        report: initMultilingual(this.readingSheet.data.report),
        scouts: initMultilingual(this.readingSheet.data.scouts),
        additional_periods: initMultilingual(
          this.readingSheet.data.additional_periods
        ),
        durations: initMultilingual(this.readingSheet.data.durations),
        additional_places: initMultilingual(
          this.readingSheet.data.additional_places
        ),
        newsletter_comments: initMultilingual(
          this.readingSheet.data.newsletter_comments
        ),
        additional_manuscripts: initMultilingual(
          this.readingSheet.data.additional_manuscripts
        ),
        documentList: this.copyFileDocuments(
          this.readingSheet.data.documentList,
          DOCUMENT_TYPES.FILES
        ),
        coverList: this.copyFileDocuments(
          this.readingSheet.data.documentList,
          DOCUMENT_TYPES.COVERS
        ),
        videoList: this.copyFileDocuments(
          this.readingSheet.data.documentList,
          DOCUMENT_TYPES.VIDEOS
        ),
        manuscript: this.readingSheet.data.manuscript,
        detailed_summaries: initMultilingual(
          this.readingSheet.data.detailed_summaries
        ),
        key_moments: initMultilingual(this.readingSheet.data.key_moments),
        arenas: initMultilingual(this.readingSheet.data.arenas),
        promises: initMultilingual(this.readingSheet.data.promises),
        messages: initMultilingual(this.readingSheet.data.messages),
        tones: initMultilingual(this.readingSheet.data.tones),
        point_of_views: initMultilingual(this.readingSheet.data.point_of_views),
        narrative_structures: initMultilingual(
          this.readingSheet.data.narrative_structures
        ),
        strong_points: initMultilingual(this.readingSheet.data.strong_points),
        weak_points: initMultilingual(this.readingSheet.data.weak_points),
        book_references: initMultilingual(
          this.readingSheet.data.book_references
        ),
        movie_references: initMultilingual(
          this.readingSheet.data.movie_references
        ),
        characters: this.readingSheet.data.characters.map(character => ({
          ...character,
          characterisations: initMultilingual(character.characterisations),
          issues: initMultilingual(character.issues),
          evolutions: initMultilingual(character.evolutions),
        })),
      }
      if (this.form.videoList.length > 0) {
        this.hideVideoUpload = true
      }
      if (this.form.coverList.length > 0) {
        this.hideUpload = true
      }
      this.getCompanies({
        name: this.form.company ? this.form.company.name : '',
        perPage: 100,
      })
      this.getCountries({
        name: this.form.country ? this.form.country.name : '',
      })
    } else {
      this.getCompanies({ perPage: 10 })
    }
    this.getPeriods({ perPage: 500 })
    this.getAuthors({ perPage: 10 })
    this.getCategories({ perPage: 100 })
    this.getTimes({ perPage: 10 })
    this.getAudiences({ perPage: 500 })
    this.getGenres({ perPage: 500 })
    this.getCountries({ perPage: 500 })
    this.getContacts({ perPage: 10 })
    this.getFormats({ perPage: 10 })
    this.getThematics({ perPage: 500 })
    this.getAuthors({ perPage: 10 })
    this.getReporters({ perPage: 10 })
    this.getAuthorTypes()
    this.getBookStatuses()
    this.getRightsStatuses()
    this.getLanguages()
    this.getAdaptationFormats({ perPage: 25 })
    this.getAdaptationKinds({ perPage: 25 })
    this.getAdaptationBudgets({ perPage: 25 })
    this.getLiteraryPrizes({ perPage: 500 })
  },
  methods: {
    ...mapActions('readingSheet', [
      'saveReadingSheet',
      'getReadingSheet',
      'getBookStatuses',
      'getRightsStatuses',
      'deleteFile',
    ]),
    ...mapActions('period', ['getPeriods']),
    ...mapActions('thematic', ['getThematics']),
    ...mapActions('author', ['getAuthors', 'getAuthorTypes']),
    ...mapActions('category', ['getCategories']),
    ...mapActions('time', ['getTimes']),
    ...mapActions('audience', ['getAudiences']),
    ...mapActions('genre', ['getGenres']),
    ...mapActions('country', ['getCountries']),
    ...mapActions('locale', ['getLanguages']),
    ...mapActions('contact', ['getContacts']),
    ...mapActions('format', ['getFormats']),
    ...mapActions('company', ['getCompanies']),
    ...mapActions('adaptationFormat', ['getAdaptationFormats']),
    ...mapActions('adaptationKind', ['getAdaptationKinds']),
    ...mapActions('adaptationBudget', ['getAdaptationBudgets']),
    ...mapActions('literaryPrize', ['getLiteraryPrizes']),
    ...mapMutations('common', ['setActiveNavTab']),
    ...mapMutations('readingSheet', [
      'setStateData',
      'removeStateData',
      'addStateItem',
      'setStatePropertyValue',
      'setStateAuthorData',
    ]),
    ...mapMutations('author', ['setStateAuthorData']),
    ...mapMutations('contact', ['setStateContactData']),
    ...mapActions('reporter', ['getReporters']),
    ...mapActions('locale', ['setSelectedPane']),
    async submit({ withNotification }) {
      let formData = new FormData()

      formData.append('withNotification', withNotification)

      if (this.form.original_title) {
        formData.append('original_title', this.form.original_title)
      }
      if (this.form.titles) {
        for (const [key, value] of Object.entries(this.form.titles)) {
          formData.append(`titles[${key}]`, value)
        }
      }
      if (this.form.subtitles) {
        for (const [key, value] of Object.entries(this.form.subtitles)) {
          formData.append(`subtitles[${key}]`, value)
        }
      }
      if (this.form.summaries) {
        for (const [key, value] of Object.entries(this.form.summaries)) {
          formData.append(`summaries[${key}]`, value)
        }
      }
      if (this.form.durations) {
        for (const [key, value] of Object.entries(this.form.durations)) {
          formData.append(`durations[${key}]`, value)
        }
      }
      if (this.form.pitches) {
        for (const [key, value] of Object.entries(this.form.pitches)) {
          formData.append(`pitches[${key}]`, value)
        }
      }
      if (this.form.additional_places) {
        for (const [key, value] of Object.entries(
          this.form.additional_places
        )) {
          formData.append(`additional_places[${key}]`, value)
        }
      }
      if (this.form.additional_periods) {
        for (const [key, value] of Object.entries(
          this.form.additional_periods
        )) {
          formData.append(`additional_periods[${key}]`, value)
        }
      }
      if (this.form.public_comments) {
        for (const [key, value] of Object.entries(this.form.public_comments)) {
          formData.append(`public_comments[${key}]`, value)
        }
      }
      if (this.form.newsletter_comments) {
        for (const [key, value] of Object.entries(
          this.form.newsletter_comments
        )) {
          formData.append(`newsletter_comments[${key}]`, value)
        }
      }
      if (this.form.additional_manuscripts) {
        for (const [key, value] of Object.entries(
          this.form.additional_manuscripts
        )) {
          formData.append(`additional_manuscripts[${key}]`, value)
        }
      }
      if (this.form.scouts) {
        for (const [key, value] of Object.entries(this.form.scouts)) {
          formData.append(`scouts[${key}]`, value)
        }
      }
      if (this.form.detailed_summaries) {
        for (const [key, value] of Object.entries(
          this.form.detailed_summaries
        )) {
          formData.append(`detailed_summaries[${key}]`, value)
        }
      }
      if (this.form.key_moments) {
        for (const [key, value] of Object.entries(this.form.key_moments)) {
          formData.append(`key_moments[${key}]`, value)
        }
      }
      if (this.form.arenas) {
        for (const [key, value] of Object.entries(this.form.arenas)) {
          formData.append(`arenas[${key}]`, value)
        }
      }
      if (this.form.promises) {
        for (const [key, value] of Object.entries(this.form.promises)) {
          formData.append(`promises[${key}]`, value)
        }
      }
      if (this.form.messages) {
        for (const [key, value] of Object.entries(this.form.messages)) {
          formData.append(`messages[${key}]`, value)
        }
      }
      if (this.form.tones) {
        for (const [key, value] of Object.entries(this.form.tones)) {
          formData.append(`tones[${key}]`, value)
        }
      }
      if (this.form.point_of_views) {
        for (const [key, value] of Object.entries(this.form.point_of_views)) {
          formData.append(`point_of_views[${key}]`, value)
        }
      }
      if (this.form.narrative_structures) {
        for (const [key, value] of Object.entries(
          this.form.narrative_structures
        )) {
          formData.append(`narrative_structures[${key}]`, value)
        }
      }
      if (this.form.strong_points) {
        for (const [key, value] of Object.entries(this.form.strong_points)) {
          formData.append(`strong_points[${key}]`, value)
        }
      }
      if (this.form.weak_points) {
        for (const [key, value] of Object.entries(this.form.weak_points)) {
          formData.append(`weak_points[${key}]`, value)
        }
      }
      if (this.form.book_references) {
        for (const [key, value] of Object.entries(this.form.book_references)) {
          formData.append(`book_references[${key}]`, value)
        }
      }
      if (this.form.movie_references) {
        for (const [key, value] of Object.entries(this.form.movie_references)) {
          formData.append(`movie_references[${key}]`, value)
        }
      }

      formData.append('private_comment', this.form.private_comment)
      formData.append('original_country_id', this.form.original_country_id)
      if (this.form.format_id) {
        formData.append('format_id', this.form.format_id)
      }
      if (this.form.company_id) {
        formData.append('company_id', this.form.company_id)
      }
      if (this.form.adaptation_budget_id) {
        formData.append('adaptation_budget_id', this.form.adaptation_budget_id)
      }
      formData.append('status_id', this.form.status_id)
      formData.append('language_id', this.form.language_id)
      formData.append('time_id', this.form.time_id)

      if (this.form.authorList) {
        this.form.authorList.forEach((author, index) => {
          formData.append(`authorList[${index}][id]`, author.id)
          formData.append(`authorList[${index}][type]`, author.type)
        })
      }
      if (this.form.genres) {
        this.form.genres.forEach(genre => {
          formData.append(`genres[]`, genre)
        })
      }
      if (this.form.periods) {
        this.form.periods.forEach(period => {
          formData.append(`periods[]`, period.id)
        })
      }

      if (this.form.places) {
        this.form.places.forEach(place => {
          formData.append(`places[]`, place.id)
        })
      }

      if (this.form.thematics) {
        this.form.thematics.forEach(thematic => {
          formData.append(`thematics[]`, thematic)
        })
      }
      if (this.form.audiences) {
        this.form.audiences.forEach(audience => {
          formData.append(`audiences[]`, audience.id)
        })
      }
      if (this.form.categories) {
        this.form.categories.forEach(category => {
          formData.append(`categories[]`, category.id)
        })
      }
      if (this.form.adaptation_formats) {
        this.form.adaptation_formats.forEach(adaptationFormat => {
          formData.append(`adaptation_formats[]`, adaptationFormat.id)
        })
      }
      if (this.form.adaptation_kinds) {
        this.form.adaptation_kinds.forEach(adaptationKind => {
          formData.append(`adaptation_kinds[]`, adaptationKind.id)
        })
      }
      if (this.form.literary_prizes) {
        this.form.literary_prizes.forEach(literaryPrize => {
          formData.append(`literary_prizes[]`, literaryPrize.id)
        })
      }
      if (this.form.sourceList) {
        this.form.sourceList.forEach((source, index) => {
          formData.append(`sourceList[${index}][contact_id]`, source.id)

          if (source.other) {
            formData.append(`sourceList[${index}][other]`, source.other)
          }

          if (source.date) {
            formData.append(`sourceList[${index}][date]`, source.date)
          }

          if (source.mail) {
            formData.append(`sourceList[${index}][mail]`, source.mail)
          }
        })
      }
      if (this.form.contactList) {
        this.form.contactList.forEach((contact, index) => {
          formData.append(`contactList[${index}][id]`, contact.id)
          formData.append(
            `contactList[${index}][audiovisual_law]`,
            contact.audiovisual_law
          )
        })
      }
      formData.append('serie', this.form.serie)
      formData.append('universal', this.form.universal)
      if (this.form.nb_pages) {
        formData.append('nb_pages', this.form.nb_pages)
      }
      if (this.form.rights_status_id) {
        formData.append('rights_status_id', this.form.rights_status_id)
      }
      formData.append(
        'rights_status_updated_at',
        this.form.rights_status_updated_at
      )
      formData.append(
        'manuscript_confidential',
        this.form.manuscript_confidential
      )
      formData.append(
        'publication_date',
        this.form.publication_date ? this.form.publication_date : ''
      )
      if (this.form.seriesList && this.form.serie) {
        this.form.seriesList.forEach((series, index) => {
          formData.append(
            `seriesList[${index}][id]`,
            series.id ? series.id : ''
          )
          formData.append(`seriesList[${index}][name]`, series.name)
          formData.append(`seriesList[${index}][number]`, series.number)
        })
      }
      if (this.newDocumentFiles.length > 0) {
        this.newDocumentFiles.forEach(file => {
          formData.append('documentList[]', file)
        })
      }
      if (this.newDocumentCovers.length > 0) {
        this.newDocumentCovers.forEach(file => {
          formData.append('cover_file[]', file)
        })
      }
      if (this.newDocumentManuscript !== null) {
        formData.append('manuscript', this.newDocumentManuscript)
      }
      if (this.newDocumentVideo.length > 0) {
        this.newDocumentVideo.forEach(video => {
          formData.append('video_file[]', video)
        })
      }
      if (this.form.reporterList.length > 0) {
        this.form.reporterList.forEach((reporter, index) => {
          formData.append(`reporterList[${index}][id]`, reporter.id)
        })
      }

      // Rating types
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.ratingTypes)) {
        if (this.form['rating_' + key]) {
          formData.append('ratings[' + key + ']', this.form['rating_' + key])
        }
      }

      formData.append('primary_genre', this.form.primary_genre)

      // CHARACTERS
      for (const [key, value] of Object.entries(this.form.characters)) {
        for (const [
          characterAttribute,
          characterAttributeValue,
        ] of Object.entries(value)) {
          if (
            characterAttribute === 'evolutions' ||
            characterAttribute === 'issues' ||
            characterAttribute === 'characterisations' ||
            characterAttribute === 'ages'
          ) {
            for (const [lang, langValue] of Object.entries(
              characterAttributeValue
            )) {
              formData.append(
                'characters[' +
                  key +
                  '][' +
                  characterAttribute +
                  '][' +
                  lang +
                  ']',
                langValue
              )
            }
          } else {
            if (
              characterAttribute === 'id' &&
              characterAttributeValue === null
            ) {
              continue
            }
            formData.append(
              'characters[' + key + '][' + characterAttribute + ']',
              characterAttributeValue
            )
          }
        }
      }

      await this.saveReadingSheet({
        routeParameters: {
          id: this.$route.params.id,
        },
        formData,
      })

      if (this.isUpdate && this.saveReadingSheetError.data === null) {
        this.refreshFormData()
      }
      // Go to the books list after adding new entity in order not to save the same book many times on each 'save' button click
      if (!this.isUpdate && this.saveReadingSheetError.data === null) {
        this.$router.push({ name: 'readingSheets' })
      }
    },
    async removeDocument(file, fileList, itemName) {
      let fileId = file.id
      let index = this.form[itemName].indexOf(file)
      this.$confirm(
        `Are you sure, you want to delete this file ${file.name} in the database from this reading sheet?`,
        'Confirmation',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          try {
            this.deleteFile({
              routeParameters: {
                book: this.$route.params.id,
                file: fileId,
              },
            })
            this.$notify({
              title: 'Success',
              message: 'Deletion succeed',
              type: 'success',
            })
          } catch (e) {
            this.$notify({
              title: 'Failed',
              message: 'Deletion Failed',
              type: 'danger',
            })
          }
          this.removeStateData({
            property: 'readingSheet',
            item: 'documentList',
            value: index,
          })
        })
        .catch(() => {
          fileList.push(file)
          this.hideUpload =
            fileList.length > 0 || this.form.coverList > 0 || !this.hideUpload
          this.hideVideoUpload =
            fileList.length > 0 ||
            this.form.videoList > 0 ||
            !this.hideVideoUpload
        })
    },
    deleteFiles(file, fileList) {
      let fileId = file.id
      if (fileId) {
        this.removeDocument(file, fileList, 'documentList')
      } else {
        let index = this.newDocumentFiles.indexOf(file)
        if (index) {
          this.newDocumentFiles.splice(index, 1)
        }
      }
    },
    deleteCovers(file, fileList) {
      let fileId = file.id
      if (fileId) {
        this.removeDocument(file, fileList, 'coverList')
      } else {
        let index = this.newDocumentCovers.indexOf(file)
        if (index) {
          this.newDocumentCovers.splice(index, 1)
        }
      }
      this.hideUpload = this.newDocumentCovers.length > 0
    },
    handleChange(file, fileList) {
      if (file.status === 'ready') {
        if (
          !/\.(ppt|pptx|pdf|doc|docx|xls|xlsx|avi|mp4|ogg|jpg|jpeg|png)$/i.test(
            file.name
          )
        ) {
          this.$notify.error({
            title: 'Error',
            message:
              'File extension must be one of these: ppt, pptx, pdf, doc, docx, xls, xlsx, avi, mp4, ogg, jpg, jpeg, png',
          })
          fileList.pop()
          return
        }
        if (file.size > MAX_DOCUMENT_FILE_SIZE) {
          this.$notify.error({
            title: 'Error',
            message: 'The file size must not exceeds 60Mb',
          })
          fileList.pop()
          return
        }
        let existedFiles = this.newDocumentFiles.filter(document => {
          return !document.filename === file.name
        })

        if (existedFiles.length > 0) {
          fileList.pop()
          return
        }
        this.newDocumentFiles.push(file.raw)
      }
    },
    coverUploadChange(file, fileList) {
      if (file.status === 'ready') {
        if (file.size / 1024 / 1025 < 5.01) {
          this.newDocumentCovers.push(file.raw)
          if (this.newDocumentCovers.length > 0) {
            this.hideUpload = true
          }
        } else {
          fileList.pop()
          this.$notify.error({
            type: 'Error',
            message: 'Image should not be greater than 5Mb',
          })
        }
      }
    },
    videoUploadChange(file) {
      if (!/\.(mp4|mov|wmv|flv|avi|webm|mkv|mp3|wav)$/i.test(file.name)) {
        this.$refs.video.clearFiles()
        this.$notify.error({
          type: 'Error',
          message:
            'Available file extensions are: .mp4, .mov, .wmv, .flv, .avi, .webm, .mkv, .mp3, .wav',
        })
      } else if (file.size / 1024 / 1025 >= 500.01) {
        this.$refs.video.clearFiles()
        this.$notify.error({
          type: 'Error',
          message: 'Image should not be greater than 500Mb',
        })
      } else {
        this.newDocumentVideo.push(file.raw)
        this.hideVideoUpload = !this.hideVideoUpload
      }
    },
    deleteVideos(file, fileList) {
      let fileId = file.id
      if (fileId) {
        this.removeDocument(file, fileList, 'videoList')
      } else {
        let index = this.newDocumentVideo.indexOf(file)
        if (index) {
          this.newDocumentVideo.splice(index, 1)
        }
      }
      this.hideVideoUpload = this.newDocumentVideo.length > 0
    },
    addItem(list, itemName = null) {
      if (this.isUpdate) {
        this.addStateItem({
          property: 'readingSheet',
          item: itemName,
          value: list,
        })
      } else {
        list.push({})
      }
    },
    removeItem(index, itemName = null, list) {
      if (this.isUpdate) {
        this.removeStateData({
          property: 'readingSheet',
          item: itemName,
          value: index,
        })
      } else {
        list.splice(index, 1)
      }
    },
    addTag(item, selectList, itemName = null, tagList, itemType = null) {
      let getItem = selectList.find(obj => obj.id === item)
      let listItem = tagList.find(obj => obj.id === item)

      if (!listItem) {
        let newItem = Object.assign({}, getItem)
        if (itemName === 'authorList') {
          newItem = Object.assign({}, getItem, {
            type: 1,
          })
        }
        if (this.isUpdate) {
          this.setStateData({
            property: 'readingSheet',
            item: itemName,
            value: newItem,
          })
        } else {
          tagList.push(newItem)
        }
        if (itemType) {
          this.form[itemType] = null
        }
      }
    },
    setItemChangeValue(event, index, itemName, name) {
      if (!this.isUpdate) {
        if (itemName !== 'contactList') {
          this.$set(this.form[itemName][index], name, event)
        }
        if (itemName === 'authorList') {
          this.setStateAuthorData({
            property: 'authors',
            item: this.form[itemName][index],
            name: name,
            value: event,
          })
        }
        if (itemName === 'contactList' || itemName === 'sourceList') {
          this.setStateContactData({
            property: 'contacts',
            item: this.form[itemName][index],
            name: name,
            value: event,
          })
        }
      } else {
        this.setStatePropertyValue({
          property: 'readingSheet',
          item: itemName,
          index: index,
          name: name,
          value: event,
        })
      }
    },
    copyFileDocuments(documents, type) {
      let documentList = []
      if (documents.length > 0) {
        documents.filter(file => {
          let image
          if (file.type === type) {
            image = file.full_path
            documentList.push({ name: file.filename, id: file.id, url: image })
          }
        })
      }
      return documentList
    },
    previewFile(file) {
      window.open(file.url, '_blank')
    },
    filterCategories(search) {
      this.getCategories({ name: search })
    },
    filterThematics(search) {
      this.getThematics({ name: search })
    },
    filterGenres(search) {
      this.getGenres({ name: search })
    },
    filterCompanines(search) {
      this.getCompanies({ name: search })
    },
    filterCountries(search) {
      this.getCountries({ name: search })
    },
    filterContacts(search) {
      this.getContacts({ name: search })
    },
    filterAuthors(search) {
      this.getAuthors({ name: search })
    },
    filterPeriods(search) {
      this.getPeriods({ name: search })
    },
    filterReporters(search) {
      this.getReporters({ title: search })
    },
    getTree(data) {
      let tree = []
      if (data) {
        for (let i = 0, len = data.length; i < len; i++) {
          if (data[i].parent_id === undefined || data[i].parent_id === null) {
            let node = {
              id: data[i].id,
              name: data[i].name,
              children: data
                .filter(obj => obj.parent_id === data[i].id)
                .map(item => ({ id: item.id, name: item.name })),
            }
            tree.push(node)
          }
        }
      }
      return tree
    },
    handleTreeChange(itemName, value) {
      this.form[itemName] = value
      if (itemName === 'genres') {
        if (this.form.genres.indexOf(this.form.primary_genre) === -1) {
          this.form.primary_genre = null
        }
      }
    },
    refreshFormData() {
      const videoList = this.copyFileDocuments(
        this.readingSheet.data.documentList,
        DOCUMENT_TYPES.VIDEOS
      )
      this.newDocumentVideo = []
      this.form.videoList = videoList
    },
    redirectToAiComparation() {
      router.push({
        name: 'readingSheets-aiComparation',
        params: { id: this.$route.params.id },
      })
    },
  },
}
</script>
