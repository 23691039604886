import { generateMutations } from '../builder'
import Vue from 'vue'

export default {
  ...generateMutations('getRequests', 'requests'),
  ...generateMutations('saveRequest', 'request'),
  ...generateMutations('getRequest', 'request'),
  ...generateMutations('getRequestTypes', 'types'),
  ...generateMutations('getRequestStatuses', 'statuses'),
  ...generateMutations('deleteRequest'),
  changeRequestStatus(state, { property, item, name, value }) {
    let index = state[property].data.findIndex(obj => obj.id === item)
    if (state[property].data[index][name] !== undefined) {
      state[property].data[index][name] = value
    } else {
      Vue.set(state[property].data[index], name, value)
    }
  },
}
