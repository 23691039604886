<template>
  <div class="requests-table" v-loading="saveRequestLoading">
    <div class="search-status-wrapper">
      <el-row :gutter="10" v-if="showFilter">
        <el-col :span="12">
          <el-select
            v-model="extraFilters.status"
            popper-class="app-select-options"
            clearable
            placeholder="Search by status"
            class="app-select"
          >
            <el-option
              v-for="item in statuses.data"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-select
            v-model="extraFilters.type"
            popper-class="app-select-options"
            clearable
            placeholder="Search by type"
            class="app-select"
          >
            <el-option
              v-for="item in types.data"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <el-table :data="requests.data" v-loading="getRequestsLoading">
      <el-table-column prop="id" label="#" width="65"> </el-table-column>
      <el-table-column label="User name" min-width="150">
        <template v-slot="scope">
          {{ scope.row.user.name }}
          <span v-if="scope.row.user.company">
            ({{ scope.row.user.company }})
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="user.email" label="Email" min-width="100">
      </el-table-column>
      <el-table-column label="Reading sheet" min-width="120">
        <template v-slot="scope">
          <router-link
            :to="{
              name: 'readingSheets-save',
              params: { id: scope.row.book_id },
            }"
          >
            <i> {{ scope.row.book ? scope.row.book.original_title : '' }}</i>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="demand_date" label="Date" min-width="100">
      </el-table-column>
      <el-table-column
        prop="demand_end_date"
        label="End date"
        min-width="100"
        :formatter="
          (row, col, cell) => {
            return cell ? cell : '-'
          }
        "
      >
      </el-table-column>
      <el-table-column prop="demand_type.name" label="Type" min-width="100">
      </el-table-column>
      <el-table-column prop="demand_status.name" label="Status" min-width="100">
        <template v-slot="scope">
          <el-select
            :value="scope.row.demand_status_id"
            @change="
              confirmStatusUpdate(scope.row.id, 'demand_status_id', $event)
            "
          >
            <el-option
              v-for="status in statuses.data"
              :key="status.id"
              :value="status.id"
              :label="status.name"
            ></el-option>
          </el-select>
          <el-dialog
            title="Update Request"
            :visible.sync="openCommentary"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
          >
            <el-form>
              <el-form-item
                label="Commentary"
                :error="errors['commentary']"
                :label-width="labelWidth + 'px'"
              >
                <el-input
                  @input="
                    setRequestCommentary(scope.row.id, 'commentary', $event)
                  "
                  type="textarea"
                  :value="form.commentary"
                  :rows="4"
                ></el-input>
              </el-form-item>
            </el-form>
            <span class="horizontal-align">
              <el-button @click="openCommentary = false">Cancel</el-button>
              <el-button
                :loading="saveRequestLoading"
                type="primary"
                @click="updateStatus(scope.row.id)"
              >
                Confirm
              </el-button>
            </span>
          </el-dialog>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="160">
        <template v-slot="scope">
          <router-link
            :to="{ name: 'request-save', params: { id: scope.row.id } }"
            tag="span"
            class="ml-10"
          >
            <el-button type="primary" size="small" class="plain-primary" plain>
              <i class="el-icon-edit" />
            </el-button>
          </router-link>
          <el-popconfirm
            confirmButtonText="OK"
            cancelButtonText="No, Thanks"
            icon="el-icon-info"
            iconColor="red"
            title="Are you sure to delete this?"
            @confirm="confirmDelete(scope.row.id)"
            @cancel="
              $notify({
                title: 'Cancel',
                message: 'Deletion cancelled',
                type: 'info',
              })
            "
          >
            <el-button
              size="small"
              type="danger"
              slot="reference"
              class="ml-10"
            >
              <i class="el-icon-delete" />
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :meta="requests.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { pagination } from '@/mixins'
import Paginator from '@/components/Form/Paginator'
import { apiError, labelWidth } from '@/mixins'

export default {
  name: 'RequestsTable',
  components: {
    Paginator,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: () => null,
    },
    bookId: {
      type: Number,
      required: false,
      default: () => null,
    },
    showFilter: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  mixins: [pagination('getRequests'), apiError('saveRequestError'), labelWidth],
  data() {
    return {
      openCommentary: false,
      formId: null,
      form: {},
    }
  },
  computed: {
    ...mapState('request', [
      'requests',
      'statuses',
      'types',
      'getRequestsLoading',
      'saveRequestError',
      'saveRequestLoading',
    ]),
  },
  created() {
    this.extraFilters.user_id = this.userId
    this.extraFilters.book_id = this.bookId
  },
  mounted() {
    this.getRequests({ user_id: this.userId, book_id: this.bookId })
    this.getRequestStatuses()
    this.getRequestTypes()
  },
  methods: {
    ...mapActions('request', [
      'getRequests',
      'getRequestStatuses',
      'getRequestTypes',
      'saveRequest',
      'deleteRequest',
    ]),
    ...mapMutations('request', ['changeRequestStatus']),
    async updateStatus() {
      let form = this.requests.data.find(item => item.id === this.formId)
      await this.saveRequest({
        routeParameters: {
          id: this.formId,
        },
        ...form,
      }).then(() => {
        if (this.saveRequestError.status === null) {
          this.openCommentary = false
        }
      })
    },
    confirmStatusUpdate(id, itemName, value) {
      this.formId = id
      this.form = this.requests.data.find(item => item.id === id)
      this.$confirm(
        `Are you sure, you want to update this request from ${this.form.user.name} (${this.form.user.email})?`,
        'Confirmation',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        }
      ).then(async () => {
        try {
          await this.changeRequestStatus({
            property: 'requests',
            item: id,
            name: itemName,
            value: value,
          })
          if (value === 4) {
            // Only when status is set to done
            this.openCommentary = true
          } else {
            await this.updateStatus()
          }
        } catch (e) {
          this.$notify({
            title: 'Failed',
            message: 'Request update Failed',
            type: 'danger',
          })
        }
      })
    },
    async setRequestCommentary(id, itemName, value) {
      await this.changeRequestStatus({
        property: 'requests',
        item: this.formId,
        name: itemName,
        value: value,
      })
    },
    async confirmDelete(id) {
      try {
        await this.deleteRequest({
          routeParameters: {
            id,
          },
        })
        this.search(true)
        this.$notify({
          title: 'Success',
          message: 'Deletion succeed',
          type: 'success',
        })
      } catch (e) {
        this.$notify({
          title: 'Failed',
          message: 'Deletion Failed',
          type: 'danger',
        })
      }
    },
  },
}
</script>
