<template>
  <div class="save">
    <save-page-header title="Literary Prize"></save-page-header>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native="submit"
    >
      <el-form-item label="Label" prop="label">
        <el-input
          v-model="form.label"
          :class="['literary-prize-edit', { 'input-error': inputError }]"
        ></el-input>
      </el-form-item>
      <div class="align-right">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveLiteraryPrizeLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            {{ isUpdate ? 'Save Literary Prize' : 'Create Literary Prize' }}
          </el-button>
        </el-col>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import { initMultilingual } from '@/services/helpers'
import SavePageHeader from '@/components/ui/SavePageHeader'

export default {
  components: {
    SavePageHeader,
  },
  mixins: [apiError('saveLiteraryPrizeError'), labelWidth],
  data: () => ({
    form: {
      names: initMultilingual(),
    },
  }),
  computed: {
    ...mapState('literaryPrize', [
      'literaryPrize',
      'saveLiteraryPrizeError',
      'saveLiteraryPrizeLoading',
    ]),
    isUpdate() {
      return this.$route.params.id !== undefined
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getLiteraryPrize({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = this.literaryPrize.data
    }
  },
  methods: {
    ...mapActions('literaryPrize', ['saveLiteraryPrize', 'getLiteraryPrize']),
    async submit() {
      await this.saveLiteraryPrize({
        routeParameters: {
          id: this.$route.params.id,
        },
        ...this.form,
      })
    },
  },
}
</script>
