<template>
  <div class="list">
    <portal to="search">
      <search placeholder="Find a prompt..." v-model="globalFilter"></search>
    </portal>
    <div class="mb-50">
      <h2 class="title primary-color">AI PROMPT LIST</h2>
    </div>
    <el-table :data="aiPrompts.data" v-loading="getAiPromptsLoading">
      <el-table-column prop="id" label="#" width="75"> </el-table-column>
      <el-table-column prop="label" label="Label" min-width="250">
      </el-table-column>
      <el-table-column width="120">
        <template v-slot="scope">
          <router-link
            :to="{ name: 'ai-prompt-save', params: { id: scope.row.id } }"
          >
            <el-button type="primary" size="small" class="plain-primary" plain>
              <i class="el-icon-edit" />
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :meta="aiPrompts.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
import Search from '@/components/Form/Search.vue'

export default {
  components: {
    Paginator,
    Search,
  },
  mixins: [pagination('getAiPrompts', 'label')],
  computed: {
    ...mapState('aiPrompt', ['aiPrompts', 'getAiPromptsLoading']),
  },
  mounted() {
    this.getAiPrompts()
  },
  methods: {
    ...mapActions('aiPrompt', ['getAiPrompts']),
  },
}
</script>
