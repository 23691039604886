import { generateMutations } from '../builder'
import Vue from 'vue'

export default {
  ...generateMutations('getReadingSheets', 'readingSheets'),
  ...generateMutations('saveReadingSheet', 'readingSheet'),
  ...generateMutations('getReadingSheet', 'readingSheet'),
  ...generateMutations('getBookStatuses', 'statuses'),
  ...generateMutations('getRightsStatuses', 'rightsStatuses'),
  ...generateMutations('deleteFile'),
  ...generateMutations('deleteManuscript'),
  setStateData(state, { property, item, value }) {
    state[property].data[item].push(value)
  },
  removeStateData(state, { property, item, value }) {
    state[property].data[item].splice(value, 1)
  },
  addStateItem(state, { property, item, value }) {
    console.log([value])
    state[property].data[item].push({})
  },
  setStatePropertyValue(state, { property, item, index, name, value }) {
    if (state[property].data[item][index][name]) {
      state[property].data[item][index][name] = value
    } else {
      Vue.set(state[property].data[item][index], name, value)
    }
  },
  setFilters(state, value) {
    state.filterStore = { ...state.filterStore, ...value }
  },
}
