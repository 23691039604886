import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { Notification } from 'element-ui'
import { getDeep } from './helpers'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
})

instance.interceptors.request.use(config => {
  return config
})

instance.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (axios.isCancel(error)) {
      return Promise.reject(error)
    }

    const { response } = error
    const path = response.config.url.replace(response.config.baseURL, '')

    if (path !== 'admin/me') {
      Notification({
        title: 'Error',
        message:
          response.data && response.data.message
            ? response.data.message
            : "Une erreur innatendue s'est produite",
        type: 'error',
        offset: 100,
        duration: 8000,
      })
    }

    // Global redirect on 403
    if (response.status === 403) {
      router.push({ name: 'home' })
    }

    // Global redirection on 401
    if (response.status === 401) {
      store.commit('auth/logoutSuccess')

      if (path !== 'admin/me') {
        router.push({ name: 'auth-login' })
      }
    }

    return Promise.reject(error)
  }
)

export function error(errors) {
  let flatErrors = {}
  const errorValues = getDeep(errors, 'data', 'errors')

  if (!errorValues || typeof errorValues !== 'object') {
    return flatErrors
  }

  Object.keys(errorValues).forEach(key => {
    flatErrors[key] = errorValues[key].flat().join('\n')
  })

  return flatErrors
}

export default instance
