<template>
  <div class="save" v-loading.fullscreen.lock="isPageLoading">
    <save-page-header title="Author"></save-page-header>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native="submit"
    >
      <el-form-item label="Full name" prop="name" :error="errors['name']">
        <el-input v-model="form.name" placeholder="Full name(Firstname first)">
        </el-input>
      </el-form-item>
      <!-- Country field -->
      <el-form-item label="Country" :error="errors['country_id']">
        <el-select
          v-model="form.country_id"
          placeholder="Country"
          class="el-col-md-24"
          filterable
          autocomplete="on"
          :filter-method="filterCountries"
        >
          <el-option
            v-for="country in countries.data"
            :key="country.id"
            :label="country.name"
            :value="country.id"
            :disabled="getCountriesLoading"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <translatable-input
        input-type="textarea"
        :rows="4"
        :errors="errors"
        :source.sync="form.biographies"
        sourceKey="biographies"
        placeholder="Biography"
        title="Biography"
        :label-width="labelWidth"
      />
      <div :class="[{ 'align-right': !inModal, 'align-modal': inModal }]">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveAuthorLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            {{ isUpdate ? 'Save Author' : 'Create Author' }}
          </el-button>
        </el-col>
      </div>
    </el-form>

    <div v-if="!getAuthorLoading && books.length > 0">
      <save-page-header title="Book(s) of this author"></save-page-header>
      <books-table
        class="reading-sheet-table"
        :data="books"
        @row-click="goToBook"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import TranslatableInput from '@/components/Form/TranslatableInput'
import { initMultilingual } from '@/services/helpers'
import SavePageHeader from '@/components/ui/SavePageHeader'
import BooksTable from '@/components/ui/BooksTable'

export default {
  components: {
    TranslatableInput,
    SavePageHeader,
    BooksTable,
  },
  mixins: [apiError('saveAuthorError'), labelWidth],
  data: () => ({
    form: {
      biographies: initMultilingual(),
    },
    books: [],
  }),
  computed: {
    ...mapState('author', [
      'author',
      'saveAuthorError',
      'saveAuthorLoading',
      'getAuthorLoading',
    ]),
    ...mapState('country', ['countries', 'getCountriesLoading']),
    isUpdate() {
      return this.$route.params.id !== undefined && !this.inModal
    },
    isPageLoading() {
      return this.getAuthorLoading
    },
  },
  props: {
    inModal: {
      required: false,
      default: () => false,
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getAuthor({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.author.data,
        biographies: initMultilingual(this.author.data.biographies),
      }

      this.books = this.author.data.books || []
      delete this.form.books

      this.getCountries({
        name: this.form.country ? this.form.country.name : '',
      })
    } else {
      this.getCountries({ perPage: 10 })
    }
  },
  methods: {
    ...mapActions('author', ['saveAuthor', 'getAuthor']),
    ...mapActions('country', ['getCountries']),
    submit() {
      this.saveAuthor({
        routeParameters: {
          id: this.isUpdate ? this.$route.params.id : null,
        },
        ...this.form,
        inModal: this.inModal,
      }).then(() => {
        if (this.saveAuthorError.status == null) {
          this.$emit('created', true)
        }
      })
    },
    filterCountries(search) {
      this.getCountries({ name: search })
    },
    goToBook(book) {
      this.$router.push({ name: 'readingSheets-save', params: { id: book.id } })
    },
  },
}
</script>
