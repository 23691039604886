import { generateAction } from '../builder'

import router from '@/router'

const getThematics = generateAction('getThematics', 'admin/thematics')
const getThematic = generateAction('getThematic', 'admin/thematics/{id}')
const saveThematic = generateAction(
  'saveThematic',
  'admin/thematics/{id}',
  'POST',
  () => {
    return router.push({
      name: 'thematic-index',
    })
  }
)
const deleteThematic = generateAction(
  'deleteThematic',
  'admin/thematics/{id}',
  'DELETE'
)

export default {
  getThematics,
  saveThematic,
  getThematic,
  deleteThematic,
}
