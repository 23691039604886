import List from '@/views/Format/List.vue'
import View from '@/views/Format/View.vue'

export const formatRoutes = [
  {
    path: '/formats',
    name: 'format-index',
    component: List,
  },
  {
    path: '/format/:id?',
    name: 'format-view',
    component: View,
    meta: { menuIndex: '/formats' },
  },
]
