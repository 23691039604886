<template>
  <div class="list-item">
    <div class="content">
      <div class="detail vertical-align">
        <p class="list-item-id">#{{ item.id }} -</p>
        <p class="bold">{{ item.name }}</p>
        <div class="line"></div>
        <span
          v-for="(author, index) in item.authors"
          :key="author.id"
          class="author-link-wrapper"
        >
          <router-link
            :to="{ name: 'author-save', params: { id: author.id } }"
            tag="span"
            @click.native="onAuthorClick"
          >
            <p class="primary-color bold pointer">
              {{ author.name
              }}{{ item.authors.length === index + 1 ? '' : ',' }}
            </p>
          </router-link>
        </span>
      </div>
      <div class="description">
        {{ item.description }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: false,
      default: () => {
        return {
          id: '',
          name: '',
          author: '',
          description: '',
          status: '',
        }
      },
    },
  },
  methods: {
    onAuthorClick(event) {
      event.stopPropagation()
    },
  },
}
</script>
