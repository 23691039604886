import List from '@/views/News/List.vue'
import Saves from '@/views/News/Save.vue'

export const newsRoutes = [
  {
    path: '/news',
    name: 'news-index',
    component: List,
  },
  {
    path: '/new/:id?',
    name: 'news-save',
    component: Saves,
    meta: { menuIndex: '/news' },
  },
]
