import List from '@/views/Country/List.vue'
import View from '@/views/Country/View.vue'

export const countryRoutes = [
  {
    path: '/countries',
    name: 'country-index',
    component: List,
  },
  {
    path: '/country/:id?',
    name: 'country-view',
    component: View,
    meta: { menuIndex: '/countries' },
  },
]
