<template>
  <div class="list searches-list">
    <el-table :data="userSearchesData" v-loading="getUserSearchesLoading">
      <el-table-column prop="title" label="Title"></el-table-column>
      <el-table-column label="Search values">
        <template v-slot="scope">
          <div v-for="item in scope.row.filterValues" :key="item.label">
            {{ item.text }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UserSearchesTab',
  created() {
    this.getUserSearchesData()
  },
  computed: {
    ...mapState('user', ['userSearches', 'getUserSearchesLoading']),
    userSearchesData() {
      let userSearchesData = this.userSearches.data.map(item => {
        let filterData = [
          {
            category: 'authorFilter',
            label: 'Author',
          },
          {
            category: 'genreFilter',
            label: 'Genre',
          },
          {
            category: 'countryFilter',
            label: 'Country',
          },
          {
            category: 'thematicFilter',
            label: 'Thematic',
          },
          {
            category: 'locationFilter',
            label: 'Place',
          },
          {
            category: 'periodFilter',
            label: 'Period',
          },
          {
            category: 'timeFilter',
            label: 'Time',
          },
          {
            category: 'audienceFilter',
            label: 'Audience',
          },
          {
            category: 'languageFilter',
            label: 'Language',
          },
          {
            category: 'categoryFilter',
            label: 'Category',
          },
          {
            category: 'formatFilter',
            label: 'Format',
          },
        ]

        let parts = []
        let textFilter = item.text_search
        let filterValues = item.saved_filters
        let filterValueTexts = item.filter_texts

        if (textFilter) {
          parts.push({
            label: 'Text',
            values: [textFilter],
            text: `Text: ${textFilter}`,
          })
        }

        filterData.forEach(filter => {
          if (typeof filterValues[filter.category] !== 'undefined') {
            let values = filterValues[filter.category].map(valueId => {
              return filterValueTexts[filter.category].find(
                item => item.id === valueId
              ).name
            })
            if (values.length > 0) {
              parts.push({
                label: filter.label,
                values: values,
                text: `${filter.label}: ${values.join(', ')}`,
              })
            }
          }
        })

        let ratingsOptions = {
          quality: 'Literary quality',
          concept: 'Concept',
          plot: 'Intrigue',
          characters: 'Characters',
          coherence: 'Coherence',
          commercial: 'Commercial potencial',
          emotions: 'Emotions',
          dialogues: 'Dialogues',
        }

        Object.values(filterValues.ratingsFilter).forEach(item => {
          if (item.rate > 0) {
            let value = item.rate === 3 ? ': 3' : ` >= ${item.rate}`
            parts.push({
              label: `Rate (${ratingsOptions[item.name]})`,
              values: [value],
              text: `Rate (${ratingsOptions[item.name]})${value}`,
            })
          }
        })

        let newItem = {
          title: item.title,
          filterValues: parts,
        }

        return newItem
      })

      return userSearchesData
    },
  },
  methods: {
    ...mapActions('user', ['getUserSearches']),
    async getUserSearchesData() {
      let userId = this.$route.params.id
      await this.getUserSearches({
        routeParameters: {
          id: userId,
        },
      })
    },
  },
}
</script>
