<template>
  <div class="list">
    <portal to="search">
      <search
        placeholder="Find a literary prize..."
        v-model="globalFilter"
      ></search>
    </portal>
    <div class="space-between mb-50">
      <h2 class="title primary-color">Literary Prize List</h2>
      <router-link :to="{ name: 'literary-prize-save' }" tag="span">
        <el-button type="primary" class="plain-primary" plain>
          <i class="el-icon-plus"></i>
          CREATE A NEW LITERARY PRIZE
        </el-button>
      </router-link>
    </div>
    <el-table :data="literaryPrizes.data" v-loading="getLiteraryPrizesLoading">
      <el-table-column prop="id" label="#" width="75"> </el-table-column>
      <el-table-column prop="label" label="Label" min-width="250">
      </el-table-column>
      <el-table-column label="Actions" width="160">
        <template v-slot="scope">
          <router-link
            :to="{ name: 'literary-prize-save', params: { id: scope.row.id } }"
            tag="span"
            class="ml-10"
          >
            <el-button type="primary" size="small" class="plain-primary" plain>
              <i class="el-icon-edit" />
            </el-button>
          </router-link>
          <el-popconfirm
            v-if="scope.row.canBeDeleted"
            confirmButtonText="OK"
            cancelButtonText="No, Thanks"
            icon="el-icon-info"
            iconColor="red"
            title="Are you sure to delete this?"
            @confirm="confirmDelete(scope.row.id)"
            @cancel="
              $notify({
                title: 'Cancel',
                message: 'Deletion cancelled',
                type: 'info',
              })
            "
          >
            <el-button
              size="small"
              type="danger"
              slot="reference"
              class="ml-10"
            >
              <i class="el-icon-delete" />
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :meta="literaryPrizes.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import Paginator from '@/components/Form/Paginator.vue'
import Search from '@/components/Form/Search.vue'
import { pagination } from '@/mixins'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Paginator,
    Search,
  },
  mixins: [pagination('getLiteraryPrizes', 'label')],
  computed: {
    ...mapState('literaryPrize', [
      'literaryPrizes',
      'getLiteraryPrizesLoading',
    ]),
  },
  mounted() {
    this.getLiteraryPrizes()
  },
  methods: {
    ...mapActions('literaryPrize', [
      'getLiteraryPrizes',
      'deleteLiteraryPrize',
    ]),
    async confirmDelete(id) {
      try {
        await this.deleteLiteraryPrize({
          routeParameters: {
            id,
          },
        })
        this.search(true)
        this.$notify({
          title: 'Success',
          message: 'Deletion succeed',
          type: 'success',
        })
      } catch (e) {
        this.$notify({
          title: 'Failed',
          message: 'Deletion Failed',
          type: 'danger',
        })
      }
    },
  },
}
</script>
