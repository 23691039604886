import { generateState } from '@/store/builder'

export default {
  literaryPrizes: [],
  literaryPrize: {},
  ...generateState('getLiteraryPrizes'),
  ...generateState('saveLiteraryPrize'),
  ...generateState('getLiteraryPrize'),
  ...generateState('deleteLiteraryPrize'),
}
