import List from '@/views/LiteraryPrize/List.vue'
import Save from '@/views/LiteraryPrize/Save.vue'

export const literaryPrizeRoutes = [
  {
    path: '/literary-prize',
    name: 'literary-prize-index',
    component: List,
  },
  {
    path: '/literary-prize/:id?',
    name: 'literary-prize-save',
    component: Save,
    meta: { menuIndex: '/literary-prize' },
  },
]
