import List from '@/views/Period/List.vue'
import Save from '@/views/Period/Save.vue'

export const periodRoutes = [
  {
    path: '/periods',
    name: 'period-index',
    component: List,
  },
  {
    path: '/period/:id?',
    name: 'period-save',
    component: Save,
    meta: { menuIndex: '/periods' },
  },
]
