<template>
  <div class="save">
    <save-page-header title="Audience"></save-page-header>
    <el-form class="mt-30">
      <translatable-input
        :errors="errors"
        :source.sync="form.names"
        sourceKey="names"
        :isDisabled="true"
        title="Name"
        :label-width="labelWidth"
      />
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import TranslatableInput from '@/components/Form/TranslatableInput'
import { initMultilingual } from '@/services/helpers'
import SavePageHeader from '@/components/ui/SavePageHeader'

export default {
  components: {
    TranslatableInput,
    SavePageHeader,
  },
  mixins: [apiError('getAudienceError'), labelWidth],
  data: () => ({
    form: {
      names: initMultilingual(),
    },
  }),
  computed: {
    ...mapState('audience', ['audience', 'getAudienceError']),
    isFetch() {
      return this.$route.params.id !== undefined
    },
  },
  async mounted() {
    if (this.isFetch) {
      await this.getAudience({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.audience.data,
        names: initMultilingual(this.audience.data.names),
      }
    }
  },
  methods: {
    ...mapActions('audience', ['getAudience']),
  },
}
</script>
