<template>
  <el-row
    v-if="meta !== undefined && meta.total > 10"
    type="flex"
    justify="end"
    class="mt-10"
  >
    <el-pagination
      background
      layout="sizes, prev, pager, next"
      :total="meta.total"
      :current-page="meta.current_page"
      :page-sizes="[10, 20, 50]"
      @current-change="onCurrentChange"
      @size-change="onSizeChange"
    >
    </el-pagination>
  </el-row>
</template>

<script>
export default {
  name: 'Paginator',
  props: {
    meta: {
      type: Object,
      default: () => {},
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onCurrentChange(current) {
      this.$emit('update:currentPage', current)
    },
    onSizeChange(size) {
      this.$emit('update:perPage', size)
    },
  },
}
</script>
