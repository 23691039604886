<template>
  <div class="default-layout">
    <el-container>
      <el-aside width="200px">
        <layout-aside />
      </el-aside>
      <el-container>
        <el-header class="p-0" height="70px">
          <layout-header />
        </el-header>
        <el-main>
          <slot />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LayoutAside from '@/components/Layout/Aside'
import LayoutHeader from '@/components/Layout/Header'

export default {
  components: {
    LayoutAside,
    LayoutHeader,
  },
  computed: {
    ...mapState('auth', ['logoutLoading']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
}
</script>
