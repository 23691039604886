import { generateAction } from '../builder'

import router from '@/router'

const getAllNews = generateAction('getAllNews', 'admin/news')
const getNews = generateAction('getNews', 'admin/news/{id}')
const saveNews = generateAction('saveNews', 'admin/news/{id}', 'POST', () => {
  return router.push({
    name: 'news-index',
  })
})
const deleteNews = generateAction('deleteNews', 'admin/news/{id}', 'DELETE')

const deleteImage = generateAction(
  'deleteImage',
  'admin/news/news-image/{news}',
  'DELETE'
)
export default {
  getAllNews,
  saveNews,
  getNews,
  deleteNews,
  deleteImage,
}
