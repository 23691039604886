export default {
  setReady: (state, data) => {
    state.ready = data
  },
  setLoadingProgressPercentage: (state, data) => {
    state.loadingProgressPercentage = data
  },
  setActiveNavTab(state, data) {
    state.activeNavTab = data
  },
}
