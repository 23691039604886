<template>
  <div class="filters">
    <el-row>
      <el-col :span="4">
        <el-select
          v-model="filter.author"
          clearable
          filterable
          remote
          placeholder="Author"
          autocomplete="on"
          :loading="getAuthorsLoading"
          :remote-method="getAuthorsList"
        >
          <el-option
            v-for="author in authors.data"
            :key="author.id"
            :label="author.name"
            :value="author.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.rightsStatus"
          clearable
          placeholder="State of rights"
        >
          <el-option
            v-for="rightsStatus in rightsStatuses.data"
            :key="rightsStatus.id"
            :label="rightsStatus.name"
            :value="rightsStatus.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-date-picker
          v-model="filter.publicationDate"
          clearable
          type="month"
          placeholder="Publication date"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.statuses"
          clearable
          multiple
          filterable
          default-first-option
          placeholder="Status"
        >
          <el-option
            v-for="status in statuses.data"
            :key="status.id"
            :label="status.label"
            :value="status.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.audiences"
          clearable
          multiple
          filterable
          default-first-option
          placeholder="Audience(s)"
        >
          <el-option
            v-for="audience in audiences.data"
            :key="audience.id"
            :label="audience.name"
            :value="audience.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.genres"
          clearable
          multiple
          filterable
          default-first-option
          placeholder="Genre(s)"
          autocomplete="on"
        >
          <el-option
            v-for="genre in genres.data"
            :key="genre.id"
            :label="genre.name"
            :value="genre.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.categories"
          clearable
          multiple
          filterable
          default-first-option
          placeholder="Categories"
          autocomplete="on"
        >
          <el-option
            v-for="category in categories.data"
            :key="category.id"
            :label="category.name"
            :value="category.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.thematics"
          clearable
          multiple
          filterable
          default-first-option
          placeholder="Thematic(s)"
          autocomplete="on"
        >
          <el-option
            v-for="thematic in thematics.data"
            :key="thematic.id"
            :label="thematic.name"
            :value="thematic.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.places"
          clearable
          multiple
          filterable
          default-first-option
          placeholder="Place(s)"
          autocomplete="on"
        >
          <el-option
            v-for="country in countries.data"
            :key="country.id"
            :label="country.name"
            :value="country.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.periods"
          clearable
          multiple
          filterable
          default-first-option
          placeholder="Period(s)"
          autocomplete="on"
        >
          <el-option
            v-for="period in periods.data"
            :key="period.id"
            :label="period.name"
            :value="period.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.times"
          clearable
          multiple
          filterable
          default-first-option
          placeholder="Time(s)"
          autocomplete="on"
        >
          <el-option
            v-for="time in times.data"
            :key="time.id"
            :label="time.name"
            :value="time.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.nativeCountry"
          clearable
          filterable
          placeholder="Native country"
          autocomplete="on"
        >
          <el-option
            v-for="country in countries.data"
            :key="country.id"
            :label="country.name"
            :value="country.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.motherTongue"
          clearable
          filterable
          placeholder="Mother tongue"
        >
          <el-option
            v-for="language in languages.data"
            :key="language.id"
            :label="language.name"
            :value="language.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.series"
          clearable
          filterable
          placeholder="Series"
        >
          <el-option
            v-for="item in seriesOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="filter.formats"
          clearable
          multiple
          filterable
          placeholder="Format(s)"
        >
          <el-option
            v-for="format in formats.data"
            :key="format.id"
            :label="format.name"
            :value="format.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5" v-for="(name, key) in ratingTypes" :key="key">
        <div class="rating vertical-align">
          <p>{{ name }}</p>
          <el-rate v-model="filter[key]" :max="4" class="ml-20"></el-rate>
          <i
            v-if="filter[key] !== 0"
            class="ml-20 pointer icon ion-ios-refresh"
            @click="filter[key] = 0"
          ></i>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      filter: {},
      ratingTypes: {
        quality: 'Literary quality',
        concept: 'Concept',
        plot: 'Plot',
        characters: 'Characters',
        coherence: 'Coherence',
        emotions: 'Emotions',
        dialogues: 'Dialogues',
        commercial: 'Commercial',
      },
    }
  },
  watch: {
    filter: {
      handler: function() {
        this.setFilters(this.filter)
        this.$emit('input', this.filter)
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('readingSheet', [
      'statuses',
      'rightsStatuses',
      'getBookStatusesLoading',
      'filterStore',
    ]),
    ...mapState('period', ['periods', 'getPeriodsLoading']),
    ...mapState('thematic', ['thematics', 'getThematicsLoading']),
    ...mapState('author', ['authors', 'getAuthorsLoading']),
    ...mapState('category', ['categories', 'getCategoriesLoading']),
    ...mapState('time', ['times', 'getTimesLoading']),
    ...mapState('audience', ['audiences', 'getAudiencesLoading']),
    ...mapState('genre', ['genres', 'getGenresLoading']),
    ...mapState('country', ['countries', 'getCountriesLoading']),
    ...mapState('locale', ['languages', 'getLanguagesLoading']),
    ...mapState('format', ['formats', 'getFormatsLoading']),
    seriesOptions() {
      return [
        { id: 1, name: 'All Books' },
        { id: 2, name: 'Series of Books' },
        { id: 3, name: 'Not a Series of Books' },
      ]
    },
  },
  mounted() {
    this.filter = this.filterStore
    this.getBookStatuses()
    this.getPeriods({ perPage: 300 })
    this.getThematics({ perPage: 300 })
    this.getAuthors({ perPage: 10 })
    this.getCategories({ perPage: 300 })
    this.getTimes({ perPage: 300 })
    this.getAudiences({ perPage: 300 })
    this.getGenres({ perPage: 300 })
    this.getCountries({ perPage: 500 })
    this.getFormats({ perPage: 300 })
    this.getLanguages()
    this.getRightsStatuses()
  },
  methods: {
    ...mapMutations('readingSheet', ['setFilters']),
    ...mapActions('readingSheet', [
      'getReadingSheets',
      'getBookStatuses',
      'getRightsStatuses',
    ]),
    ...mapActions('period', ['getPeriods']),
    ...mapActions('thematic', ['getThematics']),
    ...mapActions('author', ['getAuthors']),
    ...mapActions('category', ['getCategories']),
    ...mapActions('time', ['getTimes']),
    ...mapActions('audience', ['getAudiences']),
    ...mapActions('genre', ['getGenres']),
    ...mapActions('country', ['getCountries']),
    ...mapActions('locale', ['getLanguages']),
    ...mapActions('format', ['getFormats']),
    getAuthorsList(search) {
      this.getAuthors({ name: search, perPage: 50 })
    },
  },
}
</script>
