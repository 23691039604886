import { generateAction } from '../builder'

const getCharacterFunctions = generateAction(
  'getCharacterFunctions',
  'admin/character-functions'
)

export default {
  getCharacterFunctions,
}
