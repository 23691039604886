import { generateState } from '../builder'

export default {
  genres: [],
  genre: {},
  ...generateState('getGenres'),
  ...generateState('saveGenre'),
  ...generateState('getGenre'),
  ...generateState('deleteGenre'),
}
