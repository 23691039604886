import { generateState } from '../builder'

export default {
  dashboardData: {
    recentUsers: [],
    recentCriteria: [],
    popularBooks: [],
    recentSearches: [],
  },
  ...generateState('getDashboardData'),
}
