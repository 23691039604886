<template>
  <div class="list">
    <portal to="search">
      <search
        placeholder="Find an Administrator..."
        v-model="globalFilter"
      ></search>
    </portal>
    <div class="space-between mb-50">
      <h2 class="title primary-color">ADMINISTRATOR LIST</h2>
      <router-link :to="{ name: 'administrator-save' }" tag="span">
        <el-button type="primary" class="plain-primary" plain>
          <i class="el-icon-plus"></i>
          CREATE A NEW ADMINISTRATOR
        </el-button>
      </router-link>
    </div>
    <el-table :data="administrators.data" v-loading="getAdministratorsLoading">
      <el-table-column prop="id" label="#" width="75"> </el-table-column>
      <el-table-column prop="name" label="Name" min-width="250">
      </el-table-column>
      <el-table-column prop="email" label="Email" min-width="250">
      </el-table-column>
      <el-table-column width="120">
        <template v-slot="scope">
          <router-link
            :to="{ name: 'administrator-save', params: { id: scope.row.id } }"
          >
            <el-button type="primary" size="small" class="plain-primary" plain>
              <i class="el-icon-edit" />
            </el-button>
          </router-link>
          <el-popconfirm
            confirmButtonText="OK"
            cancelButtonText="No, Thanks"
            icon="el-icon-info"
            iconColor="red"
            title="Are you sure to delete this?"
            @confirm="confirmDelete(scope.row.id)"
            @cancel="
              $notify({
                title: 'Cancel',
                message: 'Deletion cancelled',
                type: 'info',
              })
            "
          >
            <el-button
              size="small"
              type="danger"
              slot="reference"
              class="ml-10"
            >
              <i class="el-icon-delete" />
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :meta="administrators.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
import Search from '@/components/Form/Search.vue'

export default {
  components: {
    Paginator,
    Search,
  },
  mixins: [pagination('getAdministrators')],
  computed: {
    ...mapState('administrator', [
      'administrators',
      'getAdministratorsLoading',
    ]),
  },
  mounted() {
    this.getAdministrators()
  },
  methods: {
    ...mapActions('administrator', [
      'getAdministrators',
      'deleteAdministrator',
    ]),
    async confirmDelete(id) {
      try {
        await this.deleteAdministrator({
          routeParameters: {
            id,
          },
        })
        this.search(true)
        this.$notify({
          title: 'Success',
          message: 'Deletion succeed',
          type: 'success',
        })
      } catch (e) {
        this.$notify({
          title: 'Failed',
          message: 'Deletion Failed',
          type: 'danger',
        })
      }
    },
  },
}
</script>
