import { generateState } from '../builder'

export default {
  administrators: [],
  administrator: {},
  ...generateState('getAdministrators'),
  ...generateState('getAdministrator'),
  ...generateState('saveAdministrator'),
  ...generateState('deleteAdministrator'),
}
