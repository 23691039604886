<template>
  <div>
    <div
      class="form-items"
      v-for="(character, index) in value"
      :key="character.id ? character.id : character.key"
    >
      <character-item
        :errors="errors"
        v-model="value[index]"
        :index="index"
        @remove="() => removeCharacter(index)"
        :disabled="disabled"
        :default-selected-pane="defaultSelectedPane"
      />
    </div>
    <div class="form-items" v-if="!disabled">
      <el-button type="primary" @click="addCharacter"
        >Add a character</el-button
      >
    </div>
  </div>
</template>

<script>
import CharacterItem from '@/views/ReadingSheets/CharacterItem.vue'
import { initMultilingual } from '@/services/helpers'

export default {
  name: 'CharacterList',
  components: { CharacterItem },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultSelectedPane: {
      type: String,
      default: () => 'en',
    },
  },
  emits: ['input'],
  methods: {
    addCharacter() {
      const copy = this.value.concat({
        id: null,
        key: `temp-${new Date().getTime()}`,
        name: '',
        functions: null,
        gender: null,
        ages: null,
        country: null,
        topology: null,
        characterisations: initMultilingual(),
        issues: initMultilingual(),
        evolutions: initMultilingual(),
      })
      this.$emit('input', copy)
    },
    removeCharacter(index) {
      const copy = [...this.value].filter((_item, i) => i !== index)
      this.$emit('input', copy)
    },
  },
}
</script>
