<template>
  <div class="dashboard-wrapper" v-loading.fullscreen.lock="isPageLoading">
    <div class="dashboard-main-block">
      <div class="dashboard-header">
        <h2 class="primary-color">Dashboard</h2>
      </div>

      <popular-books v-if="popularBooks.length > 0" :items="popularBooks" />
      <recent-searches
        v-if="recentSearches.length > 0"
        :items="recentSearches"
      />
    </div>
    <div class="dashboard-right-block">
      <el-collapse accordion>
        <recent-users :items="recentUsers" />
        <recent-criteria :items="recentCriteria" />
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RecentUsers from './RecentUsers.vue'
import RecentCriteria from './RecentCriteria.vue'
import PopularBooks from './PopularBooks.vue'
import RecentSearches from './RecentSearches.vue'

export default {
  name: 'Dashboard',
  components: {
    RecentUsers,
    RecentCriteria,
    PopularBooks,
    RecentSearches,
  },
  created() {
    this.initData()
  },
  computed: {
    ...mapState('dashboard', ['dashboardData', 'getDashboardDataLoading']),
    isPageLoading() {
      return this.getDashboardDataLoading
    },
    recentUsers() {
      return this.dashboardData.recentUsers
    },
    recentCriteria() {
      return this.dashboardData.recentCriteria
    },
    popularBooks() {
      return this.dashboardData.popularBooks
    },
    recentSearches() {
      return this.dashboardData.recentSearches
    },
  },
  methods: {
    ...mapActions('dashboard', ['getDashboardData']),
    initData() {
      this.getDashboardData()
    },
  },
}
</script>
