import Login from '@/views/Auth/Login'

export const authRoutes = [
  {
    path: '/auth/login',
    name: 'auth-login',
    component: Login,
    meta: { layout: 'empty', guest: true },
  },
]
