import { generateAction } from '../builder'

import router from '@/router'

const getAuthors = generateAction('getAuthors', 'admin/authors')
const getAuthor = generateAction('getAuthor', 'admin/authors/{id}')
const saveAuthor = generateAction(
  'saveAuthor',
  'admin/authors/{id}',
  'POST',
  data => {
    if (!data.inModal) {
      return router.push({
        name: 'author-index',
      })
    }
  }
)
const deleteAuthor = generateAction(
  'deleteAuthor',
  'admin/authors/{id}',
  'DELETE'
)
const getAuthorTypes = generateAction(
  'getAuthorTypes',
  'admin/utilities/author-types'
)

export default {
  getAuthors,
  saveAuthor,
  getAuthor,
  deleteAuthor,
  getAuthorTypes,
}
