import { generateAction } from '../builder'

const getCharacterTopologies = generateAction(
  'getCharacterTopologies',
  'admin/character-topologies'
)

export default {
  getCharacterTopologies,
}
