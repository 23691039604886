import { generateAction } from '../builder'

import router from '@/router'

const getPeriods = generateAction('getPeriods', 'admin/periods')
const getPeriod = generateAction('getPeriod', 'admin/periods/{id}')
const savePeriod = generateAction(
  'savePeriod',
  'admin/periods/{id}',
  'POST',
  () => {
    return router.push({
      name: 'period-index',
    })
  }
)
const deletePeriod = generateAction(
  'deletePeriod',
  'admin/periods/{id}',
  'DELETE'
)

export default {
  getPeriods,
  savePeriod,
  getPeriod,
  deletePeriod,
}
