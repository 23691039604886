import List from '@/views/Genre/List.vue'
import Save from '@/views/Genre/Save.vue'

export const genreRoutes = [
  {
    path: '/genres',
    name: 'genre-index',
    component: List,
  },
  {
    path: '/genre/:id?',
    name: 'genre-save',
    component: Save,
    meta: { menuIndex: '/genres' },
  },
]
