import List from '@/views/Company/List.vue'
import Save from '@/views/Company/Save.vue'

export const companyRoutes = [
  {
    path: '/companies',
    name: 'company-index',
    component: List,
  },
  {
    path: '/company/:id?',
    name: 'company-save',
    component: Save,
    meta: { menuIndex: '/companies' },
  },
]
