<template>
  <el-collapse-item class="dashboard-right-item" :name="accordeonTabName">
    <template slot="title" class="item-title-block">
      <slot name="title" />
    </template>

    <div class="item-content-block">
      <slot name="content" />
    </div>
  </el-collapse-item>
</template>

<script>
export default {
  name: 'DashboardRightItem',
  props: {
    accordeonTabName: {
      type: String,
      required: true,
    },
  },
}
</script>
