import List from '@/views/Time/List.vue'
import Save from '@/views/Time/Save.vue'

export const timeRoutes = [
  {
    path: '/times',
    name: 'time-index',
    component: List,
  },
  {
    path: '/time/:id?',
    name: 'time-save',
    component: Save,
    meta: { menuIndex: '/times' },
  },
]
