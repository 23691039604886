import { generateAction } from '../builder'

const getAdaptationFormats = generateAction(
  'getAdaptationFormats',
  'admin/adaptation-formats'
)

export default {
  getAdaptationFormats,
}
