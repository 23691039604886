var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"book-table",attrs:{"data":_vm.data},on:{"row-click":_vm.onRowClick}},[_c('el-table-column',{attrs:{"width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"status all-align"},[_c('el-popover',{attrs:{"placement":"top","width":"180","trigger":"hover","popper-class":"status-popup"}},[_c('div',[_vm._v(" STATUS: "),_c('span',{class:[
                'bold',
                {
                  red: scope.row.status.id === 1,
                  orange: scope.row.status.id === 2,
                  grey: scope.row.status.id === 3,
                  green: scope.row.status.id === 4,
                },
              ]},[_vm._v(_vm._s(scope.row.status.label.toUpperCase()))])]),_c('div',{class:[
              'status-icon success',
              {
                red: scope.row.status.id === 1,
                orange: scope.row.status.id === 2,
                grey: scope.row.status.id === 3,
                green: scope.row.status.id === 4,
              },
            ],attrs:{"slot":"reference"},slot:"reference"})])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Title / Author / Pitch","min-width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('reading-list-item',{attrs:{"item":{
          id: scope.row.id,
          name: scope.row.original_title,
          authors: scope.row.authorList,
          description: _vm.getPitch(scope.row.pitches),
        }}})]}}])}),_c('el-table-column',{attrs:{"label":"State of rights / Rights update date","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"bold"},[_vm._v(" "+_vm._s(scope.row.rights_status)+" "+_vm._s(scope.row.rights_status && scope.row.rights_status_updated_at ? ' / ' : '')+" "+_vm._s(scope.row.rights_status_updated_at)+" ")])]}}])}),_c('el-table-column',{attrs:{"class-name":"actions-column","min-width":_vm.actionsColumnWidth},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._t("actions-column",function(){return [_c('i',{staticClass:"icon ion-ios-arrow-forward"})]},{"row":scope.row})]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }