<template>
  <div class="save">
    <save-page-header
      title="AI COMPARATION"
      :show-language-select="false"
    ></save-page-header>
    <el-form
      label-position="top"
      class="m-25"
      style="position: relative"
      @submit.prevent.stop=""
      v-loading="isLoading"
    >
      <p class="bold subtitle">BASE</p>
      <div class="ruler"></div>

      <ai-comparation-group>
        <el-form-item label="Author" :error="errors['author']">
          <ai-comparation-item
            icon="el-icon-search"
            @transfer="$refs.author.setAuthorSearch(form.aiBook.author)"
          >
            <authors-selection
              :error="errors['author_list']"
              v-model="form.book.author_list"
              ref="author"
            ></authors-selection>
            <template #result>
              {{ form.aiBook.author }}
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item
          label="Original country"
          :error="errors['original_country_id']"
        >
          <ai-comparation-item
            @transfer="
              form.book.original_country_id = form.aiBook.original_country_id
            "
          >
            <div>
              <el-select
                v-model="form.book.original_country_id"
                filterable
                autocomplete="on"
                @change="filterCountries"
                :filter-method="filterCountries"
              >
                <el-option
                  v-for="country in countries.data"
                  :key="'book_country_' + country.id"
                  :label="country.name"
                  :value="country.id"
                ></el-option>
              </el-select>
            </div>

            <template #result>
              <el-select v-model="form.aiBook.original_country_id" disabled>
                <el-option
                  v-for="country in countries.data"
                  :key="'ai_book_country' + country.id"
                  :label="country.name"
                  :value="country.id"
                ></el-option>
              </el-select>
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Original language" :error="errors['language_id']">
          <ai-comparation-item
            @transfer="form.book.language_id = form.aiBook.language_id"
          >
            <el-select v-model="form.book.language_id" filterable>
              <el-option
                v-for="language in languages.data"
                :key="'book_language_' + language.id"
                :label="language.name"
                :value="language.id"
              ></el-option>
            </el-select>

            <template #result>
              <el-select v-model="form.aiBook.language_id" disabled>
                <el-option
                  v-for="language in languages.data"
                  :key="'ai_book_language_' + language.id"
                  :label="language.name"
                  :value="language.id"
                ></el-option>
              </el-select>
            </template>
          </ai-comparation-item>
        </el-form-item>
      </ai-comparation-group>

      <p class="bold subtitle">PRESENTATION</p>
      <div class="ruler"></div>

      <ai-comparation-group>
        <el-form-item label="Pitch">
          <ai-comparation-item
            @transfer="
              form.book.pitches[form.aiBook.locale] = form.aiBook.pitch
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.pitches"
              :default-selected-pane="form.aiBook.locale"
              sourceKey="book.pitches"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              placeholder="Pitch"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.pitch"
              >
              </translatable-input-compare>
            </template>
          </ai-comparation-item>
        </el-form-item>

        <el-form-item label="Times" :error="errors['time_id']">
          <ai-comparation-item
            @transfer="form.book.time_id = form.aiBook.time_id"
          >
            <el-select v-model="form.book.time_id">
              <el-option
                v-for="time in times.data"
                :key="'book_time_' + time.id"
                :label="time.name"
                :value="time.id"
              ></el-option>
            </el-select>

            <template #result>
              <el-select v-model="form.aiBook.time_id" disabled>
                <el-option
                  v-for="time in times.data"
                  :key="'ai_book_time' + time.id"
                  :label="time.name"
                  :value="time.id"
                ></el-option>
              </el-select>
            </template>
          </ai-comparation-item>
        </el-form-item>

        <el-form-item label="Reporter summary">
          <ai-comparation-item
            @transfer="
              form.book.newsletter_comments[form.aiBook.locale] =
                form.aiBook.newsletter_comment
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.newsletter_comments"
              sourceKey="book.newsletter_comments"
              :label-width="labelWidth"
              :default-selected-pane="form.aiBook.locale"
              inputType="textarea"
              :rows="9"
              placeholder="Reporter summary"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.newsletter_comment"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>

        <el-form-item label="Places" :error="errors['places']">
          <ai-comparation-item
            @transfer="form.book.places = form.aiBook.places"
          >
            <el-select
              v-model="form.book.places"
              value-key="id"
              filterable
              multiple
              :filter-method="filterCountries"
            >
              <el-option
                v-for="country in countries.data"
                :key="'book_place' + country.id"
                :label="country.name"
                :value="country.id"
              ></el-option>
            </el-select>

            <template #result>
              <el-select
                v-model="form.aiBook.places"
                value-key="id"
                multiple
                disabled
              >
                <el-option
                  v-for="country in countries.data"
                  :key="'ai_book_place' + country.id"
                  :label="country.name"
                  :value="country.id"
                ></el-option>
              </el-select>
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Complement places">
          <ai-comparation-item
            @transfer="
              form.book.additional_places[form.aiBook.locale] =
                form.aiBook.additional_place
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.additional_places"
              sourceKey="book.additional_places"
              :default-selected-pane="form.aiBook.locale"
              :label-width="labelWidth"
              placeholder="Enter the complement places"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.additional_place"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>

        <el-form-item label="Periods" :error="errors['periods']">
          <ai-comparation-item
            @transfer="form.book.periods = form.aiBook.periods"
          >
            <el-select
              v-model="form.book.periods"
              filterable
              multiple
              remote
              :remote-method="filterPeriods"
            >
              <el-option
                v-for="period in periods.data"
                :key="'book_period_' + period.id"
                :label="period.name"
                :value="period.id"
              ></el-option>
            </el-select>

            <template #result>
              <el-select
                v-model="form.aiBook.periods"
                value-key="id"
                multiple
                disabled
              >
                <el-option
                  v-for="period in periods.data"
                  :key="'ai_book_period_' + period.id"
                  :label="period.name"
                  :value="period.id"
                ></el-option>
              </el-select>
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Complement periods">
          <ai-comparation-item
            @transfer="
              form.book.additional_periods[form.aiBook.locale] =
                form.aiBook.additional_period
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.additional_periods"
              sourceKey="book.arenas"
              :label-width="labelWidth"
              :default-selected-pane="form.aiBook.locale"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the arena"
            />
            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.additional_period"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
      </ai-comparation-group>

      <p class="bold subtitle">CRITERIAS</p>
      <div class="ruler"></div>

      <ai-comparation-group>
        <el-form-item label="Genres" :error="errors['genres']">
          <ai-comparation-item
            @transfer="
              () => {
                form.book.genres = form.aiBook.genres
                form.book.primary_genre_id = form.aiBook.primary_genre_id
              }
            "
          >
            <div>
              <div>
                <b>List</b>
              </div>
              <criteria-tree
                :source-tree="getTree(genres.data)"
                :selected-items="form.book.genres"
                @change="value => handleTreeChange('genres', value)"
              />

              <div v-if="genreList.length">
                <div class="mt-10">
                  <b>Primary</b>
                </div>
                <el-radio-group v-model="form.book.primary_genre_id">
                  <el-radio
                    class="primary-genre-radio"
                    v-for="genre in genreList"
                    :key="'book_primary_genre' + genre.id"
                    :label="genre.id"
                    >{{ genre.name }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>

            <template #result>
              <div>
                <div>
                  <b>List</b>
                </div>
                <criteria-tree
                  :source-tree="getTree(genres.data)"
                  :selected-items="form.aiBook.genres"
                  disabled
                />
                <div v-if="aiGenreList.length">
                  <div class="mt-10">
                    <b>Primary</b>
                  </div>
                  <el-radio-group v-model="form.aiBook.primary_genre_id">
                    <el-radio
                      class="primary-genre-radio"
                      v-for="genre in aiGenreList"
                      :key="'ai_book_primary_genre_' + genre.id"
                      :label="genre.id"
                      disabled
                      >{{ genre.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
            </template>
            <template
              #justification
              v-if="form.aiBook.genres_justifications.length"
            >
              <div
                v-for="item in form.aiBook.genres_justifications"
                :key="item.label"
              >
                <span class="bold">{{ item.label }} : </span>
                <span>{{ item.justification }}</span>
              </div>
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Thematics" :error="errors['thematics']">
          <ai-comparation-item
            @transfer="form.book.thematics = form.aiBook.thematics"
          >
            <criteria-tree
              :source-tree="getTree(thematics.data)"
              :selected-items="form.book.thematics"
              @change="value => handleTreeChange('thematics', value)"
            />

            <template #result>
              <criteria-tree
                :source-tree="getTree(thematics.data)"
                :selected-items="form.aiBook.thematics"
                disabled
              />
            </template>
            <template #justification>
              <div
                v-for="item in form.aiBook.thematics_justifications"
                :key="item.label"
              >
                <span class="bold">{{ item.label }} : </span>
                <span>{{ item.justification }}</span>
              </div>
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Categories" :error="errors['categories']">
          <ai-comparation-item
            @transfer="form.book.categories = form.aiBook.categories"
          >
            <el-select
              v-model="form.book.categories"
              placeholder="Categories..."
              value-key="id"
              auto-complete="on"
              multiple
              filterable
              :filter-method="filterCategories"
              clearable
            >
              <el-option
                v-for="item in categories.data"
                :key="'book_category_' + item.id"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>

            <template #result>
              <el-select
                v-model="form.aiBook.categories"
                placeholder="Categories..."
                value-key="id"
                multiple
                disabled
              >
                <el-option
                  v-for="item in categories.data"
                  :key="'ai_book_category' + item.id"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </template>
          </ai-comparation-item>
        </el-form-item>
      </ai-comparation-group>

      <p class="bold subtitle">CHARACTERS</p>
      <div class="ruler"></div>
      <ai-comparation-group>
        <ai-comparation-item @transfer="handleCharactersTransfer">
          <character-list
            v-model="form.book.characters"
            :errors="errors"
            :default-selected-pane="form.aiBook.locale"
          ></character-list>

          <template #result>
            <character-list
              v-model="form.aiBook.characters"
              :errors="errors"
              :default-selected-pane="form.aiBook.locale"
              disabled
            ></character-list>
          </template>
        </ai-comparation-item>
      </ai-comparation-group>

      <p class="bold subtitle">RIGHTS</p>
      <div class="ruler"></div>

      <ai-comparation-group>
        <el-form-item
          label="First publishing house"
          :error="errors['company_id']"
        >
          <ai-comparation-item
            @transfer="
              handleInputChange(
                'company',
                form.aiBook.publishing_house_name,
                'filterCompanies'
              )
            "
            icon="el-icon-search"
          >
            <el-select
              v-model="form.book.company_id"
              placeholder="Select a company..."
              ref="company"
              filterable
              autocomplete="on"
              :filter-method="filterCompanies"
            >
              <el-option
                v-for="company in companies.data"
                :key="company.id"
                :label="company.name"
                :value="company.id"
              ></el-option>
            </el-select>

            <template #result>
              {{ form.aiBook.publishing_house_name }}
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item
          label="Publication Date"
          :error="errors['publication_date']"
        >
          <ai-comparation-item
            @transfer="
              form.book.publication_date = form.aiBook.publication_date
            "
          >
            <el-date-picker
              v-model="form.book.publication_date"
              type="month"
              value-format="yyyy-MM"
              placeholder="Pick a month"
              class="full-width"
            ></el-date-picker>

            <template #result>
              <el-date-picker
                v-model="form.aiBook.publication_date"
                type="month"
                value-format="yyyy-MM"
                placeholder="Pick a month"
                class="full-width"
                disabled
              ></el-date-picker>
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Format" :error="errors['format_id']">
          <ai-comparation-item
            @transfer="form.book.format_id = form.aiBook.format_id"
          >
            <el-select v-model="form.book.format_id" placeholder="Format...">
              <el-option
                v-for="format in formats.data"
                :key="'book_format_' + format.id"
                :label="format.name"
                :value="format.id"
              ></el-option>
            </el-select>

            <template #result>
              <el-select v-model="form.aiBook.format_id" disabled>
                <el-option
                  v-for="format in formats.data"
                  :key="'ai_book_format_' + format.id"
                  :label="format.name"
                  :value="format.id"
                ></el-option>
              </el-select>
            </template>
          </ai-comparation-item>
        </el-form-item>

        <el-form-item label="Number of page" :error="errors['nb_pages']">
          <ai-comparation-item
            @transfer="form.book.nb_pages = form.aiBook.nb_pages"
          >
            <el-input placeholder="pages..." v-model="form.book.nb_pages">
              <template #append>pages</template>
            </el-input>

            <template #result>
              <el-input
                placeholder="pages..."
                v-model="form.aiBook.nb_pages"
                disabled
              >
                <template #append>pages</template>
              </el-input>
            </template>
          </ai-comparation-item>
        </el-form-item>
      </ai-comparation-group>

      <p class="bold subtitle">ADAPTATION FILE</p>
      <div class="ruler"></div>

      <ai-comparation-group>
        <el-form-item label="Arena">
          <ai-comparation-item
            @transfer="form.book.arenas[form.aiBook.locale] = form.aiBook.arena"
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.arenas"
              sourceKey="book.arenas"
              :label-width="labelWidth"
              :default-selected-pane="form.aiBook.locale"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the arena"
            />
            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.arena"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Message">
          <ai-comparation-item
            @transfer="
              form.book.messages[form.aiBook.locale] = form.aiBook.message
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.messages"
              sourceKey="book.messages"
              :label-width="labelWidth"
              :default-selected-pane="form.aiBook.locale"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the message"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.message"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Promise">
          <ai-comparation-item
            @transfer="
              form.book.promises[form.aiBook.locale] = form.aiBook.promise
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.promises"
              sourceKey="book.promises"
              :label-width="labelWidth"
              :default-selected-pane="form.aiBook.locale"
              inputType="textarea"
              :rows="9"
              placeholder="Enter the promise"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.promise"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Point of view">
          <ai-comparation-item
            @transfer="
              form.book.point_of_views[form.aiBook.locale] =
                form.aiBook.point_of_view
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.point_of_views"
              sourceKey="book.point_of_views"
              :label-width="labelWidth"
              inputType="textarea"
              :default-selected-pane="form.aiBook.locale"
              :rows="9"
              placeholder="Enter the point of view"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.point_of_view"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Narrative structure">
          <ai-comparation-item
            @transfer="
              form.book.narrative_structures[form.aiBook.locale] =
                form.aiBook.narrative_structure
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.narrative_structures"
              sourceKey="book.narrative_structures"
              :label-width="labelWidth"
              inputType="textarea"
              :default-selected-pane="form.aiBook.locale"
              :rows="9"
              placeholder="Enter the narrative structure"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.narrative_structure"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Strong points">
          <ai-comparation-item
            @transfer="
              form.book.strong_points[form.aiBook.locale] =
                form.aiBook.strong_point
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.strong_points"
              sourceKey="book.strong_points"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              :default-selected-pane="form.aiBook.locale"
              placeholder="Enter the strong points"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.strong_point"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Weak points">
          <ai-comparation-item
            @transfer="
              form.book.weak_points[form.aiBook.locale] = form.aiBook.weak_point
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.weak_points"
              sourceKey="book.weak_points"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              :default-selected-pane="form.aiBook.locale"
              placeholder="Enter the weak points"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.weak_point"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Book references">
          <ai-comparation-item
            @transfer="
              form.book.book_references[form.aiBook.locale] =
                form.aiBook.book_reference
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.book_references"
              sourceKey="book.book_references"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              :default-selected-pane="form.aiBook.locale"
              placeholder="Enter the book references"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.book_reference"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Movie references">
          <ai-comparation-item
            @transfer="
              form.book.movie_references[form.aiBook.locale] =
                form.aiBook.movie_reference
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.movie_references"
              sourceKey="book.movie_references"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              :default-selected-pane="form.aiBook.locale"
              placeholder="Enter the movie references"
            />
            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.movie_reference"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item label="Detailed summaries">
          <ai-comparation-item
            @transfer="
              form.book.detailed_summaries[form.aiBook.locale] =
                form.aiBook.detailed_summary
            "
          >
            <translatable-input
              :errors="errors"
              :source.sync="form.book.detailed_summaries"
              sourceKey="book.detailed_summaries"
              :label-width="labelWidth"
              inputType="textarea"
              :rows="9"
              :default-selected-pane="form.aiBook.locale"
              placeholder="Detailed summaries"
            />

            <template #result>
              <translatable-input-compare
                :locale="form.aiBook.locale"
                :content="form.aiBook.detailed_summary"
              />
            </template>
          </ai-comparation-item>
        </el-form-item>

        <el-form-item
          label="Adaptation formats"
          :errors="errors['adaptation_formats']"
        >
          <ai-comparation-item
            @transfer="
              form.book.adaptation_formats = form.aiBook.adaptation_formats
            "
          >
            <el-select
              v-model="form.book.adaptation_formats"
              placeholder="Select the adaptation format"
              value-key="id"
              multiple
              filterable
              :filter-method="getAdaptationFormats"
              clearable
            >
              <el-option
                v-for="adaptation_format in adaptationFormats.data"
                :key="'book_adaptation_format_' + adaptation_format.id"
                :label="adaptation_format.label"
                :value="adaptation_format.id"
              ></el-option>
            </el-select>

            <template #result>
              <el-select
                v-model="form.aiBook.adaptation_formats"
                placeholder="Select the adaptation format"
                value-key="id"
                multiple
                disabled
              >
                <el-option
                  v-for="adaptation_format in adaptationFormats.data"
                  :key="'ai_book_adaptation_format' + adaptation_format.id"
                  :label="adaptation_format.label"
                  :value="adaptation_format.id"
                ></el-option>
              </el-select>
            </template>

            <template #justification>
              <div
                v-for="item in form.aiBook.adaptation_formats_justifications"
                :key="item.label"
              >
                <span class="bold">{{ item.label }} : </span>
                <span>{{ item.justification }}</span>
              </div>
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item
          label="Adaptation kinds"
          :errors="errors['adaptation_kinds']"
        >
          <ai-comparation-item
            @transfer="
              form.book.adaptation_kinds = form.aiBook.adaptation_kinds
            "
          >
            <el-select
              v-model="form.book.adaptation_kinds"
              multiple
              filterable
              value-key="id"
            >
              <el-option
                v-for="adaptation_kind in adaptationKinds.data"
                :key="'book_adaptation_kind_' + adaptation_kind.id"
                :label="adaptation_kind.label"
                :value="adaptation_kind.id"
              ></el-option>
            </el-select>

            <template #result>
              <el-select
                v-model="form.aiBook.adaptation_kinds"
                value-key="id"
                multiple
                disabled
              >
                <el-option
                  v-for="adaptation_kind in adaptationKinds.data"
                  :key="'ai_book_adaptation_kind_' + adaptation_kind.id"
                  :label="adaptation_kind.label"
                  :value="adaptation_kind.id"
                ></el-option>
              </el-select>
            </template>

            <template #justification>
              <div
                v-for="item in form.aiBook.adaptation_kinds_justifications"
                :key="item.label"
              >
                <span class="bold">{{ item.label }} : </span>
                <span>{{ item.justification }}</span>
              </div>
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item
          label="Adaptation budget"
          :errors="errors['adaptation_budget_id']"
        >
          <ai-comparation-item
            @transfer="
              form.book.adaptation_budget_id = form.aiBook.adaptation_budget_id
            "
          >
            <el-select
              v-model="form.book.adaptation_budget_id"
              value-key="id"
              placeholder="Adaptation budget..."
              autocomplete="on"
              clearable
            >
              <el-option
                v-for="item in adaptationBudgets.data"
                :key="'book_adaptation_budget_' + item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>

            <template #result>
              <el-select
                v-model="form.aiBook.adaptation_budget_id"
                disabled
                value-key="id"
              >
                <el-option
                  v-for="item in adaptationBudgets.data"
                  :key="'ai_book_adaptation_budget_' + item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>
          </ai-comparation-item>
        </el-form-item>
        <el-form-item
          label="Literary Prizes"
          :errors="errors['literary_prizes']"
        >
          <ai-comparation-item
            @transfer="form.book.literary_prizes = form.aiBook.literary_prizes"
          >
            <el-select v-model="form.book.literary_prizes" multiple filterable>
              <el-option
                v-for="literary_prize in literaryPrizes.data"
                :key="'book_literary_prize' + literary_prize.id"
                :label="literary_prize.label"
                :value="literary_prize.id"
              ></el-option>
            </el-select>

            <template #result>
              <el-select
                v-model="form.aiBook.literary_prizes"
                multiple
                disabled
              >
                <el-option
                  v-for="literary_prize in literaryPrizes.data"
                  :key="'ai_book_literary_prize' + literary_prize.id"
                  :label="literary_prize.label"
                  :value="literary_prize.id"
                ></el-option>
              </el-select>
            </template>
          </ai-comparation-item>
        </el-form-item>
      </ai-comparation-group>

      <p class="bold subtitle">RATINGS</p>
      <div class="ruler"></div>

      <ai-comparation-group>
        <template v-for="(name, key) in ratingTypes">
          <el-form-item
            :label="name"
            :error="errors['ratings.' + key]"
            :key="key"
          >
            <ai-comparation-item
              :key="'rating-type-title-' + key"
              @transfer="form.book.ratings = form.aiBook.ratings"
            >
              <div class="text-center">
                <el-rate v-model="form.book.ratings[key]" :max="4"></el-rate>
                <span>{{ form.book.ratings[key] || 0 }} / 4</span>
              </div>
              <template #result>
                <div class="text-center">
                  <el-rate
                    v-model="form.aiBook.ratings[key]"
                    :max="4"
                    disabled
                  ></el-rate>
                  <span>{{ form.aiBook.ratings[key] || 0 }} / 4</span>
                </div>
              </template>

              <template
                #justification
                v-if="form.aiBook.ratings_justifications[key]"
              >
                {{ form.aiBook.ratings_justifications[key] }}
              </template>
            </ai-comparation-item>
          </el-form-item>
        </template>
      </ai-comparation-group>
      <div class="align-right fixed-save">
        <el-button
          type="primary"
          plain
          class="primary"
          :loading="postUpdateBookLoading"
          @click="submit"
          >SAVE THE CHANGES</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
// Components
import AiComparationItem from '@/views/ReadingSheets/AiComparation/AiComparationItem.vue'
import AiComparationGroup from '@/views/ReadingSheets/AiComparation/AiComparationGroup.vue'
import TranslatableInput from '@/components/Form/TranslatableInput.vue'
import CriteriaTree from '@/views/ReadingSheets/CriteriaTree.vue'
import TranslatableInputCompare from '@/views/ReadingSheets/AiComparation/TranslatableInputCompare.vue'
import CharacterList from '@/views/ReadingSheets/CharacterList.vue'

import { mapActions, mapState } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import { initMultilingual } from '@/services/helpers'
import SavePageHeader from '@/components/ui/SavePageHeader.vue'
import { error } from '@/services/api'
import AuthorsSelection from '@/views/ReadingSheets/AuthorsSelection.vue'

export default {
  components: {
    AuthorsSelection,
    AiComparationGroup,
    SavePageHeader,
    TranslatableInputCompare,
    CriteriaTree,
    TranslatableInput,
    AiComparationItem,
    CharacterList,
  },
  mixins: [apiError('postUpdateBookError'), labelWidth],
  data() {
    return {
      loading: true,
      ratingTypes: {
        quality: 'Literary quality',
        concept: 'Concept',
        plot: 'Plot',
        characters: 'Characters',
        coherence: 'Coherence',
        emotions: 'Emotions',
        dialogues: 'Dialogues',
      },
      form: {
        book: {
          original_country_id: null,
          language_id: null,
          format_id: null,
          time_id: null,
          adaptation_budget_id: null,
          nb_pages: null,
          pitches: initMultilingual(),
          newsletter_comments: initMultilingual(),
          places: [],
          additional_places: initMultilingual(),
          arenas: initMultilingual(),
          messages: initMultilingual(),
          promises: initMultilingual(),
          point_of_views: initMultilingual(),
          narrative_structures: initMultilingual(),
          key_moments: initMultilingual(),
          strong_points: initMultilingual(),
          weak_points: initMultilingual(),
          book_references: initMultilingual(),
          movie_references: initMultilingual(),
          detailed_summaries: initMultilingual(),
          periods: [],
          additional_periods: initMultilingual(),
          genres: [],
          primary_genre_id: null,
          thematics: [],
          adaptation_formats: [],
          adaptation_kinds: [],
          literary_prizes: [],
          ratings: {
            coherence: null,
            concept: null,
            plot: null,
            characters: null,
            dialogues: null,
            emotions: null,
            quality: null,
          },
          characters: [],
          author_list: [],
          publication_date: [],
          company_id: null,
          company: null,
          categories: [],
        },
        aiBook: {
          original_country_id: null,
          language_id: null,
          format_id: null,
          time_id: null,
          adaptation_budget_id: null,
          nb_pages: null,
          pitch: null,
          newsletter_comment: null,
          places: [],
          additional_place: null,
          arena: null,
          message: null,
          promise: null,
          point_of_view: null,
          narrative_structure: null,
          key_moment: null,
          strong_point: null,
          weak_point: null,
          book_reference: null,
          movie_reference: null,
          detailed_summary: null,
          periods: [],
          additional_period: null,
          genres: [],
          genres_justifications: [],
          primary_genre_id: null,
          thematics: [],
          thematics_justifications: [],
          adaptation_formats: [],
          adaptation_formats_justifications: [],
          adaptation_kinds: [],
          adaptation_kinds_justifications: [],
          literary_prizes: [],
          ratings: {
            coherence: null,
            concept: null,
            plot: null,
            characters: null,
            dialogues: null,
            emotions: null,
            quality: null,
          },
          ratings_justifications: [],
          characters: [],
          locale: 'en',
          author: null,
          publication_date: null,
          publishing_house_name: null,
          categories: [],
        },
      },
      translatableFields: [
        'pitches',
        'newsletter_comments',
        'additional_places',
        'arenas',
        'messages',
        'promises',
        'point_of_views',
        'narrative_structures',
        'key_moments',
        'strong_points',
        'weak_points',
        'book_references',
        'movie_references',
        'detailed_summaries',
        'additional_periods',
      ],
    }
  },
  methods: {
    handleInputChange(refName, value, filterMethod = null) {
      this.$refs[refName].focus()
      this.$refs[refName].$emit('input', value)
      this.$refs[refName].$emit('change', value)

      if (filterMethod) {
        this[filterMethod](value)
      }
    },
    error,
    ...mapActions('country', ['getCountries']),
    ...mapActions('locale', ['getLanguages']),
    ...mapActions('format', ['getFormats']),
    ...mapActions('time', ['getTimes']),
    ...mapActions('adaptationBudget', ['getAdaptationBudgets']),
    ...mapActions('period', ['getPeriods']),
    ...mapActions('thematic', ['getThematics']),
    ...mapActions('category', ['getCategories']),
    ...mapActions('genre', ['getGenres']),
    ...mapActions('adaptationFormat', ['getAdaptationFormats']),
    ...mapActions('adaptationKind', ['getAdaptationKinds']),
    ...mapActions('literaryPrize', ['getLiteraryPrizes']),
    ...mapActions('aiBook', ['getAiBook', 'postUpdateBook']),
    ...mapActions('company', ['getCompanies']),
    filterCountries(search) {
      this.getCountries({ name: search, perPage: 500 })
    },
    filterPeriods(search) {
      this.getPeriods({ name: search })
    },
    filterCompanies(search) {
      this.getCompanies({ name: search })
    },
    filterCategories(search) {
      this.getCategories({ name: search })
    },
    filterThematics(search) {
      this.getThematics({ name: search })
    },
    filterGenres(search) {
      this.getGenres({ name: search })
    },
    getTree(data) {
      let tree = []
      if (data) {
        for (let i = 0, len = data.length; i < len; i++) {
          if (data[i].parent_id === undefined || data[i].parent_id === null) {
            let node = {
              id: data[i].id,
              name: data[i].name,
              children: data
                .filter(obj => obj.parent_id === data[i].id)
                .map(item => ({ id: item.id, name: item.name })),
            }
            tree.push(node)
          }
        }
      }
      return tree
    },
    handleTreeChange(itemName, value) {
      this.form.book[itemName] = value
      if (itemName === 'genres') {
        if (
          this.form.book.genres.indexOf(this.form.book.primary_genre_id) === -1
        ) {
          this.form.book.primary_genre_id = null
        }
      }
    },
    async initialize() {
      // Get ai book data in aiBook
      await this.getAiBook({
        routeParameters: {
          id: this.$route.params.id,
        },
      })

      // UPDATE FORM WITH THIS AIBOOK VALUES
      for (const [index, type] of Object.entries(this.form)) {
        for (const key of Object.keys(type)) {
          if (index === 'book' && this.translatableFields.includes(key)) {
            this.form[index][key] = initMultilingual(
              this.aiBook.data[index][key]
            )
          } else {
            if (key === 'ratings') {
              // Object keys to avoid reactivity problems with vuex store
              this.form[index][key] = { ...this.aiBook.data[index][key] }
            } else if (key === 'characters') {
              let newCharacters = []
              for (const characterIndex of Object.keys(
                this.aiBook.data[index][key]
              )) {
                // Object keys to avoid reactivity problems with vuex store
                newCharacters[characterIndex] = {
                  ...this.aiBook.data[index][key][characterIndex],
                  issues: initMultilingual(
                    this.aiBook.data[index][key][characterIndex].issues
                  ),
                  characterisations: initMultilingual(
                    this.aiBook.data[index][key][characterIndex]
                      .characterisations
                  ),
                  evolutions: initMultilingual(
                    this.aiBook.data[index][key][characterIndex].evolutions
                  ),
                }
              }
              // Change the whole key in one time for reactivity
              this.form[index][key] = newCharacters
            } else {
              this.form[index][key] = this.aiBook.data[index][key]
            }
          }
        }
      }

      // GET LISTS
      this.getLists()
    },
    async getLists() {
      await Promise.all([
        this.getCountries({
          name: this.form.book.country ? this.form.book.country.name : '',
          perPage: 500,
        }),
        this.getLanguages(),
        this.getFormats({ perPage: 500 }),
        this.getTimes({ perPage: 500 }),
        this.getAdaptationBudgets({ perPage: 10 }),
        this.getPeriods({ perPage: 500 }),
        this.getCategories({ perPage: 500 }),
        this.getGenres({ perPage: 500 }),
        this.getThematics({ perPage: 500 }),
        this.getAdaptationFormats({ perPage: 500 }),
        this.getAdaptationKinds({ perPage: 500 }),
        this.getLiteraryPrizes({ perPage: 500 }),
        this.getCompanies({
          name: this.form.book.company ? this.form.book.company.name : '',
          perPage: 100,
        }),
      ])

      this.loading = false
    },
    handleCharactersTransfer() {
      const aiCharacters = this.form.aiBook.characters

      for (const aiValue of Object.values(aiCharacters)) {
        const index = this.form.book.characters.findIndex(
          c => c.name === aiValue.name
        )
        if (index > -1) {
          // If the character is already in the book, we keep the other translations
          const character = this.form.book.characters[index]

          aiValue.characterisations = {
            ...character.characterisations,
            [this.form.aiBook.locale]:
              aiValue.characterisations[this.form.aiBook.locale],
          }
          aiValue.issues = {
            ...character.issues,
            [this.form.aiBook.locale]: aiValue.issues[this.form.aiBook.locale],
          }
          aiValue.evolutions = {
            ...character.evolutions,
            [this.form.aiBook.locale]:
              aiValue.evolutions[this.form.aiBook.locale],
          }
        }
      }

      this.form.book.characters = aiCharacters
    },
    dataInFormData(formData, data, path = '', isRoot = true) {
      for (const [key, value] of Object.entries(data)) {
        const newPath = isRoot ? key : path + '[' + key + ']'
        if (value && (typeof value === 'object' || Array.isArray(value))) {
          formData = this.dataInFormData(formData, value, newPath, false)
        } else {
          if (value) {
            // Manage single value
            formData.append(newPath, value)
          }
        }
      }
      return formData
    },
    async submit() {
      let formData = new FormData()

      // Note : There is no need to send AiBook data
      formData = this.dataInFormData(formData, this.form.book)

      await this.postUpdateBook({
        routeParameters: {
          id: this.$route.params.id,
        },
        formData,
      })
    },
  },
  mounted() {
    this.initialize()
  },
  computed: {
    ...mapState('aiBook', [
      'aiBook',
      'getAiBookLoading',
      'postUpdateBookLoading',
      'postUpdateBookError',
      'postUpdateBookSuccess',
    ]),
    ...mapState('country', ['countries', 'getCountriesLoading']),
    ...mapState('locale', ['languages', 'getLanguagesLoading']),
    ...mapState('format', ['formats', 'getFormatsLoading']),
    ...mapState('time', ['times', 'getTimesLoading']),
    ...mapState('adaptationBudget', [
      'adaptationBudgets',
      'getAdaptationBudgetsLoading',
    ]),
    ...mapState('period', ['periods', 'getPeriodsLoading']),
    ...mapState('thematic', ['thematics', 'getThematicsLoading']),
    ...mapState('category', ['categories', 'getCategoriesLoading']),
    ...mapState('genre', ['genres', 'getGenresLoading']),
    ...mapState('adaptationFormat', [
      'adaptationFormats',
      'getAdaptationFormatsLoading',
    ]),
    ...mapState('adaptationKind', [
      'adaptationKinds',
      'getAdaptationKindsLoading',
    ]),
    ...mapState('literaryPrize', [
      'literaryPrizes',
      'getLiteraryPrizesLoading',
    ]),
    ...mapState('company', ['companies', 'getCompaniesLoading']),
    genreList() {
      let list = []
      if (this.genres && this.genres.data) {
        list = this.genres.data.filter(item => {
          return (
            this.form.book.genres &&
            this.form.book.genres.indexOf(item.id) !== -1
          )
        })
      }
      return list
    },
    aiGenreList() {
      let list = []
      if (this.genres && this.genres.data) {
        list = this.genres.data.filter(item => {
          return (
            this.form.aiBook.genres &&
            this.form.aiBook.genres.indexOf(item.id) !== -1
          )
        })
      }
      return list
    },
    isLoading() {
      return this.loading || this.getAiBookLoading || this.postUpdateBookLoading
    },
  },
}
</script>
