import List from '@/views/Contact/List.vue'
import Save from '@/views/Contact/Save.vue'

export const contactRoutes = [
  {
    path: '/contacts',
    name: 'contact-index',
    component: List,
  },
  {
    path: '/contact/:id?',
    name: 'contact-save',
    component: Save,
    meta: { menuIndex: '/contacts' },
  },
]
