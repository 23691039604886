import { generateState } from '../builder'

export default {
  users: [],
  user: {},
  libraries: [],
  plans: [],
  userSearches: { data: [] },
  ...generateState('getUsers'),
  ...generateState('saveUser'),
  ...generateState('getUser'),
  ...generateState('deleteUser'),
  ...generateState('getLibraries'),
  ...generateState('validateUser'),
  ...generateState('getPlans'),
  ...generateState('cancelPlan'),
  ...generateState('addPlan'),
  ...generateState('manageCredits'),
  ...generateState('getUserSearches'),
}
