<template>
  <div class="list">
    <portal to="search">
      <search placeholder="Find a category..." v-model="globalFilter"></search>
    </portal>
    <div class="mb-50">
      <h2 class="title primary-color">CATEGORY LIST</h2>
    </div>
    <el-table :data="categories.data" v-loading="getCategoriesLoading">
      <el-table-column prop="id" label="#" width="75"> </el-table-column>
      <el-table-column prop="name" label="Name" min-width="250">
      </el-table-column>
      <el-table-column width="120">
        <template v-slot="scope">
          <router-link
            :to="{ name: 'category-view', params: { id: scope.row.id } }"
          >
            <el-button type="primary" size="small" class="plain-primary" plain>
              <i class="el-icon-view" />
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :meta="categories.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
import Search from '@/components/Form/Search.vue'

export default {
  components: {
    Paginator,
    Search,
  },
  mixins: [pagination('getCategories')],
  computed: {
    ...mapState('category', ['categories', 'getCategoriesLoading']),
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    ...mapActions('category', ['getCategories']),
  },
}
</script>
