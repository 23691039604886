import { generateAction } from '../builder'

const getAdaptationKinds = generateAction(
  'getAdaptationKinds',
  'admin/adaptation-kinds'
)

export default {
  getAdaptationKinds,
}
