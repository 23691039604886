import { generateMutations } from '../builder'

export default {
  ...generateMutations('getReporters', 'reporters'),
  ...generateMutations('saveReporter', 'reporter'),
  ...generateMutations('getReporter', 'reporter'),
  ...generateMutations('deleteReporter'),
  ...generateMutations('getReporterStatuses', 'statuses'),
  ...generateMutations('getReporterBooks', 'reporterBooksData'),
  ...generateMutations('getReporterUsers', 'reporterUsers'),
  ...generateMutations('getReporterUserRoles', 'reporterUserRoles'),
  ...generateMutations('deleteReporterBook'),
  setStateValue(state, { property, value }) {
    state[property] = value
  },
}
