import { generateAction } from '../builder'

const postAiBookGeneration = generateAction(
  'postAiBookGeneration',
  'admin/ai-books/ai-book-generation',
  'POST'
)

const getAiBook = generateAction('getAiBook', 'admin/ai-books/{id}', 'GET')

const postUpdateBook = generateAction(
  'postUpdateBook',
  'admin/ai-books/update-book/{id}',
  'POST'
)

export default {
  postAiBookGeneration,
  getAiBook,
  postUpdateBook,
}
