import { generateState } from '../builder'

export default {
  reporters: [],
  reporter: {},
  statuses: { data: [] },
  reporterBooksData: [],
  reporterUsers: { data: [] },
  reporterUserRoles: { data: [] },
  ...generateState('getReporters'),
  ...generateState('saveReporter'),
  ...generateState('getReporter'),
  ...generateState('deleteReporter'),
  ...generateState('getReporterStatuses'),
  ...generateState('getReporterBooks'),
  ...generateState('getReporterUsers'),
  ...generateState('getReporterUserRoles'),
  ...generateState('deleteReporterBook'),
}
