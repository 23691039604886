import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import audience from './audience'
import category from './category'
import common from './common'
import format from './format'
import genre from './genre'
import locale from './locale'
import time from './time'
import period from './period'
import thematic from './thematic'
import administrator from './administrator'
import country from './country'
import contact from './contact'
import company from './company'
import user from './user'
import reporter from './reporter'
import request from './request'
import author from './author'
import readingSheet from './readingSheet'
import news from './news'
import dashboard from './dashboard'
import literaryPrize from './literaryPrize'
import aiPrompt from './aiPrompt'
import adaptationFormat from './adaptationFormat'
import adaptationKind from './adaptationKind'
import adaptationBudget from './adaptationBudget'
import characterFunction from './characterFunction'
import characterGender from './characterGender'
import characterAge from './characterAge'
import characterTopology from './characterTopology'
import aiBook from './aiBook'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    administrator,
    audience,
    category,
    common,
    format,
    genre,
    locale,
    time,
    period,
    thematic,
    country,
    contact,
    company,
    user,
    reporter,
    request,
    author,
    readingSheet,
    news,
    dashboard,
    literaryPrize,
    aiPrompt,
    adaptationFormat,
    adaptationKind,
    adaptationBudget,
    characterFunction,
    characterGender,
    characterAge,
    characterTopology,
    aiBook,
  },
  strict: process.env.NODE_ENV !== 'production',
})
