<template>
  <el-row :gutter="20" class="pb-25">
    <el-col :span="10">
      <slot></slot>
    </el-col>
    <el-col :span="4" class="text-center">
      <el-button
        @click.prevent="$emit('transfer')"
        type="info"
        plain
        class="transfer-button"
      >
        <i :class="icon"></i>
      </el-button>
    </el-col>
    <el-col :span="10">
      <div>
        <slot name="result"> </slot>
      </div>
      <div v-if="$slots.justification">
        <el-alert
          class="mt-10"
          title="information"
          type="info"
          :closable="false"
        >
          <template #title>
            Justification(s)
          </template>
          <slot name="justification"></slot>
        </el-alert>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  emit: ['transfer'],
  props: {
    icon: {
      type: String,
      default: () => 'el-icon-back',
    }
  },
}
</script>

<style scoped>
.transfer-button {
  border-radius: 100%;
  height: 50px;
  width: 50px;
}
</style>
