<template>
  <div class="save">
    <save-page-header title="Company"></save-page-header>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native="submit"
    >
      <!-- Company name field -->
      <el-form-item label="Company name" prop="name" :error="errors['name']">
        <el-input v-model="form.name" placeholder="Company Name" />
      </el-form-item>
      <!-- Address field -->
      <el-form-item label="Address" prop="address" :error="errors['address']">
        <el-input v-model="form.address" placeholder="Address" />
      </el-form-item>
      <!-- Additional Address field -->
      <el-form-item
        label="Additional Address"
        prop="additional_address"
        :error="errors['additional_address']"
      >
        <el-input
          v-model="form.additional_address"
          placeholder="Additional Address"
        />
      </el-form-item>
      <!-- Postal Code field -->
      <el-form-item
        label="Postal Code"
        prop="postal_code"
        :error="errors['postal_code']"
      >
        <el-input v-model="form.postal_code" placeholder="Postal Code" />
      </el-form-item>
      <!-- City field -->
      <el-form-item label="City" prop="job" :error="errors['city']">
        <el-input v-model="form.city" placeholder="City" />
      </el-form-item>
      <!-- Country field -->
      <el-form-item label="Country" :error="errors['country_id']">
        <el-select
          v-model="form.country_id"
          filterable
          placeholder="Company"
          class="el-col-md-24"
        >
          <el-option
            v-for="country in countries.data"
            :key="country.id"
            :label="country.name"
            :value="country.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- Phone field -->
      <el-form-item label="Phone" prop="phone" :error="errors['phone']">
        <el-input v-model="form.phone" placeholder="Phone" />
      </el-form-item>
      <!-- Website field -->
      <el-form-item label="Website" prop="website" :error="errors['website']">
        <el-input v-model="form.website" placeholder="Website" />
      </el-form-item>
      <!-- Description field -->
      <el-form-item
        label="Description"
        prop="description"
        :error="errors['description']"
      >
        <el-input
          type="textarea"
          :rows="3"
          v-model="form.description"
          placeholder="Description"
        />
      </el-form-item>

      <div :class="[{ 'align-right': !inModal, 'align-modal': inModal }]">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveCompanyLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            {{ isUpdate ? 'Save Company' : 'Create Company' }}
          </el-button>
        </el-col>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import SavePageHeader from '@/components/ui/SavePageHeader'
export default {
  components: {
    SavePageHeader,
  },
  props: {
    inModal: {
      required: false,
      default: () => false,
    },
  },
  mixins: [apiError('saveCompanyError'), labelWidth],
  data: () => ({
    form: {},
  }),
  computed: {
    ...mapState('company', [
      'company',
      'saveCompanyError',
      'saveCompanyLoading',
    ]),
    ...mapState('country', ['countries']),
    isUpdate() {
      return this.$route.params.id !== undefined && !this.inModal
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getCompany({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.company.data,
      }
    }
    this.getCountries({ perPage: 1000 })
  },
  methods: {
    ...mapActions('company', ['saveCompany', 'getCompany']),
    ...mapActions('country', ['getCountries']),
    submit() {
      const addr = [
        this.form.address,
        this.form.additional_address,
        this.form.postal_code,
        this.form.city,
        this.form.country_id,
      ]
      this.saveCompany({
        routeParameters: {
          id: this.isUpdate ? this.$route.params.id : null,
        },
        ...this.form,
        has_address: addr.some(el => {
          return el !== null
        }),
        inModal: this.inModal,
      }).then(() => {
        if (this.saveCompanyError.status == null) {
          this.$emit('created', true)
        }
      })
    },
  },
}
</script>
