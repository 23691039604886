import { generateAction } from '../builder'

const getCharacterAges = generateAction(
  'getCharacterAges',
  'admin/character-ages'
)

export default {
  getCharacterAges,
}
