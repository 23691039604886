import { generateAction } from '../builder'

function setSelectedPane({ commit }, lang) {
  commit('updateSelectedPane', lang)
}
const getLanguages = generateAction('getLanguages', 'admin/utilities/languages')

const getLocales = generateAction('getLocales', 'admin/utilities/locales')

export default {
  setSelectedPane,
  getLanguages,
  getLocales,
}
