<template>
  <div class="list genres-list-page">
    <portal to="search">
      <search placeholder="Find a news..." v-model="globalFilter"></search>
    </portal>
    <div class="space-between mb-50">
      <h2 class="title primary-color">NEWS LIST</h2>
      <router-link :to="{ name: 'news-save' }" tag="span">
        <el-button type="primary" class="plain-primary" plain>
          <i class="el-icon-plus"></i>
          CREATE A NEW NEWS
        </el-button>
      </router-link>
    </div>
    <el-table :data="allNews.data" v-loading="getAllNewsLoading">
      <el-table-column prop="id" label="#" width="75"> </el-table-column>
      <el-table-column prop="title" label="News title" min-width="250">
      </el-table-column>
      <el-table-column
        prop="description"
        label="News description"
        min-width="250"
      >
        <template v-slot="scope">
          <span v-html="purifyDescription(scope.row.description)"></span>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="220">
        <template v-slot="scope">
          <router-link
            :to="{ name: 'news-save', params: { id: scope.row.id } }"
            tag="span"
            class="ml-10"
          >
            <el-button type="primary" size="small" class="plain-primary" plain>
              <i class="el-icon-edit" />
            </el-button>
          </router-link>
          <el-popconfirm
            confirmButtonText="OK"
            cancelButtonText="No, Thanks"
            icon="el-icon-info"
            iconColor="red"
            title="Are you sure to delete this?"
            @confirm="confirmDelete(scope.row.id)"
            @cancel="
              $notify({
                title: 'Cancel',
                message: 'Deletion cancelled',
                type: 'info',
              })
            "
          >
            <el-button
              size="small"
              type="danger"
              slot="reference"
              class="ml-10"
            >
              <i class="el-icon-delete" />
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :meta="allNews.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
import Search from '@/components/Form/Search.vue'
import marked from 'marked'
import DOMPurify from 'dompurify'

export default {
  components: {
    Paginator,
    Search,
  },
  mixins: [pagination('getAllNews')],
  computed: {
    ...mapState('news', ['allNews', 'getAllNewsLoading']),
  },
  mounted() {
    this.getAllNews()
  },
  methods: {
    ...mapActions('news', ['getAllNews', 'deleteNews']),
    async confirmDelete(id) {
      try {
        await this.deleteNews({
          routeParameters: {
            id,
          },
        })
        this.search(true)
        this.$notify({
          title: 'Success',
          message: 'Deletion succeed',
          type: 'success',
        })
      } catch (e) {
        this.$notify({
          title: 'Failed',
          message: 'Deletion Failed',
          type: 'danger',
        })
      }
    },
    purifyDescription(data) {
      let description = data ? DOMPurify.sanitize(marked(data)) : ''
      let regex = /(<([^>]+)>)/gi
      let content = description.replace(regex, '')
      description = content
        ? content.slice(0, 150) + (content.length > 150 ? '...' : '')
        : ''
      return description
    },
  },
}
</script>
