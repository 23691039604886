import ReporterList from '@/views/Reporter/List.vue'
import ReporterSave from '@/views/Reporter/Save.vue'

export const reporterRoutes = [
  {
    path: '/reporters',
    name: 'reporter-index',
    component: ReporterList,
  },
  {
    path: '/reporter/:id?',
    name: 'reporter-save',
    component: ReporterSave,
    meta: { menuIndex: '/reporters' },
  },
]
