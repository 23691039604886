import { generateAction } from '../builder'

const getRequests = generateAction('getRequests', 'admin/demands')
const getRequest = generateAction('getRequest', 'admin/demands/{id}')
const saveRequest = generateAction('saveRequest', 'admin/demands/{id}', 'POST')
const getRequestTypes = generateAction(
  'getRequestTypes',
  'admin/utilities/demand-types'
)
const getRequestStatuses = generateAction(
  'getRequestStatuses',
  'admin/utilities/demand-statuses'
)
const deleteRequest = generateAction(
  'deleteRequest',
  'admin/demands/{id}',
  'DELETE'
)
export default {
  getRequests,
  saveRequest,
  getRequest,
  getRequestTypes,
  getRequestStatuses,
  deleteRequest,
}
