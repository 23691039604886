import { render, staticRenderFns } from "./CriteriaTree.vue?vue&type=template&id=68536109&scoped=true"
import script from "./CriteriaTree.vue?vue&type=script&lang=js"
export * from "./CriteriaTree.vue?vue&type=script&lang=js"
import style0 from "./CriteriaTree.vue?vue&type=style&index=0&id=68536109&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68536109",
  null
  
)

export default component.exports