<template>
  <div
    class="save"
    v-loading="
      getRequestTypesLoading ||
        getRequestStatusesLoading ||
        getReadingSheetsLoading ||
        getRequestLoading
    "
  >
    <save-page-header title="Request"></save-page-header>
    <router-link
      v-if="form.book_id"
      :to="{
        name: 'readingSheets-save',
        params: { id: form.book_id },
      }"
    >
      <el-button type="primary" size="small" class="plain-primary" plain>
        <i> Go to reading sheet</i>
      </el-button>
    </router-link>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native="submit"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Reading sheet" :error="errors['book_id']">
            <el-select
              filterable
              v-model="form.book_id"
              placeholder="Select reading sheet"
              class="el-col-md-24"
              autocomplete="on"
              :filter-method="filterBooks"
            >
              <el-option
                v-for="book in readingSheets.data"
                :key="book.id"
                :label="
                  book.original_title +
                    (book.authorList
                      ? ' (' +
                        book.authorList
                          .map(item => {
                            return item.name
                          })
                          .join(', ') +
                        ')'
                      : '')
                "
                :value="book.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- Book field -->
          <el-form-item
            label="Request date"
            prop="demand_date"
            :error="errors['demand_date']"
          >
            <!-- Request Date field -->
            <el-date-picker
              class="custom-date-picker"
              v-model="form.demand_date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Pick a request date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <!-- User field -->
        <el-col :span="12">
          <el-form-item label="User" :error="errors['demand_type_id']">
            <el-select
              filterable
              v-model="form.user_id"
              placeholder="Select user"
              class="el-col-md-24"
              autocomplete="on"
              :filter-method="filterUsers"
            >
              <el-option
                v-for="user in users.data"
                :key="user.id"
                :label="user.name"
                :value="user.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- Request End Date field -->
          <el-form-item
            label="Request end date"
            prop="demand_end_date"
            :error="errors['demand_end_date']"
          >
            <el-date-picker
              v-model="form.demand_end_date"
              class="custom-date-picker"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Pick a request end date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <!-- Request Type field -->
        <el-col :span="12">
          <el-form-item label="Request type" :error="errors['demand_type_id']">
            <el-select
              v-model="form.demand_type_id"
              placeholder="Request type"
              class="el-col-md-24"
            >
              <el-option
                v-for="type in types.data"
                :key="type.id"
                :label="type.name"
                :value="type.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- Request Status field -->
        <el-col :span="12">
          <el-form-item
            label="Request status"
            :error="errors['demand_status_id']"
          >
            <el-select
              v-model="form.demand_status_id"
              placeholder="Request status"
              class="el-col-md-24"
              :disabled="getRequestStatusesLoading"
            >
              <el-option
                v-for="status in statuses.data"
                :key="status.id"
                :label="status.name"
                :value="status.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- Commentary field -->
      <el-form-item
        label="Commentary"
        prop="commentary"
        :error="errors['commentary']"
      >
        <el-input
          type="textarea"
          rows="3"
          v-model="form.commentary"
          placeholder="Commentary"
        />
      </el-form-item>
      <div class="align-right">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveRequestLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            {{ isUpdate ? 'Save Request' : 'Create Request' }}
          </el-button>
        </el-col>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import SavePageHeader from '@/components/ui/SavePageHeader'
export default {
  components: {
    SavePageHeader,
  },
  mixins: [apiError('saveRequestError'), labelWidth],
  data: () => ({
    form: {},
  }),
  computed: {
    ...mapState('request', [
      'request',
      'types',
      'statuses',
      'saveRequestError',
      'saveRequestLoading',
      'getRequestTypesLoading',
      'getRequestStatusesLoading',
      'getRequestLoading',
    ]),
    ...mapState('user', ['users', 'getUsersLoading']),
    ...mapState('readingSheet', ['readingSheets', 'getReadingSheetsLoading']),
    isUpdate() {
      return this.$route.params.id !== undefined
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getRequest({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.request.data,
      }
      this.getUsers({
        name: this.form.user.email ? this.form.user.email : '',
      })
      this.getReadingSheets({
        name: this.form.book ? this.form.book.original_title : '',
      })
    } else {
      this.getUsers({ perPage: 10 })
      this.getReadingSheets({ perPage: 10 })
    }
    this.getRequestTypes()
    this.getRequestStatuses()
  },
  methods: {
    ...mapActions('request', [
      'saveRequest',
      'getRequest',
      'getRequestTypes',
      'getRequestStatuses',
    ]),
    ...mapActions('user', ['getUsers']),
    ...mapActions('readingSheet', ['getReadingSheets']),
    async submit() {
      await this.saveRequest({
        routeParameters: {
          id: this.$route.params.id,
        },
        ...this.form,
      }).then(() => {
        if (this.saveRequestError.status === null) {
          this.$router.push({ name: 'request-index' })
        }
      })
    },
    filterUsers(search) {
      this.getUsers({ name: search })
    },
    filterBooks(search) {
      this.getReadingSheets({ name: search })
    },
  },
}
</script>
