import List from '@/views/ReadingSheets/List.vue'
import Save from '@/views/ReadingSheets/Save.vue'
import AiComparation from '@/views/ReadingSheets/AiComparation/AiComparation.vue'

export const readingSheetsRoutes = [
  {
    path: '/reading-sheets',
    name: 'readingSheets',
    component: List,
  },
  {
    path: '/reading-sheet/:id?',
    name: 'readingSheets-save',
    component: Save,
    meta: { menuIndex: '/reading-sheets' },
  },
  {
    path: '/reading-sheet/:id?/ai-comparation',
    name: 'readingSheets-aiComparation',
    component: AiComparation,
    meta: { menuIndex: '/reading-sheets' },
  },
]
