<template>
  <div class="save">
    <save-page-header title="News"></save-page-header>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native="submit"
    >
      <el-form-item
        label="Title"
        class="with-label-as-translatable"
        :error="errors['title']"
      >
        <el-input v-model="form.title"></el-input>
      </el-form-item>

      <el-form-item
        label="Description"
        class="with-label-as-translatable"
        :error="errors['description']"
      >
        <editor
          lang="en"
          height="300px"
          :initialValue="editorText"
          @change="onEditorChange"
          initialEditType="wysiwyg"
          ref="toastuiEditor"
        ></editor>
      </el-form-item>

      <el-form-item class="with-label-as-translatable">
        <el-switch
          v-model="form.type"
          :active-value="1"
          :inactive-value="0"
          active-text="Image"
          inactive-text="YouTube video link"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        label="Image"
        v-if="form.type"
        class="with-label-as-translatable"
        :error="errors['image']"
      >
        <el-upload
          v-if="form.type"
          class="cover-upload"
          :action="actionURL"
          list-type="picture-card"
          :show-file-list="true"
          :auto-upload="false"
          :file-list="form.image"
          :limit="1"
          :on-preview="previewFile"
          :on-change="imageUploadChange"
          :on-remove="deleteFile"
          :multiple="false"
          accept=".pdf, .PDF, .jpg, .JPG, .jpeg, .JPEG, .png,.PNG"
          :class="{ hide: hideUpload }"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item
        v-else
        label="YouTube link"
        :error="errors['link']"
        class="with-label-as-translatable"
      >
        <el-input v-model="form.link" placeholder="YouTube link"> </el-input>
      </el-form-item>

      <div class="align-right">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveNewsLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            {{ isUpdate ? 'Save News' : 'Create News' }}
          </el-button>
        </el-col>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import SavePageHeader from '@/components/ui/SavePageHeader'
import 'codemirror/lib/codemirror.css'
import '@toast-ui/editor/dist/toastui-editor.css'

import { Editor } from '@toast-ui/vue-editor'

export default {
  components: {
    SavePageHeader,
    editor: Editor,
  },
  mixins: [apiError('saveNewsError'), labelWidth],
  data: () => ({
    actionURL: '',
    newImage: null,
    hideUpload: false,
    editorText: '',
    form: {
      type: 0,
    },
  }),
  computed: {
    ...mapState('news', ['news', 'saveNewsError', 'saveNewsLoading']),
    isUpdate() {
      return this.$route.params.id !== undefined
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getNews({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.editorText = this.$refs.toastuiEditor.invoke(
        'setHtml',
        this.news.data.description
      )
      this.form = {
        ...this.news.data,
        type: this.news.data.type,
        image: this.news.data.image ? [{ url: this.news.data.image }] : [],
        description: this.$refs.toastuiEditor.invoke('getHtml'),
      }
      if (this.news.data.image) {
        this.hideUpload = true
      }
    }
  },
  methods: {
    ...mapActions('news', ['saveNews', 'getNews', 'deleteImage']),
    ...mapMutations('news', ['updateNewsData']),
    async submit() {
      let formData = new FormData()
      if (this.form.title) {
        formData.append('title', this.form.title)
      }
      if (this.form.description) {
        formData.append('description', this.form.description)
      }
      if (this.newImage) {
        formData.append('image', this.newImage)
      }
      if (this.form.link) {
        formData.append('link', this.form.link)
      }
      formData.append('type', this.form.type)

      await this.saveNews({
        routeParameters: {
          id: this.$route.params.id,
        },
        formData,
      })
    },
    async deleteFile(file, fileList) {
      let fileId = this.$route.params.id
      this.$confirm(
        `Are you sure, you want to delete this file in the database?`,
        'Confirmation',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        }
      )
        .then(async () => {
          try {
            await this.deleteImage({
              routeParameters: {
                news: fileId,
              },
            })
            this.hideUpload = false
            this.$notify({
              title: 'Success',
              message: 'Deletion succeed',
              type: 'success',
            })
          } catch (e) {
            this.$notify({
              title: 'Failed',
              message: 'Deletion Failed',
              type: 'danger',
            })
          }
          this.updateNewsData({
            property: 'image',
            value: null,
          })
        })
        .catch(() => {
          fileList.push(file)
        })
    },
    previewFile(file) {
      window.open(file.url, '_blank')
    },
    imageUploadChange(file, fileList) {
      if (file.size / 1024 / 1025 < 5.01) {
        this.newImage = file.raw
        if (this.newImage) {
          this.hideUpload = true
        }
      } else {
        fileList.pop()
        this.$notify.error({
          type: 'Error',
          message: 'Image should not be greater than 5Mb',
        })
      }
    },
    onEditorChange() {
      if (this.isUpdate) {
        this.updateNewsData({
          property: 'description',
          value: this.$refs.toastuiEditor.invoke('getHtml'),
        })
        this.form.description = this.$refs.toastuiEditor.invoke('getHtml')
      } else {
        this.form.description = this.$refs.toastuiEditor.invoke('getHtml')
      }
    },
  },
}
</script>
