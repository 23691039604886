<template>
  <dashboard-right-item accordeon-tab-name="recent-criteria">
    <template slot="title">
      <div class="icon-wrapper">
        <i class="icon ion-ios-pricetag" />
      </div>
      <div class="content-wrapper">
        <div class="top-line">Critères</div>
        <div class="bottom-line">LES PLUS DEMANDÉS</div>
      </div>
    </template>

    <template slot="content">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="recent-criteria-item-wrapper"
      >
        <div class="numbers-block">
          <div class="criteria-index">#{{ index + 1 }}</div>
          <div class="criteria-data">
            {{ item.searchDate }}
          </div>
        </div>
        <div class="criteria-block">
          <div
            v-for="criteria in item.criteria"
            :key="criteria.name"
            class="criteria-item"
          >
            <div class="category-name">
              {{ criteria.name }}
            </div>
            <div class="category-values-wrapper">
              <div
                class="category-value"
                v-for="value in criteria.values"
                :key="value"
              >
                {{ value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </dashboard-right-item>
</template>

<script>
import DashboardRightItem from './DashboardRightItem.vue'

export default {
  name: 'RecentCriteria',
  components: {
    DashboardRightItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
