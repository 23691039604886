<template>
  <div class="tree-elements">
    <div class="group-wrapper" v-for="parent in data" :key="parent.id">
      <el-checkbox
        :value="parent.selected"
        @change="value => onParentChange(value, parent)"
        :disabled="disabled"
        >{{ parent.name }}</el-checkbox
      >
      <div
        class="child-wrapper"
        v-for="child in parent.children"
        :key="child.id"
      >
        <el-checkbox
          :value="child.selected"
          @change="value => onChildChange(value, parent, child)"
          :disabled="disabled"
          >{{ child.name }}</el-checkbox
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CriteriaTree',
  props: {
    sourceTree: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    data() {
      let data = this.sourceTree.map(item => {
        let selected = this.selectedItems.indexOf(item.id) !== -1
        let children = item.children.map(childItem => {
          let selected = this.selectedItems.indexOf(childItem.id) !== -1
          return {
            ...childItem,
            selected,
          }
        })
        return {
          ...item,
          children,
          selected,
        }
      })
      return data
    },
  },
  methods: {
    onParentChange(value, parentItem) {
      let newSelectedItems = this.selectedItems.slice()
      if (value) {
        newSelectedItems.push(parentItem.id)
        // Check all children
        parentItem.children.forEach(childItem => {
          if (childItem.selected === false) {
            newSelectedItems.push(childItem.id)
          }
        })
      } else {
        let index = newSelectedItems.indexOf(parentItem.id)
        newSelectedItems.splice(index, 1)

        // Uncheck all children
        parentItem.children.forEach(childItem => {
          let childItemIndex = newSelectedItems.indexOf(childItem.id)
          if (childItemIndex !== -1) {
            newSelectedItems.splice(childItemIndex, 1)
          }
        })
      }
      this.$emit('change', newSelectedItems)
    },
    onChildChange(value, parentItem, childItem) {
      let newSelectedItems = this.selectedItems.slice()
      if (value) {
        newSelectedItems.push(childItem.id)

        // Check parent item when we select a child
        if (newSelectedItems.indexOf(parentItem.id) === -1) {
          newSelectedItems.push(parentItem.id)
        }
      } else {
        let index = newSelectedItems.indexOf(childItem.id)
        newSelectedItems.splice(index, 1)

        // Uncheck parent item if it was the last child
        if (parentItem.children.filter(item => item.selected).length === 1) {
          let parentIndex = newSelectedItems.indexOf(parentItem.id)
          if (parentIndex !== -1) {
            newSelectedItems.splice(parentIndex, 1)
          }
        }
      }
      this.$emit('change', newSelectedItems)
    },
  },
}
</script>

<style scoped>
:deep(.is-disabled.is-checked .el-checkbox__inner) {
  background-color: black !important;
}

:deep(.el-checkbox.is-disabled.is-checked .el-checkbox__label) {
  color: black !important;
}
</style>
