import List from '@/views/Author/List.vue'
import Save from '@/views/Author/Save.vue'

export const authorRoutes = [
  {
    path: '/authors',
    name: 'author-index',
    component: List,
  },
  {
    path: '/author/:id?',
    name: 'author-save',
    component: Save,
    meta: { menuIndex: '/authors' },
  },
]
