export const DOCUMENT_TYPES = {
  FILES: 1,
  COVERS: 2,
  VIDEOS: 3,
}

export const AI_PROMPT_VARIABLES = [
  {
    name: 'thematics',
    description:
      'Liste des thématiques organisées hiérarchiquement avec pour chaque thématique parente sa description',
  },
  {
    name: 'genres',
    description: 'Liste des genres organisés hiérarchiquement',
  },
  {
    name: 'times',
    description: 'Liste des époques enregistrées en base',
  },
  {
    name: 'periods',
    description: 'Liste des périodes enregistrées en base',
  },
  {
    name: 'categories',
    description: 'Liste des catégories enregistrées en base',
  },
  {
    name: 'character_ages',
    description: 'Liste des âges des personnages enregistrés en base',
  },
  {
    name: 'character_functions',
    description: 'Liste des fonctions de personnages enregistrées en base',
  },
  {
    name: 'character_genders',
    description: 'Liste des genres de personnages enregistrés en base',
  },
  {
    name: 'character_topologies',
    description: 'Liste des topologies de personnages enregistrées en base',
  },
  {
    name: 'adaptation_formats',
    description: 'Liste des formats d’adaptation enregistrés en base',
  },
  {
    name: 'adaptation_genders',
    description: 'Liste des genres d’adaptation enregistrés en base',
  },
  {
    name: 'adaptation_budgets',
    description: 'Liste des budgets d’adaptation enregistrés en base',
  },
  {
    name: 'literary_prizes',
    description: 'Liste des prix littéraires enregistrés en base',
  },
  {
    name: 'Formats',
    description: 'Liste des formats',
  },
  {
    name: 'title',
    description: 'Titre du livre dans la langue de génération',
  },
  {
    name: 'author',
    description: 'Nom des auteurs du livre séparés par des virgules (writer)',
  },
  {
    name: 'original_country',
    description: "Nom du pays d'origine du livre",
  },
  {
    name: 'public_comment',
    description: 'Commentaire publique du livre (Information additionnelles)',
  },
  {
    name: 'private_comment',
    description: 'Commentaire BS2BO du livre (Information additionnelles)',
  },
  {
    name: 'scout',
    description: 'Scout du livre',
  },
  {
    name: 'pitch',
    description: 'Pitch du livre',
  },
  {
    name: 'context',
    description:
      'Manuscrit ou rapport de lecture (en fonction de la selection dans le volet déroulant) (OBLIGATOIRE)',
  },
  {
    name: 'json_structure',
    description: 'Structure de retour JSON (OBLIGATOIRE)',
  },
]

export const AI_GENERATE_FROM = {
  Report: 'report',
  Manuscript: 'manuscript',
}

export const AI_MODEL_NAMES = {
  'Gemini 1.5 Flash': 'gemini-1.5-flash',
  'Claude 3 Opus': 'claude-3-opus-20240229',
  'Claude 3 Haiku': 'claude-3-haiku-20240307',
}
