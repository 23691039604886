import { generateMutations } from '../builder'

export default {
  ...generateMutations('getUsers', 'users'),
  ...generateMutations('saveUser', 'user'),
  ...generateMutations('getUser', 'user'),
  ...generateMutations('deleteUser'),
  ...generateMutations('getLibraries', 'libraries'),
  ...generateMutations('validateUser', 'user'),
  ...generateMutations('getPlans', 'plans'),
  ...generateMutations('cancelPlan', 'user'),
  ...generateMutations('addPlan', 'user'),
  ...generateMutations('manageCredits', 'user'),
  ...generateMutations('getUserSearches', 'userSearches'),
}
