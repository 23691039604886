import api from '@/services/api'
import { generateAction } from '../builder'

const getReporters = generateAction('getReporters', 'admin/reporters')
const getReporter = generateAction('getReporter', 'admin/reporters/{id}')
const deleteReporter = generateAction(
  'deleteReporter',
  'admin/reporters/{id}',
  'DELETE'
)
const getReporterStatuses = generateAction(
  'getReporterStatuses',
  'admin/utilities/reporter-statuses'
)
const getReporterBooks = generateAction(
  'getReporterBooks',
  'admin/reporters/{id}/books'
)
const getReporterUsers = generateAction(
  'getReporterUsers',
  'admin/users/user-options-list'
)
const getReporterUserRoles = generateAction(
  'getReporterUserRoles',
  'admin/users/user-role-options-list'
)
const deleteReporterBook = generateAction(
  'deleteReporterBook',
  'admin/reporters/delete-book',
  'DELETE'
)

const saveReporter = async ({ commit }, requestData) => {
  commit('setStateValue', {
    property: 'saveReporterLoading',
    value: true,
  })

  let result = {
    data: null,
    validationErrors: null,
  }

  try {
    let reporterId = requestData.id
    let url = reporterId ? `admin/reporters/${reporterId}` : `admin/reporters`
    let response = await api.request({
      method: 'post',
      url: url,
      data: requestData,
    })
    result.data = response.data.data
  } catch (error) {
    if (error.response.status === 422) {
      result.validationErrors = error.response.data.errors
    }
  }

  commit('setStateValue', {
    property: 'saveReporterLoading',
    value: false,
  })

  return result
}

export default {
  getReporters,
  saveReporter,
  getReporter,
  deleteReporter,
  getReporterStatuses,
  getReporterUsers,
  getReporterUserRoles,
  getReporterBooks,
  deleteReporterBook,
}
