<template>
  <div>
    <h2 class="title primary-color mt-40">BOOKLIST</h2>
    <div v-if="reporterBooksData.length === 0" class="genre-title">
      No data
    </div>

    <div v-if="reporterBooksData.length > 0" class="books-data-wrapper">
      <div
        v-show="genre.books.length > 0"
        v-for="genre in reporterBooksData"
        :key="genre.id"
      >
        <div class="genre-title">{{ genre.title }}</div>

        <books-table
          :data="genre.books"
          :actions-column-width="100"
          @row-click="goToBook"
        >
          <template v-slot:actions-column="slotProps">
            <el-button
              slot="reference"
              type="danger"
              size="mini"
              class="delete-book-button"
              @click="event => onDeleteRowClick(event, slotProps.row)"
            >
              supprimer
            </el-button>
          </template>
        </books-table>

        <el-dialog :visible.sync="deleteDialogVisible" class="confirm-dialog">
          <h4 class="text-center">Are you sure to delete this?</h4>
          <span slot="footer" class="dialog-footer">
            <el-button @click="deleteDialogVisible = false"
              >No, Thanks</el-button
            >
            <el-button type="primary" @click="onConfirmDelete">OK</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BooksTable from '@/components/ui/BooksTable.vue'

export default {
  name: 'ReporterBooklist',
  components: {
    BooksTable,
  },
  props: {
    reporterId: {
      type: Number,
      default: null,
    },
    reporterBooksData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      deleteDialogVisible: false,
    }
  },
  methods: {
    ...mapActions('reporter', ['deleteReporterBook']),
    async onDeleteBook(book) {
      await this.deleteReporterBook({
        reporterId: this.reporterId,
        bookId: book.id,
      })
      this.$emit('on-book-delete')
    },
    goToBook(book) {
      this.$router.push({ name: 'readingSheets-save', params: { id: book.id } })
    },
    onDeleteRowClick(event, row) {
      this.deleteDialogVisible = true
      this.selectedBook = row
      event.stopPropagation()
    },
    onConfirmDelete() {
      this.onDeleteBook(this.selectedBook)
      this.deleteDialogVisible = false
    },
  },
}
</script>
