<template>
  <div class="list">
    <portal to="search">
      <search placeholder="Find a user..." v-model="globalFilter"></search>
    </portal>
    <div class="space-between mb-50">
      <h2 class="title primary-color">USER LIST</h2>
    </div>
    <el-table :data="users.data" v-loading="getUsersLoading">
      <el-table-column prop="id" label="#" width="75"> </el-table-column>
      <el-table-column prop="name" label="User name" min-width="150">
      </el-table-column>
      <el-table-column label="Company" min-width="150">
        <template v-slot="scope">
          {{ scope.row.company ? scope.row.company : '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="subscription" label="Subscription" min-width="150">
        <template v-slot="scope">
          {{ scope.row.subscription ? scope.row.subscription.data.role : '-' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="number_connections"
        label="Number of connections"
        min-width="150"
      >
        <template v-slot="scope">
          {{
            scope.row.number_connections ? scope.row.number_connections : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="last_connection_date"
        label="Last connection date"
        min-width="150"
      >
        <template v-slot="scope">
          {{
            scope.row.last_connection_date
              ? scope.row.last_connection_date
              : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="160">
        <template v-slot="scope">
          <router-link
            :to="{ name: 'user-save', params: { id: scope.row.id } }"
            tag="span"
            class="ml-10"
          >
            <el-button type="primary" size="small" class="plain-primary" plain>
              <i class="el-icon-edit" />
            </el-button>
          </router-link>
          <el-popconfirm
            confirmButtonText="OK"
            cancelButtonText="No, Thanks"
            icon="el-icon-info"
            iconColor="red"
            title="Are you sure to delete this?"
            @confirm="confirmDelete(scope.row.id)"
            @cancel="
              $notify({
                title: 'Cancel',
                message: 'Deletion cancelled',
                type: 'info',
              })
            "
          >
            <el-button
              size="small"
              type="danger"
              slot="reference"
              class="ml-10"
            >
              <i class="el-icon-delete" />
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :meta="users.meta"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Paginator from '@/components/Form/Paginator'
import { pagination } from '@/mixins'
import Search from '@/components/Form/Search.vue'
export default {
  components: {
    Paginator,
    Search,
  },
  mixins: [pagination('getUsers')],
  computed: {
    ...mapState('user', ['users', 'getUsersLoading']),
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    ...mapActions('user', ['getUsers', 'deleteUser']),
    async confirmDelete(id) {
      try {
        await this.deleteUser({
          routeParameters: {
            id,
          },
        })
        this.search(true)
        this.$notify({
          title: 'Success',
          message: 'Deletion succeed',
          type: 'success',
        })
      } catch (e) {
        this.$notify({
          title: 'Failed',
          message: 'Deletion Failed',
          type: 'danger',
        })
      }
    },
  },
}
</script>
