<template>
  <el-dialog :visible="visible" class="custom-dialog">
    <lightbox
      title="AI generation"
      @button1="closeModal"
      @button2="submit"
      :btn2Loading="postAiBookGenerationLoading"
      btn1="CANCEL"
      btn2="GENERATE"
    >
      <el-form class="pt-20 pb-20 bold">
        <el-form-item label="Generate from" :error="errors['generate_from']">
          <el-select
            v-model="form.generate_from"
            placeholder="Select a source for generating"
          >
            <el-option
              v-for="(source, label) in sources"
              :key="source"
              :label="label"
              :value="source"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Model" :error="errors['ai_model']">
          <el-select v-model="form.ai_model" placeholder="Select a model">
            <el-option
              v-for="(model, label) in models"
              :key="model"
              :label="label"
              :value="model"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Language" :error="errors['language']">
          <el-select v-model="form.language" placeholder="Select a language">
            <el-option
              v-for="language in languages"
              :key="language"
              :label="language"
              :value="language"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </lightbox>
  </el-dialog>
</template>

<script>
import Lightbox from '@/components/ui/Lightbox.vue'
import { AI_GENERATE_FROM, AI_MODEL_NAMES } from '@/utils/constant'
import { apiError } from '@/mixins'
import { mapActions, mapState } from 'vuex'

export default {
  components: { Lightbox },
  mixins: [apiError('postAiBookGenerationError')],
  emits: ['close', 'generation-launched'],
  data() {
    return {
      sources: AI_GENERATE_FROM,
      models: AI_MODEL_NAMES,
      languages: ['en', 'fr'],
      form: {
        generate_from: '',
        ai_model: '',
        language: 'en',
        book_id: this.bookId,
      },
    }
  },
  computed: {
    ...mapState('aiBook', [
      'postAiBookGenerationError',
      'postAiBookGenerationLoading',
    ]),
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
      default: false,
    },
    bookId: {
      required: true,
      type: Number,
    },
  },
  methods: {
    ...mapActions('aiBook', ['postAiBookGeneration']),
    closeModal() {
      this.$emit('close')
    },
    async submit() {
      this.postAiBookGeneration(this.form).then(() => {
        if (this.postAiBookGenerationError.status === null) {
          this.$emit('generation-launched')
          this.closeModal()
        }
      })
    },
  },
  watch: {
    visible(value) {
      this.show = !!value
    },
    postAiBookGenerationSuccess() {
      this.closeModal()
    },
  },
}
</script>
