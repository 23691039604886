<template>
  <el-upload
    action=""
    ref="manuscriptUpload"
    list-type="picture"
    :auto-upload="false"
    accept=".pdf, .PDF"
    :on-change="handleChange"
    :on-remove="handleRemove"
    :file-list="fileList"
    :multiple="false"
    :limit="1"
    :on-exceed="onExceed"
    :on-preview="onPreview"
  >
    <el-button type="primary" class="plain-primary" plain
      >Add a file...</el-button
    >
  </el-upload>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    maxFileSize: {
      type: Number,
      default: () => 1024 * 1024 * 60,
    },
    fileList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions('readingSheet', ['deleteManuscript']),
    ...mapMutations('readingSheet', ['removeStateData']),
    handleChange(file) {
      if (file.size > this.maxFileSize) {
        this.$notify.error({
          title: 'Error',
          message: 'The file size must not exceeds 60Mb',
        })
        this.$refs.manuscriptUpload.clearFiles()
      } else {
        this.$emit('change', file.raw)
      }
    },
    handleRemove() {
      this.$confirm(
        `Are you sure, you want to delete this manuscript in the database from this reading sheet?`,
        'Confirmation',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          try {
            this.deleteManuscript({
              routeParameters: {
                book: this.$route.params.id,
              },
            })

            this.$notify({
              title: 'Success',
              message: 'Deletion succeed',
              type: 'success',
            })
          } catch (e) {
            this.$notify({
              title: 'Failed',
              message: 'Deletion Failed',
              type: 'danger',
            })
          }
        })
        .catch(() => {
          this.$notify({
            title: 'Failed',
            message: 'Something went wrong',
            type: 'danger',
          })
        })
      this.$emit('remove')
    },
    onExceed(file) {
      if (file.length) {
        // Replace the currently present manuscript
        this.$refs.manuscriptUpload.clearFiles()
        this.$refs.manuscriptUpload.handleStart(file[0])

        // Emit change
        this.$emit('change', file[0])
      }
    },
    onPreview(file) {
      window.open(file.url, '_blank')
    },
  },
}
</script>
