<template>
  <div class="reporters-table">
    <div class="search-status-wrapper">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-select
            :value="filters.status"
            popper-class="app-select-options"
            clearable
            placeholder="Search by status"
            class="app-select"
            @change="onFilterStatusChange"
          >
            <el-option
              v-for="item in statuses.data"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <el-table :data="data" v-loading="isLoading">
      <el-table-column prop="title" label="Title of reporter" min-width="150">
      </el-table-column>
      <el-table-column
        prop="date"
        label="Expected date of publication"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        v-if="!hiddenColumns.hasOwnProperty('reporterUsers')"
        label="List of associated users"
        min-width="120"
      >
        <template v-slot="scope">
          <div
            v-for="(reporterUser, index) in scope.row.reporterUsers"
            :key="index"
          >
            {{ reporterUser }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="reporterStatus.name"
        label="Status"
        min-width="100"
      >
      </el-table-column>
      <el-table-column label="Actions" width="160">
        <template v-slot="scope">
          <router-link
            :to="{ name: 'reporter-save', params: { id: scope.row.id } }"
            tag="span"
            class="ml-10"
          >
            <el-button type="primary" size="small" class="plain-primary" plain>
              <i class="el-icon-edit" />
            </el-button>
          </router-link>
          <el-popconfirm
            v-if="!hiddenColumns.hasOwnProperty('delete-action')"
            confirmButtonText="OK"
            cancelButtonText="No, Thanks"
            icon="el-icon-info"
            iconColor="red"
            title="Are you sure to delete this?"
            @confirm="confirmDelete(scope.row.id)"
            @cancel="
              $notify({
                title: 'Cancel',
                message: 'Deletion cancelled',
                type: 'info',
              })
            "
          >
            <el-button
              size="small"
              type="danger"
              slot="reference"
              class="ml-10"
            >
              <i class="el-icon-delete" />
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ReportersTable',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    hiddenColumns: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.getReporterStatuses()
  },
  computed: {
    ...mapState('reporter', ['statuses']),
  },
  methods: {
    ...mapActions('reporter', ['getReporterStatuses']),
    confirmDelete(id) {
      this.$emit('confirm-delete', id)
    },
    onFilterStatusChange(value) {
      let newFilter = Object.assign({}, this.filters, { status: value })
      this.$emit('filter-change', newFilter)
    },
  },
}
</script>
