import List from '@/views/User/Lists.vue'
import Save from '@/views/User/Save.vue'

export const userRoutes = [
  {
    path: '/users',
    name: 'user-index',
    component: List,
  },
  {
    path: '/user/:id?',
    name: 'user-save',
    component: Save,
    meta: { menuIndex: '/users' },
  },
]
