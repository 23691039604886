<template>
  <div class="lang-switcher">
    <el-select v-model="selectedPane">
      <el-option
        v-for="locale in locales"
        :label="locale.code.toUpperCase()"
        :key="locale.code"
        :value="locale.code"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('locale', ['paneLang', 'locales']),
    selectedPane: {
      get() {
        return this.paneLang
      },
      set(val) {
        this.setSelectedPane(val)
      },
    },
  },
  methods: {
    ...mapActions('locale', ['setSelectedPane']),
  },
}
</script>
