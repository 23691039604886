import List from '@/views/Request/List.vue'
import Save from '@/views/Request/Save.vue'

export const requestRoutes = [
  {
    path: '/requests',
    name: 'request-index',
    component: List,
  },
  {
    path: '/request/:id?',
    name: 'request-save',
    component: Save,
    meta: { menuIndex: '/requests' },
  },
]
