<template>
  <div class="save">
    <save-page-header title="Administrator"></save-page-header>
    <el-form
      class="mt-30"
      :label-width="labelWidth + 'px'"
      @submit.prevent.native="submit"
    >
      <!-- Firstname field-->
      <el-form-item
        label="Firstname"
        prop="firstname"
        :error="errors['firstname']"
      >
        <el-input v-model="form.firstname" />
      </el-form-item>
      <!-- Lastname field-->
      <el-form-item
        label="Lastname"
        prop="lastname"
        :error="errors['lastname']"
      >
        <el-input v-model="form.lastname" />
      </el-form-item>
      <!-- Email field-->
      <el-form-item label="Email" prop="email" :error="errors['email']">
        <el-input v-model="form.email" />
      </el-form-item>
      <!-- password field-->
      <el-form-item
        label="Password"
        prop="password"
        :error="errors['password']"
      >
        <el-input v-model="form.password" show-password />
      </el-form-item>
      <div class="align-right">
        <el-col :span="4">
          <el-button
            native-type="submit"
            :loading="saveAdministratorLoading"
            type="primary"
            plain
            class="plain-primary"
            @click="submit"
          >
            {{ isUpdate ? 'Save Administrator' : 'Create Administrator' }}
          </el-button>
        </el-col>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiError, labelWidth } from '@/mixins'
import SavePageHeader from '@/components/ui/SavePageHeader'

export default {
  components: {
    SavePageHeader,
  },
  mixins: [apiError('saveAdministratorError'), labelWidth],
  data: () => ({
    form: {},
    showPassword: false,
  }),
  computed: {
    ...mapState('administrator', [
      'administrator',
      'saveAdministratorError',
      'saveAdministratorLoading',
    ]),
    isUpdate() {
      return this.$route.params.id !== undefined
    },
  },
  async mounted() {
    if (this.isUpdate) {
      await this.getAdministrator({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.administrator.data,
      }
    }
  },
  methods: {
    ...mapActions('administrator', ['saveAdministrator', 'getAdministrator']),
    async submit() {
      await this.saveAdministrator({
        routeParameters: {
          id: this.$route.params.id,
        },
        ...this.form,
      })
    },
    showPassword() {},
  },
}
</script>
