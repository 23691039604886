<template>
  <div class="recent-searches-block">
    <div class="recent-searches-header">
      <div class="recent-searches-title">
        <div class="title-icon">
          <i class="icon ion-ios-search"></i>
        </div>
        <div class="title-text">
          Recherches enregistrées
        </div>
      </div>
    </div>

    <div class="recent-searches-list">
      <div
        class="search-details-wrapepr"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="search-criteria-block">
          <div class="created-at-date">
            {{ item.createdAt }}
          </div>

          <div class="criteria-block">
            <div
              v-for="criteria in item.criteria"
              :key="criteria.name"
              class="criteria-item"
            >
              <div class="category-name">
                {{ criteria.name }}
              </div>
              <div class="category-values-wrapper">
                <div
                  class="category-value"
                  v-for="value in criteria.values"
                  :key="value"
                >
                  {{ value }}
                </div>
              </div>
            </div>
          </div>

          <div class="rating-block" v-if="item.ratings.length > 0">
            <div
              v-for="rating in item.ratings"
              :key="rating.id"
              class="rating-item-wrapper"
            >
              <div class="rate-name">
                {{ rating.name }}
              </div>
              <div class="rate-stars-wrapper">
                <div
                  v-for="pos in [1, 2, 3, 4]"
                  :key="pos"
                  class="star-item"
                  :class="{ selected: pos <= rating.rate }"
                >
                  <i v-if="pos <= rating.rate" class="el-icon-star-on"></i>
                  <i v-else class="el-icon-star-off"></i>
                </div>
                <div v-if="rating.rate < 3" class="and-more-postfix">
                  et plus
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="users-block">
          <div class="arrow-wrapper" @click="toggleUsersList(index, item)">
            <i
              v-if="item.usersListIsVisible === true"
              class="el-icon-arrow-up"
            ></i>
            <i v-else class="el-icon-arrow-down"></i>
          </div>
          <div
            class="users-list-wrapepr"
            v-if="item.usersListIsVisible === true"
          >
            <div class="users-list-label">
              Recherché par:
            </div>
            <div class="users-list">
              <div class="loading-label" v-if="item.usersListIsLoading">
                Loading ...
              </div>
              <div
                class="loading-label"
                v-if="!item.usersListIsLoading && item.usersList.length === 0"
              >
                -
              </div>
              <router-link
                class="user-link"
                v-for="user in item.usersList"
                :key="user.id"
                :to="{ name: 'user-save', params: { id: user.id } }"
              >
                {{ user.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'RecentSearches',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapMutations('dashboard', ['updateRecentSearchItem']),
    ...mapActions('dashboard', ['getSavedSearchUsers']),
    async toggleUsersList(index, item) {
      let currentIsVisible = item.usersListIsVisible === true

      this.updateRecentSearchItem({
        index,
        propertyName: 'usersListIsVisible',
        value: !currentIsVisible,
      })

      if (item.usersList.length === 0 && currentIsVisible === false) {
        this.updateRecentSearchItem({
          index,
          propertyName: 'usersListIsLoading',
          value: true,
        })

        try {
          let usersList = await this.getSavedSearchUsers({
            savedFilters: item.savedFilters,
          })
          this.updateRecentSearchItem({
            index,
            propertyName: 'usersList',
            value: usersList,
          })
        } catch (error) {
          this.updateRecentSearchItem({
            index,
            propertyName: 'usersListIsVisible',
            value: false,
          })
        }

        this.updateRecentSearchItem({
          index,
          propertyName: 'usersListIsLoading',
          value: false,
        })
      }
    },
  },
}
</script>
