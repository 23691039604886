<template>
  <div
    :class="[
      'pr-25 pl-25 pt-10 pb-10 mb-10 character-item',
      { disabled: disabled },
    ]"
  >
    <p class="bold text-center">
      {{ character.name ? character.name : `Character #${index + 1}` }}
    </p>
    <el-row :gutter="20">
      <el-col :span="16">
        <el-form-item
          label="Name"
          :error="errors[`characters.${index}.name`]"
          class="el-form-item--label-top"
        >
          <el-input v-model="character.name" :disabled="disabled"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="Function"
          :error="errors[`characters.${index}.function`]"
          class="el-form-item--label-top"
        >
          <el-select
            v-model="character.function"
            filterable
            reserve-keyword
            placeholder="Select a function"
            :filter-method="filterCharacterFunctions"
            clearable
            :disabled="disabled"
            class="width-100"
          >
            <el-option
              v-for="characterFunction in characterFunctions.data"
              :key="characterFunction.id"
              :label="characterFunction.label"
              :value="characterFunction.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item
          label="Topology"
          :error="errors[`characters.${index}.topology`]"
          class="el-form-item--label-top"
        >
          <el-select
            v-model="character.topology"
            filterable
            placeholder="Select a topology"
            :filter-method="filterTopologies"
            clearable
            :disabled="disabled"
            class="width-100"
          >
            <el-option
              v-for="topology in characterTopologies.data"
              :key="topology.id"
              :label="topology.label"
              :value="topology.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="Gender"
          :error="errors[`characters.${index}.gender`]"
          class="el-form-item--label-top"
        >
          <el-select
            v-model="character.gender"
            filterable
            placeholder="Select a gender"
            :filter-method="filterCharacterGenders"
            clearable
            :disabled="disabled"
            class="width-100"
          >
            <el-option
              v-for="characterGender in characterGenders.data"
              :key="characterGender.id"
              :label="characterGender.label"
              :value="characterGender.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="Nationality"
          :error="errors[`characters.${index}.country`]"
          class="el-form-item--label-top"
        >
          <el-select
            v-model="character.country"
            filterable
            placeholder="Select a country"
            :filter-method="filterCountries"
            clearable
            :disabled="disabled"
            class="width-100"
          >
            <el-option
              v-for="country in countries.data"
              :key="country.id"
              :label="country.name"
              :value="country.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item
      label="Ages"
      :error="errors[`characters.${index}.ages`]"
      class="el-form-item--label-top"
    >
      <el-select
        v-model="character.ages"
        filterable
        placeholder="Select an age"
        :filter-method="filterCharacterAges"
        clearable
        multiple
        :disabled="disabled"
        class="width-100"
      >
        <el-option
          v-for="characterAge in characterAges.data"
          :key="characterAge.id"
          :label="characterAge.label"
          :value="characterAge.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item
      label="Characterisation"
      :error="errors[`characters.${index}.characterisations`]"
      class="el-form-item--label-top"
    >
      <translatable-input
        :errors="errors"
        :source.sync="character.characterisations"
        :sourceKey="`characters.${index}.characterisations`"
        placeholder="Enter the characterisation"
        :is-disabled="disabled"
        :default-selected-pane="defaultSelectedPane"
      />
    </el-form-item>
    <el-form-item
      label="Issues"
      :error="errors[`characters.${index}.issues`]"
      class="el-form-item--label-top"
    >
      <translatable-input
        :errors="errors"
        :source.sync="character.issues"
        :sourceKey="`characters.${index}.issues`"
        placeholder="Enter the issues"
        :is-disabled="disabled"
        :default-selected-pane="defaultSelectedPane"
      />
    </el-form-item>
    <el-form-item
      class="el-form-item--label-top"
      label="Evolution"
      :error="errors[`characters.${index}.evolutions`]"
    >
      <translatable-input
        :errors="errors"
        :source.sync="character.evolutions"
        :sourceKey="`characters.${index}.evolutions`"
        placeholder="Enter the evolution"
        :is-disabled="disabled"
        :default-selected-pane="defaultSelectedPane"
      />
    </el-form-item>
    <el-form-item v-if="!disabled">
      <el-button
        type="danger"
        class="plain-danger all-align"
        icon="el-icon-delete"
        size="small"
        @click="() => $emit('remove')"
        plain
        >DELETE</el-button
      >
    </el-form-item>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { initMultilingual } from '@/services/helpers'
import TranslatableInput from '@/components/Form/TranslatableInput.vue'

export default {
  name: 'CharacterItem',
  components: { TranslatableInput },
  data() {
    return {
      character: this.value,
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        id: null,
        key: null,
        name: '',
        function: null,
        gender: null,
        ages: null,
        country: null,
        topology: null,
        characterisations: initMultilingual(),
        issues: initMultilingual(),
        evolutions: initMultilingual(),
      }),
    },
    index: {
      type: Number,
      default: 1,
    },
    errors: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultSelectedPane: {
      type: String,
      default: () => 'en',
    },
  },
  emits: ['input'],
  methods: {
    ...mapActions('characterFunction', ['getCharacterFunctions']),
    ...mapActions('characterGender', ['getCharacterGenders']),
    ...mapActions('characterAge', ['getCharacterAges']),
    ...mapActions('country', ['getCountries']),
    ...mapActions('characterTopology', ['getCharacterTopologies']),
    filterCharacterFunctions(search) {
      this.getCharacterFunctions({ label: search })
    },
    filterCharacterGenders(search) {
      this.getCharacterGenders({ label: search })
    },
    filterCharacterAges(search) {
      this.getCharacterAges({ label: search })
    },
    filterCountries(search) {
      this.getCountries({ name: search })
    },
    filterTopologies(search) {
      this.getCharacterTopologies({ label: search })
    },
  },
  computed: {
    ...mapState('characterFunction', ['characterFunctions']),
    ...mapState('characterGender', ['characterGenders']),
    ...mapState('characterAge', ['characterAges']),
    ...mapState('country', ['countries']),
    ...mapState('characterTopology', ['characterTopologies']),
  },
  async mounted() {
    await this.getCharacterFunctions()
    await this.getCharacterGenders()
    await this.getCharacterAges()
    await this.getCountries({ perPage: 500 })
    await this.getCharacterTopologies()
  },
}
</script>

<style scoped>
.character-item {
  border: 3px #e6ecf7 solid;
  border-radius: 25px;
}

.character-item.disabled {
  border-color: #f1f1f1;
}

:deep(.el-form-item) {
  margin-bottom: 5px;
}

:deep(.el-form-item--label-top .el-form-item__label) {
  width: auto !important;
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 10px;
}

:deep(.el-form-item--label-top .el-form-item__content) {
  margin-left: 0 !important;
}
</style>
