import { generateState } from '../builder'

export default {
  readingSheets: [],
  readingSheet: {},
  statuses: [],
  filterStore: {},
  rightsStatuses: { data: [] },
  ...generateState('getReadingSheets'),
  ...generateState('saveReadingSheet'),
  ...generateState('getReadingSheet'),
  ...generateState('getBookStatuses'),
  ...generateState('getRightsStatuses'),
  ...generateState('deleteFile'),
}
