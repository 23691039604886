import { generateState } from '../builder'

export default {
  requests: [],
  request: {},
  types: [],
  statuses: [],
  ...generateState('getRequests'),
  ...generateState('saveRequest'),
  ...generateState('getRequest'),
  ...generateState('getRequestTypes'),
  ...generateState('getRequestStatuses'),
  ...generateState('deleteRequest'),
}
