<template>
  <el-table
    class="book-table"
    :data="data"
    v-loading="isLoading"
    @row-click="onRowClick"
  >
    <el-table-column width="40">
      <template v-slot="scope">
        <div class="status all-align">
          <el-popover
            placement="top"
            width="180"
            trigger="hover"
            popper-class="status-popup"
          >
            <div>
              STATUS:
              <span
                :class="[
                  'bold',
                  {
                    red: scope.row.status.id === 1,
                    orange: scope.row.status.id === 2,
                    grey: scope.row.status.id === 3,
                    green: scope.row.status.id === 4,
                  },
                ]"
                >{{ scope.row.status.label.toUpperCase() }}</span
              >
            </div>
            <div
              :class="[
                'status-icon success',
                {
                  red: scope.row.status.id === 1,
                  orange: scope.row.status.id === 2,
                  grey: scope.row.status.id === 3,
                  green: scope.row.status.id === 4,
                },
              ]"
              slot="reference"
            ></div>
          </el-popover>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="Title / Author / Pitch" min-width="600">
      <template v-slot="scope">
        <reading-list-item
          :item="{
            id: scope.row.id,
            name: scope.row.original_title,
            authors: scope.row.authorList,
            description: getPitch(scope.row.pitches),
          }"
        />
      </template>
    </el-table-column>
    <el-table-column
      label="State of rights / Rights update date"
      min-width="220"
    >
      <template v-slot="scope">
        <p class="bold">
          {{ scope.row.rights_status }}
          {{
            scope.row.rights_status && scope.row.rights_status_updated_at
              ? ' / '
              : ''
          }}
          {{ scope.row.rights_status_updated_at }}
        </p>
      </template>
    </el-table-column>
    <el-table-column
      class-name="actions-column"
      :min-width="actionsColumnWidth"
    >
      <template v-slot="scope">
        <slot name="actions-column" v-bind:row="scope.row">
          <i class="icon ion-ios-arrow-forward"></i>
        </slot>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState } from 'vuex'
import ReadingListItem from '@/components/ui/ReadingListItem'

export default {
  name: 'BooksTable',
  components: {
    ReadingListItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    actionsColumnWidth: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    ...mapState('locale', ['locales']),
  },
  methods: {
    onRowClick(row) {
      this.$emit('row-click', row)
    },
    getPitch(values) {
      let pitch = ''

      if (values) {
        for (let i = 0, len = this.locales.length; i < len; i++) {
          if (values[this.locales[i].code]) {
            pitch = values[this.locales[i].code]
            break
          }
        }
      }

      return pitch
    },
  },
}
</script>
