import { generateState } from '../builder'

export default {
  authors: [],
  author: {},
  types: [],
  ...generateState('getAuthors'),
  ...generateState('saveAuthor'),
  ...generateState('getAuthor'),
  ...generateState('deleteAuthor'),
  ...generateState('getAuthorTypes'),
}
