import { generateAction } from '../builder'

import router from '@/router'

const getGenres = generateAction('getGenres', 'admin/genres')
const getGenre = generateAction('getGenre', 'admin/genres/{id}')
const saveGenre = generateAction(
  'saveGenre',
  'admin/genres/{id}',
  'POST',
  () => {
    return router.push({
      name: 'genre-index',
    })
  }
)
const deleteGenre = generateAction('deleteGenre', 'admin/genres/{id}', 'DELETE')

export default {
  getGenres,
  saveGenre,
  getGenre,
  deleteGenre,
}
