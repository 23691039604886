import { generateState } from '../builder'

export default {
  periods: [],
  period: {},
  ...generateState('getPeriods'),
  ...generateState('savePeriod'),
  ...generateState('getPeriod'),
  ...generateState('deletePeriod'),
}
