<template>
  <div class="view">
    <save-page-header title="Format"></save-page-header>
    <el-form class="mt-30" :label-width="labelWidth + 'px'">
      <translatable-input
        :errors="errors"
        :source.sync="form.names"
        sourceKey="names"
        title="Name"
        :isDisabled="true"
        :label-width="labelWidth"
      />
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TranslatableInput from '@/components/Form/TranslatableInput'
import { initMultilingual } from '@/services/helpers'
import SavePageHeader from '@/components/ui/SavePageHeader'
import { apiError, labelWidth } from '@/mixins'

export default {
  components: {
    TranslatableInput,
    SavePageHeader,
  },
  mixins: [apiError('getFormatError'), labelWidth],
  data: () => ({
    form: {
      names: initMultilingual(),
    },
  }),
  computed: {
    ...mapState('format', ['format', 'getFormatError']),
    isFetch() {
      return this.$route.params.id !== undefined
    },
  },
  async mounted() {
    if (this.isFetch) {
      await this.getFormat({
        routeParameters: {
          id: this.$route.params.id,
        },
      })
      this.form = {
        ...this.format.data,
        names: initMultilingual(this.format.data.names),
      }
    }
  },
  methods: {
    ...mapActions('format', ['getFormat']),
  },
}
</script>
