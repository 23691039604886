import { generateState } from '../builder'

export default {
  contacts: [],
  contact: {},
  contactTypes: [],
  ...generateState('getContacts'),
  ...generateState('saveContact'),
  ...generateState('getContact'),
  ...generateState('deleteContact'),
  ...generateState('getContactTypes'),
}
