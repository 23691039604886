import { generateAction } from '../builder'

import router from '@/router'

const getTimes = generateAction('getTimes', 'admin/times')
const getTime = generateAction('getTime', 'admin/times/{id}')
const saveTime = generateAction('saveTime', 'admin/times/{id}', 'POST', () => {
  return router.push({
    name: 'time-index',
  })
})
const deleteTime = generateAction('deleteTime', 'admin/times/{id}', 'DELETE')

export default {
  getTimes,
  saveTime,
  getTime,
  deleteTime,
}
