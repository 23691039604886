import { render, staticRenderFns } from "./AiComparationGroup.vue?vue&type=template&id=5179d096&scoped=true"
import script from "./AiComparationGroup.vue?vue&type=script&setup=true&lang=ts"
export * from "./AiComparationGroup.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AiComparationGroup.vue?vue&type=style&index=0&id=5179d096&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5179d096",
  null
  
)

export default component.exports